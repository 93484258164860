import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetchRevenueData } from "../utils/apiUtils";
import dayjs from 'dayjs';


const Settings = () => {
  const [divisions, setDivisions] = useState([]);
  const [newDivision, setNewDivision] = useState({ label: "", division: "" });
  const [editDivision, setEditDivision] = useState(null);
  const [activeTab, setActiveTab] = useState("Divisions"); // Manage which tab is currently active
  const [editSchoolRevenue, setEditSchoolRevenue] = useState(null);
// If you allow editing, we also track that
const [editCampaignRename, setEditCampaignRename] = useState(null);
const [editCampaignSpend, setEditCampaignSpend] = useState(null);

const [emailTemplates, setEmailTemplates] = useState([]);
const [newTemplate, setNewTemplate] = useState({ name: "", subject: "", content: "" });
const [editTemplate, setEditTemplate] = useState(null);

const [campaignSpendRecords, setCampaignSpendRecords] = useState([]);
const [newCampaignSpend, setNewCampaignSpend] = useState({
  campaign: "",
  month: "",
  year: "",
  totalCost: "",
});

// Fetch campaign spend records from the API
const fetchCampaignSpendRecords = async () => {
  try {
    const response = await axios.get("/api/campaign-spend");
    setCampaignSpendRecords(Array.isArray(response.data) ? response.data : []);
  } catch (error) {
    console.error("Error fetching campaign spend records:", error);
  }
};

const handleEditCampaignSpend = async (id, updatedSpend) => {
  try {
    await axios.put(`/api/campaign-spend/${id}`, updatedSpend);
    fetchCampaignSpendRecords();
    setEditCampaignSpend(null);
  } catch (error) {
    console.error("Error updating campaign spend record:", error);
  }
};

const handleDeleteCampaignSpend = async (id) => {
  try {
    await axios.delete(`/api/campaign-spend/${id}`);
    fetchCampaignSpendRecords();
  } catch (error) {
    console.error("Error deleting campaign spend record:", error);
  }
};


// Call fetchCampaignSpendRecords when the tab is active
useEffect(() => {
  if (activeTab === "CampaignSpend") {
    fetchCampaignSpendRecords();
  }
}, [activeTab]);

// Handler to add a new campaign spend record
const handleAddCampaignSpend = async () => {
  try {
    await axios.post("/api/campaign-spend", newCampaignSpend);
    setNewCampaignSpend({ campaign: "", month: "", year: "", totalCost: "" });
    fetchCampaignSpendRecords();
  } catch (error) {
    console.error("Error adding campaign spend record:", error);
  }
};

// Fetch templates
const fetchEmailTemplates = async () => {
  try {
    const response = await axios.get("/api/email-templates");
    setEmailTemplates(response.data);
  } catch (error) {
    console.error("Error fetching email templates:", error);
  }
};

// Add template
const handleAddTemplate = async () => {
  try {
    await axios.post("/api/email-templates", newTemplate);
    setNewTemplate({ name: "", subject: "", content: "" });
    fetchEmailTemplates();
  } catch (error) {
    console.error("Error adding email template:", error);
  }
};

// Edit template
const handleEditTemplate = async (id, updatedTemplate) => {
  try {
    await axios.put(`/api/email-templates/${id}`, updatedTemplate);
    setEditTemplate(null);
    fetchEmailTemplates();
  } catch (error) {
    console.error("Error updating email template:", error);
  }
};

// Delete template
const handleDeleteTemplate = async (id) => {
  try {
    await axios.delete(`/api/email-templates/${id}`);
    fetchEmailTemplates();
  } catch (error) {
    console.error("Error deleting email template:", error);
  }
};

// Fetch templates when tab changes
useEffect(() => {
  if (activeTab === "EmailTemplates") {
    fetchEmailTemplates();
  }
}, [activeTab]);


const [schoolRevenues, setSchoolRevenues] = useState([]); // Hold the list of school revenue records
const [newSchoolRevenue, setNewSchoolRevenue] = useState({
  school_name: "",
  month: "",
  revenue: "",
});

// These new states sit alongside divisions, schoolRevenues, etc.
const [campaignRenames, setCampaignRenames] = useState([]);
const [newCampaignRename, setNewCampaignRename] = useState({
  campaign: "",
  label: "",
  cost: ""   // <--- new cost field
});



useEffect(() => {
  // Option 2: Fetch when either CampaignRenames or CampaignSpend is active
  if (activeTab === "CampaignRenames" || activeTab === "CampaignSpend") {
    fetchCampaignRenames();
  }
}, [activeTab]);



const fetchCampaignRenames = async () => {
  try {
    const response = await axios.get("/api/campaign-renames");
    console.log("API Response:", response.data); // Log the raw API response
    const validData = Array.isArray(response.data)
      ? response.data.filter(
          (item) => item && typeof item.campaign === "string" && item.campaign !== null
        )
      : [];
    console.log("Valid Campaign Renames:", validData);
    setCampaignRenames(validData);
  } catch (error) {
    console.error("Error fetching campaign renames:", error);
    setCampaignRenames([]);
  }
};


const handleAddCampaignRename = async () => {
  try {
    await axios.post("/api/campaign-renames", newCampaignRename);
    setNewCampaignRename({ campaign: "", label: "" });
    fetchCampaignRenames(); 
  } catch (error) {
    console.error("Error adding campaign rename:", error);
  }
};

const handleEditCampaignRename = async (id, updatedRename) => {
  try {
    await axios.put(`/api/campaign-renames/${id}`, updatedRename);
    fetchCampaignRenames();
  } catch (error) {
    console.error("Error updating campaign rename:", error);
  }
};

const handleDeleteCampaignRename = async (id) => {
  try {
    await axios.delete(`/api/campaign-renames/${id}`);
    fetchCampaignRenames();
  } catch (error) {
    console.error("Error deleting campaign rename:", error);
  }
};


  const startEditing = (division) => {
    setEditDivision(division);
  };
  
  const stopEditing = () => {
    setEditDivision(null);
  };
  
  const startEditingSchoolRevenue = (revenue) => {
    // Convert the date to mm/dd/yyyy format for editing
    const formattedMonth = dayjs(revenue.month).format('MM/DD/YYYY');
    setEditSchoolRevenue({ ...revenue, month: formattedMonth });
  };
  
  
  const stopEditingSchoolRevenue = () => {
    setEditSchoolRevenue(null);
  };
  

  useEffect(() => {
    fetchDivisions();
  }, []);

  const fetchDivisions = async () => {
    try {
      const response = await axios.get("/api/divisions");
      console.log("Divisions fetched from API:", response.data); // Debug log
      setDivisions(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching divisions:", error);
      setDivisions([]);
    }
  };
  
  const fetchRevenueData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const response = await axios.post(
        "/revenue-by-division",
        {
          startDate: "2024-01-01", // Example date; update as needed
          endDate: "2024-12-31", // Example date; update as needed
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Revenue data updated:", response.data);
    } catch (error) {
      console.error("Error fetching revenue data:", error);
    }
  };

  const fetchSchoolRevenues = async () => {
    try {
      const response = await axios.get("/api/school-revenues");
      setSchoolRevenues(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching school revenues:", error);
    }
  };
  
  const handleAddSchoolRevenue = async () => {
    try {
      // Parse the date from mm/dd/yyyy to ISO format yyyy-mm-dd
      const parsedMonth = dayjs(newSchoolRevenue.month, 'MM/DD/YYYY').format('YYYY-MM-DD');
  
      // Update the object with the parsed date
      const newRevenueData = {
        ...newSchoolRevenue,
        month: parsedMonth,
      };
  
      await axios.post("/api/school-revenues", newRevenueData);
      setNewSchoolRevenue({ school_name: "", month: "", revenue: "" });
      fetchSchoolRevenues(); // Refresh the school revenue list
    } catch (error) {
      console.error("Error adding school revenue:", error);
    }
  };
  
  
  const handleDeleteSchoolRevenue = async (id) => {
    try {
      await axios.delete(`/api/school-revenues/${id}`);
      fetchSchoolRevenues(); // Refresh the school revenue list
    } catch (error) {
      console.error("Error deleting school revenue:", error);
    }
  };

  const handleEditSchoolRevenue = async (id, updatedRevenue) => {
    try {
      // Parse the date from mm/dd/yyyy to ISO format yyyy-mm-dd
      const parsedMonth = dayjs(updatedRevenue.month, 'MM/DD/YYYY').format('YYYY-MM-DD');
  
      const updatedRevenueData = {
        ...updatedRevenue,
        month: parsedMonth,
      };
  
      await axios.put(`/api/school-revenues/${id}`, updatedRevenueData);
      fetchSchoolRevenues(); // Refresh the school revenue list
      stopEditingSchoolRevenue();
    } catch (error) {
      console.error("Error updating school revenue:", error);
    }
  };
  
  
  
  // Fetch school revenues when the component loads or when activeTab changes
  useEffect(() => {
    if (activeTab === "SchoolRevenue") {
      fetchSchoolRevenues();
    }
  }, [activeTab]);
  

  const handleAddDivision = async () => {
    try {
      await axios.post("/api/divisions", newDivision);
      setNewDivision({ label: "", division: "" });
      fetchDivisions(); // Refresh the divisions list
      // Call the fetch function to refresh revenue data
      fetchRevenueData(); 
    } catch (error) {
      console.error("Error adding division:", error);
    }
  };
  
  const handleEditDivision = async (id, updatedDivision) => {
    try {
      await axios.put(`/api/divisions/${id}`, updatedDivision);
      fetchDivisions(); // Refresh the divisions list
      // Call the fetch function to refresh revenue data
      fetchRevenueData(); 
    } catch (error) {
      console.error("Error updating division:", error);
    }
  };
  
  const handleDeleteDivision = async (id) => {
    try {
      await axios.delete(`/api/divisions/${id}`);
      fetchDivisions(); // Refresh the divisions list
      // Call the fetch function to refresh revenue data
      fetchRevenueData(); 
    } catch (error) {
      console.error("Error deleting division:", error);
    }
  };
  

  return (
    <div className="flex min-h-screen">
      {/* Sidebar */}
      <aside className="w-64 rounded-lg bg-white px-4 py-5 shadow sm:p-6">
  <nav>
    <ul>
      <li className="mb-4">
        <button
          className="truncate font-medium text-indigo-600 hover:text-indigo-900"
          onClick={() => setActiveTab("Divisions")}
        >
          Divisions
        </button>
      </li>
      <li className="mb-4">
        <button
          className="truncate font-medium text-indigo-600 hover:text-indigo-900"
          onClick={() => setActiveTab("SchoolRevenue")}
        >
          School Revenue
        </button>
      </li>

      {/* NEW TAB FOR CAMPAIGN RENAMES */}
      <li className="mb-4">
        <button
          className="truncate font-medium text-indigo-600 hover:text-indigo-900"
          onClick={() => setActiveTab("CampaignRenames")}
        >
          Campaign Renaming
        </button>
      </li>

      <li className="mb-4">
  <button
    className="truncate font-medium text-indigo-600 hover:text-indigo-900"
    onClick={() => setActiveTab("CampaignSpend")}
  >
    Campaign Spend
  </button>
</li>


      <li className="mb-4">
  <button
    className="truncate font-medium text-indigo-600 hover:text-indigo-900"
    onClick={() => setActiveTab("EmailTemplates")}
  >
    Email Templates
  </button>
</li>


    </ul>
  </nav>
</aside>



      {/* Main content */}
      <main className="flex-1 p-6">

      {activeTab === "EmailTemplates" && (
  <>
    <h1 className="text-2xl font-bold mb-6">Email Templates</h1>

    {/* Email Templates Table */}
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Name
            </th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Subject
            </th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Content
            </th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
  {emailTemplates.map((template) => (
    <tr key={template.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        {editTemplate?.id === template.id ? (
          <input
            type="text"
            value={editTemplate.name}
            onChange={(e) =>
              setEditTemplate({ ...editTemplate, name: e.target.value })
            }
            className="border p-1 rounded"
          />
        ) : (
          template.name
        )}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
        {editTemplate?.id === template.id ? (
          <input
            type="text"
            value={editTemplate.subject}
            onChange={(e) =>
              setEditTemplate({ ...editTemplate, subject: e.target.value })
            }
            className="border p-1 rounded"
          />
        ) : (
          template.subject
        )}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
        {editTemplate?.id === template.id ? (
          <textarea
            value={editTemplate.content}
            onChange={(e) =>
              setEditTemplate({ ...editTemplate, content: e.target.value })
            }
            className="border p-2 rounded w-full"
          />
        ) : (
          <pre className="whitespace-pre-wrap">{template.content}</pre>
        )}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
        {editTemplate?.id === template.id ? (
          <>
            <button
              onClick={() => handleEditTemplate(template.id, editTemplate)}
              className="mr-4 text-green-600"
            >
              Save
            </button>
            <button
              onClick={() => setEditTemplate(null)}
              className="text-red-600"
            >
              Cancel
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => setEditTemplate(template)}
              className="mr-4 text-blue-600"
            >
              Edit
            </button>
            <button
              onClick={() => handleDeleteTemplate(template.id)}
              className="text-red-600"
            >
              Delete
            </button>
          </>
        )}
      </td>
    </tr>
  ))}
</tbody>

      </table>
    </div>

    {/* Add New Template Form */}
    <div className="bg-white shadow sm:rounded-lg mt-6">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold text-gray-900">Add New Template</h3>
        <div className="flex flex-col gap-4 mt-4">
  <input
    type="text"
    placeholder="Name"
    value={newTemplate.name}
    onChange={(e) =>
      setNewTemplate({ ...newTemplate, name: e.target.value })
    }
    className="border p-2 rounded"
  />
  <input
    type="text"
    placeholder="Subject"
    value={newTemplate.subject}
    onChange={(e) =>
      setNewTemplate({ ...newTemplate, subject: e.target.value })
    }
    className="border p-2 rounded"
  />
  <textarea
    placeholder="Content"
    value={newTemplate.content}
    onChange={(e) =>
      setNewTemplate({ ...newTemplate, content: e.target.value })
    }
    className="border p-2 rounded"
  />
  <button
    onClick={handleAddTemplate}
    className="bg-blue-500 text-white px-4 py-2 rounded"
  >
    Add Template
  </button>
</div>

      </div>
    </div>
  </>
)}

{activeTab === "CampaignSpend" && (
  <>
    <h1 className="text-2xl font-bold mb-6">Campaign Spend Tracker</h1>

    {/* Form to add a new campaign spend record */}
    <div className="bg-white shadow sm:rounded-lg p-6 mb-6">
      <div className="flex flex-col gap-4">
        <div>
          <label className="block font-medium">Campaign</label>
          <select
  value={newCampaignSpend.campaign}
  onChange={(e) =>
    setNewCampaignSpend({ ...newCampaignSpend, campaign: e.target.value })
  }
  className="border p-2 rounded w-full"
>
  <option value="">Select campaign</option>
  {campaignRenames.map((rename) => (
    <option key={rename.id} value={rename.campaign}>
      {rename.label || rename.campaign}
    </option>
  ))}
</select>

        </div>

        <div className="flex gap-4">
          <div className="flex-1">
            <label className="block font-medium">Month</label>
            <select
              value={newCampaignSpend.month}
              onChange={(e) =>
                setNewCampaignSpend({ ...newCampaignSpend, month: e.target.value })
              }
              className="border p-2 rounded w-full"
            >
              <option value="">Select month</option>
              {[
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ].map((m, i) => (
                <option key={i + 1} value={i + 1}>
                  {m}
                </option>
              ))}
            </select>
          </div>

          <div className="flex-1">
            <label className="block font-medium">Year</label>
            <select
              value={newCampaignSpend.year}
              onChange={(e) =>
                setNewCampaignSpend({ ...newCampaignSpend, year: e.target.value })
              }
              className="border p-2 rounded w-full"
            >
              <option value="">Select year</option>
              {["2024", "2025", "2026"].map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div>
          <label className="block font-medium">Total Cost ($)</label>
          <input
            type="number"
            placeholder="Enter total cost"
            value={newCampaignSpend.totalCost}
            onChange={(e) =>
              setNewCampaignSpend({ ...newCampaignSpend, totalCost: e.target.value })
            }
            className="border p-2 rounded w-full"
          />
        </div>

        <button
          onClick={handleAddCampaignSpend}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add Record
        </button>
      </div>
    </div>

    {/* Table to display campaign spend records */}
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Campaign
            </th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Month
            </th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Year
            </th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Total Cost ($)
            </th>
            {/* Add an Actions header if you want to support edit/delete */}
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
  {campaignSpendRecords.map((record) => (
    <tr key={record.id}>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
        {editCampaignSpend?.id === record.id ? (
          <input
            type="text"
            value={editCampaignSpend.campaign}
            onChange={(e) =>
              setEditCampaignSpend({ ...editCampaignSpend, campaign: e.target.value })
            }
            className="border p-1 rounded"
          />
        ) : (
          record.campaign
        )}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
        {editCampaignSpend?.id === record.id ? (
          <input
            type="number"
            value={editCampaignSpend.month}
            onChange={(e) =>
              setEditCampaignSpend({ ...editCampaignSpend, month: e.target.value })
            }
            className="border p-1 rounded"
          />
        ) : (
          record.month
        )}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
        {editCampaignSpend?.id === record.id ? (
          <input
            type="number"
            value={editCampaignSpend.year}
            onChange={(e) =>
              setEditCampaignSpend({ ...editCampaignSpend, year: e.target.value })
            }
            className="border p-1 rounded"
          />
        ) : (
          record.year
        )}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
        {editCampaignSpend?.id === record.id ? (
          <input
            type="number"
            value={editCampaignSpend.totalCost}
            onChange={(e) =>
              setEditCampaignSpend({ ...editCampaignSpend, totalCost: e.target.value })
            }
            className="border p-1 rounded"
          />
        ) : (
          `$${record.totalCost}`
        )}
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
        {editCampaignSpend?.id === record.id ? (
          <>
            <button
              onClick={() => handleEditCampaignSpend(record.id, editCampaignSpend)}
              className="mr-4 text-green-600"
            >
              Save
            </button>
            <button onClick={() => setEditCampaignSpend(null)} className="text-red-600">
              Cancel
            </button>
          </>
        ) : (
          <>
            <button
              onClick={() => setEditCampaignSpend(record)}
              className="mr-4 text-blue-600"
            >
              Edit
            </button>
            <button onClick={() => handleDeleteCampaignSpend(record.id)} className="text-red-600">
              Delete
            </button>
          </>
        )}
      </td>
    </tr>
  ))}
</tbody>

      </table>
    </div>
  </>
)}



      {activeTab === "CampaignRenames" && (
  <>
    <h1 className="text-2xl font-bold mb-6">Campaign Renaming</h1>

    {/* Campaign Renames Table */}
    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Original Campaign</th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Friendly Label</th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Total Cost</th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {campaignRenames.map((rename) => (
            <tr key={rename.id}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {editCampaignRename?.id === rename.id ? (
                  <input
                    type="text"
                    value={editCampaignRename.campaign}
                    onChange={(e) => setEditCampaignRename({ ...editCampaignRename, campaign: e.target.value })}
                    className="border p-1 rounded"
                  />
                ) : (
                  rename.campaign
                )}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-0">
                {editCampaignRename?.id === rename.id ? (
                  <input
                    type="text"
                    value={editCampaignRename.label}
                    onChange={(e) => setEditCampaignRename({ ...editCampaignRename, label: e.target.value })}
                    className="border p-1 rounded"
                  />
                ) : (
                  rename.label
                )}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-900 sm:pl-0">
                {editCampaignRename?.id === rename.id ? (
                  <input
                    type="number"
                    value={editCampaignRename.cost}
                    onChange={(e) => setEditCampaignRename({ ...editCampaignRename, cost: e.target.value })}
                    className="border p-1 rounded"
                  />
                ) : (
                  `$${rename.cost || 0}`
                )}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {editCampaignRename?.id === rename.id ? (
                  <>
                    <button
                      onClick={() => {
                        handleEditCampaignRename(rename.id, editCampaignRename);
                        setEditCampaignRename(null);
                      }}
                      className="mr-4 text-green-600"
                    >
                      Save
                    </button>
                    <button onClick={() => setEditCampaignRename(null)} className="text-red-600">
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button onClick={() => setEditCampaignRename(rename)} className="mr-4 text-blue-600">
                      Edit
                    </button>
                    <button onClick={() => handleDeleteCampaignRename(rename.id)} className="text-red-600">
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {/* Add New Campaign Rename Form */}
    <div className="bg-white shadow sm:rounded-lg mt-6">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold text-gray-900">Add New Campaign Name</h3>
        <div className="flex gap-4 mt-4">
          <input
            type="text"
            placeholder="Original Campaign"
            value={newCampaignRename.campaign}
            onChange={(e) => setNewCampaignRename({ ...newCampaignRename, campaign: e.target.value })}
            className="border p-2 rounded"
          />
          <input
            type="text"
            placeholder="Friendly Label"
            value={newCampaignRename.label}
            onChange={(e) => setNewCampaignRename({ ...newCampaignRename, label: e.target.value })}
            className="border p-2 rounded"
          />
          <input
            type="number"
            placeholder="Total Cost ($)"
            value={newCampaignRename.cost}
            onChange={(e) => setNewCampaignRename({ ...newCampaignRename, cost: e.target.value })}
            className="border p-2 rounded"
          />
          <button
            onClick={handleAddCampaignRename}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </>
)}



  {activeTab === "Divisions" && (
    <>
      <h1 className="text-2xl font-bold mb-6">Divisions</h1>
      <>
  <div className="overflow-x-auto mt-6">
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Label</th>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Division</th>
          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Actions</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {divisions.map((division) => (
          <tr key={division.id}>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              {editDivision?.id === division.id ? (
                <input
                  type="text"
                  value={editDivision.label}
                  onChange={(e) =>
                    setEditDivision({ ...editDivision, label: e.target.value })
                  }
                  className="border p-1 rounded"
                />
              ) : (
                division.label
              )}
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              {editDivision?.id === division.id ? (
                <input
                  type="text"
                  value={editDivision.division}
                  onChange={(e) =>
                    setEditDivision({ ...editDivision, division: e.target.value })
                  }
                  className="border p-1 rounded"
                />
              ) : (
                division.division
              )}
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
              {editDivision?.id === division.id ? (
                <>
                  <button
                    onClick={() => {
                      handleEditDivision(division.id, editDivision);
                      stopEditing();
                    }}
                    className="mr-4 text-green-600"
                  >
                    Save
                  </button>
                  <button onClick={stopEditing} className="text-red-600">
                    Cancel
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => startEditing(division)}
                    className="mr-4 text-blue-600"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeleteDivision(division.id)}
                    className="text-red-600"
                  >
                    Delete
                  </button>
                </>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>

  <div className="bg-white shadow sm:rounded-lg mt-6">
    <div className="px-4 py-5 sm:p-6">
      <h3 className="text-base font-semibold text-gray-900">Add New Division</h3>
      <div className="flex gap-4 mt-4">
        <input
          type="text"
          placeholder="Label"
          value={newDivision.label}
          onChange={(e) =>
            setNewDivision({ ...newDivision, label: e.target.value })
          }
          className="border p-2 rounded"
        />
        <input
          type="text"
          placeholder="Division"
          value={newDivision.division}
          onChange={(e) =>
            setNewDivision({ ...newDivision, division: e.target.value })
          }
          className="border p-2 rounded"
        />
        <button
          onClick={handleAddDivision}
          className="bg-blue-500 text-white px-4 py-2 rounded"
        >
          Add
        </button>
      </div>
    </div>
  </div>
</>

    </>
  )}

{activeTab === "SchoolRevenue" && (
  <>
    <h1 className="text-2xl font-bold mb-6">School Revenue</h1>

    <div className="overflow-x-auto mt-6">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              School Name
            </th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Year & Month
            </th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Revenue $ (USD)
            </th>
            <th className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {schoolRevenues.map((revenue) => (
            <tr key={revenue.id}>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                {editSchoolRevenue?.id === revenue.id ? (
                  <input
                    type="text"
                    value={editSchoolRevenue.school_name}
onChange={(e) => setEditSchoolRevenue({ ...editSchoolRevenue, school_name: e.target.value })}

                    className="border p-1 rounded"
                  />
                ) : (
                  revenue.school_name
                )}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
                {editSchoolRevenue?.id === revenue.id ? (
                  <input
                    type="text"
                    value={editSchoolRevenue.month}
                    onChange={(e) =>
                      setEditSchoolRevenue({ ...editSchoolRevenue, month: e.target.value })
                    }
                    className="border p-1 rounded"
                  />
                ) : (
                  revenue.month
                )}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
                {editSchoolRevenue?.id === revenue.id ? (
                  <input
                    type="number"
                    value={editSchoolRevenue.revenue}
                    onChange={(e) =>
                      setEditSchoolRevenue({ ...editSchoolRevenue, revenue: e.target.value })
                    }
                    className="border p-1 rounded"
                  />
                ) : (
                  revenue.revenue
                )}
              </td>
              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900">
                {editSchoolRevenue?.id === revenue.id ? (
                  <>
                    <button
                      onClick={() => handleEditSchoolRevenue(revenue.id, editSchoolRevenue)}
                      className="mr-4 text-green-600"
                    >
                      Save
                    </button>
                    <button onClick={stopEditingSchoolRevenue} className="text-red-600">
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      onClick={() => startEditingSchoolRevenue(revenue)}
                      className="mr-4 text-blue-600"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDeleteSchoolRevenue(revenue.id)}
                      className="text-red-600"
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>

    {/* Add School Revenue Form */}
    <div className="bg-white shadow sm:rounded-lg mt-6">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold text-gray-900">
          Add School Revenue
        </h3>

        <div className="flex gap-4 mt-4">
        <input
  type="text"
  placeholder="School Name"
  value={newSchoolRevenue.school_name}
  onChange={(e) =>
    setNewSchoolRevenue({ ...newSchoolRevenue, school_name: e.target.value })
  }
  className="border p-2 rounded"
/>

          <input
  type="text"
  placeholder="Date (mm/dd/yyyy)"
  value={newSchoolRevenue.month}
  onChange={(e) =>
    setNewSchoolRevenue({ ...newSchoolRevenue, month: e.target.value })
  }
  className="border p-2 rounded"
/>

          <input
            type="number"
            placeholder="Revenue $"
            value={newSchoolRevenue.revenue}
            onChange={(e) =>
              setNewSchoolRevenue({
                ...newSchoolRevenue,
                revenue: e.target.value,
              })
            }
            className="border p-2 rounded"
          />
          <button
            onClick={handleAddSchoolRevenue}
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </>
)}

</main>

    </div>
  );
};

export default Settings;