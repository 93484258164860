import React from 'react';
import { Link } from 'react-router-dom';
import { 
  ChartBarIcon, 
  CalendarIcon, 
  ClipboardDocumentListIcon, 
  UserGroupIcon, 
  GlobeAltIcon 
} from '@heroicons/react/24/outline';

function MainDashboard() {
  return (
    <div className="container mx-auto p-6">
      {/* Logo */}
      <div className="flex justify-center mb-8">
        <img src="/logo512.png" alt="App Logo" className="h-32" />
      </div>
      {/* Cards Grid */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {/* Analytics (internal) */}
        <Link to="/analytics" className="p-6 bg-white shadow rounded-lg hover:shadow-lg transition flex items-center">
          <ChartBarIcon className="h-8 w-8 text-gray-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">Analytics</h2>
            <p className="text-gray-600">View detailed company analytics and stats.</p>
          </div>
        </Link>
        {/* Calendar Backend (external) */}
        <a
          href="https://chessat3-78fb0913f62f.herokuapp.com/manage-services"
          target="_blank"
          rel="noopener noreferrer"
          className="p-6 bg-white shadow rounded-lg hover:shadow-lg transition flex items-center"
        >
          <CalendarIcon className="h-8 w-8 text-gray-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">Club Calendar Backend</h2>
            <p className="text-gray-600">Manage the club calendar backend.</p>
          </div>
        </a>
        {/* Calendar Frontend (external) */}
        <a
          href="https://chessat3-78fb0913f62f.herokuapp.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="p-6 bg-white shadow rounded-lg hover:shadow-lg transition flex items-center"
        >
          <CalendarIcon className="h-8 w-8 text-gray-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">Club Calendar Frontend</h2>
            <p className="text-gray-600">View and manage the club calendar frontend.</p>
          </div>
        </a>
        {/* Booking Forms (external) */}
        <a
          href="https://join.chessat3.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="p-6 bg-white shadow rounded-lg hover:shadow-lg transition flex items-center"
        >
          <ClipboardDocumentListIcon className="h-8 w-8 text-gray-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">Booking Forms</h2>
            <p className="text-gray-600">Website booking forms backend.</p>
          </div>
        </a>
        {/* TutorCruncher (external) */}
        <a
          href="https://secure.tutorcruncher.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="p-6 bg-white shadow rounded-lg hover:shadow-lg transition flex items-center"
        >
          <UserGroupIcon className="h-8 w-8 text-gray-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">TutorCruncher</h2>
            <p className="text-gray-600">Access TutorCruncher backend.</p>
          </div>
        </a>
        {/* Chess At 3 Website (external) */}
        <a
          href="https://chessat3.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="p-6 bg-white shadow rounded-lg hover:shadow-lg transition flex items-center"
        >
          <GlobeAltIcon className="h-8 w-8 text-gray-500 mr-4" />
          <div>
            <h2 className="text-xl font-bold mb-2">Chess At 3 Website</h2>
            <p className="text-gray-600">Main Chess At 3 Website.</p>
          </div>
        </a>
      </div>
    </div>
  );
}

export default MainDashboard;
