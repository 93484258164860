import React, { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Typography, TextField } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import axios from 'axios';
import { DatePicker } from '@mui/x-date-pickers-pro';
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import StatisticCard from './StatisticCard'; // Assuming the card component is in the same folder
import { GridToolbar } from '@mui/x-data-grid-pro';

const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

const GravityBookingsStats = () => {
  const [loading, setLoading] = useState(false);
  const [bookingData, setBookingData] = useState([]);
  const [isYearlyMode, setIsYearlyMode] = useState(false);

  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
  const [startDate, setStartDate] = useState(dayjs().startOf('year')); // Default start of the year
  const [endDate, setEndDate] = useState(dayjs()); // Default to today
  const [bookingTypeStats, setBookingTypeStats] = useState([]);
  const [campaignStats, setCampaignStats] = useState([]);
  const [campaignRenames, setCampaignRenames] = useState([]);
// Holds aggregated spend data from the spend endpoint, e.g. [{ campaign: "TEST", total: 150 }]
const [campaignSpendStats, setCampaignSpendStats] = useState([]);

// Holds the merged data: for each campaign, combine booking count and spend total.
const [combinedCampaignStats, setCombinedCampaignStats] = useState([]);

  const [utmSourceStats, setUtmSourceStats] = useState([]);
  const [dateLabel, setDateLabel] = useState('Last 30 Days'); // Dynamic date label
  const [percentageChange, setPercentageChange] = useState(0); // To hold percentage difference


  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
  };

  const getPreviousPeriodDates = (startDate, endDate) => {
    const diff = endDate.diff(startDate, 'day');
    const previousStartDate = startDate.subtract(diff + 1, 'day'); // Previous period start
    const previousEndDate = startDate.subtract(1, 'day'); // Previous period end
  
    return { previousStartDate, previousEndDate };
  };
  

  const fetchBookingData = async () => {
    setLoading(true);
    try {
      const startDateWithTime = startDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const endDateWithTime = endDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
  
      const { previousStartDate, previousEndDate } = getPreviousPeriodDates(startDate, endDate);
      const previousStartDateWithTime = previousStartDate.startOf('day').format('YYYY-MM-DD HH:mm:ss');
      const previousEndDateWithTime = previousEndDate.endOf('day').format('YYYY-MM-DD HH:mm:ss');
  
      // Fetch current period data
      const currentPeriodResponse = await axios.get('https://chessat3analytics-16f70ecb95d8.herokuapp.com/gravity-data/bookings', {
        params: {
          startDate: startDateWithTime,
          endDate: endDateWithTime,
        },
      });
  
      // Fetch previous period data
      const previousPeriodResponse = await axios.get('https://chessat3analytics-16f70ecb95d8.herokuapp.com/gravity-data/bookings', {
        params: {
          startDate: previousStartDateWithTime,
          endDate: previousEndDateWithTime,
        },
      });
  
      const currentData = currentPeriodResponse.data;
      const previousData = previousPeriodResponse.data;
  
      setBookingData(currentData); // Update state with current data
  
      // Calculate percentage change between current and previous periods
      calculatePercentageChange(currentData, previousData);
  
      // Calculate statistics for the current period
      calculateBookingTypeStats(currentData);
      calculateCampaignStats(currentData);
      calculateUtmSourceStats(currentData);  
    } catch (error) {
      console.error('Error fetching booking data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignSpendData = async () => {
    try {
      const response = await axios.get("/api/campaign-spend");
      const allSpendRecords = Array.isArray(response.data) ? response.data : [];
      
      // Use the isYearlyMode flag instead of comparing dates
      const isYearly = isYearlyMode;
      
      let filteredRecords;
      if (isYearly) {
        const selectedYear = startDate.year();
        filteredRecords = allSpendRecords.filter(
          (record) => Number(record.year) === selectedYear
        );
      } else {
        const selectedMonth = startDate.month() + 1; // dayjs months are zero-indexed
        const selectedYear = startDate.year();
        filteredRecords = allSpendRecords.filter(
          (record) =>
            Number(record.month) === selectedMonth && Number(record.year) === selectedYear
        );
      }
    
      // Aggregate the totalCost per campaign.
      const totals = filteredRecords.reduce((acc, record) => {
        const campaign = record.campaign || 'Unknown';
        acc[campaign] = (acc[campaign] || 0) + Number(record.totalCost || 0);
        return acc;
      }, {});
    
      // Save as an array: e.g. [{ campaign: "TEST", total: 150 }]
      setCampaignSpendStats(
        Object.entries(totals).map(([campaign, total]) => ({ campaign, total }))
      );
    } catch (error) {
      console.error("Error fetching campaign spend data:", error);
    }
  };
  
  
  
  useEffect(() => {
    // Calculate booking counts per campaign from bookingData.
    const bookingCounts = bookingData.reduce((acc, row) => {
      const campaign = row.utm_campaign || 'Unknown';
      acc[campaign] = (acc[campaign] || 0) + 1;
      return acc;
    }, {});
  
    // Create a set of all campaigns from bookingCounts and campaignSpendStats.
    const campaigns = new Set([
      ...Object.keys(bookingCounts),
      ...campaignSpendStats.map(item => item.campaign)
    ]);
  
    // Build the combined array.
    const combined = [];
    campaigns.forEach(campaign => {
      combined.push({
        campaign,
        count: bookingCounts[campaign] || 0,
        total: campaignSpendStats.find(item => item.campaign === campaign)?.total || 0
      });
    });
    setCombinedCampaignStats(combined);
  }, [bookingData, campaignSpendStats]);
  
  
  const isYearly = startDate.isSame(startDate.startOf('year')) && endDate.isSame(endDate.endOf('year'));
  const spendLabel = isYearlyMode ? "Total Spend:" : "Monthly Spend:";


  useEffect(() => {
    const fetchCampaignRenames = async () => {
      const response = await axios.get("/api/campaign-renames");
      const renames = Array.isArray(response.data) ? response.data : [];
  
      const map = {};
      renames.forEach((r) => {
        map[r.campaign] = {
          label: r.label,
          cost: r.cost,  // This should be a number or at least parseable
        };
      });
      setCampaignRenames(map);
    };
  
    fetchCampaignRenames();
  }, []);
  

  

  const calculatePercentageChange = (currentData, previousData) => {
    // Sum up values for the current period (e.g., total revenue)
    const currentPeriodValue = currentData.reduce((sum, row) => sum + (Number(row.price) || 0), 0);
  
    // Sum up values for the previous period (e.g., total revenue)
    const previousPeriodValue = previousData.reduce((sum, row) => sum + (Number(row.price) || 0), 0);
  
    // Calculate percentage change
    if (previousPeriodValue === 0) {
      setPercentageChange(0); // Avoid division by zero
    } else {
      const percentage = ((currentPeriodValue - previousPeriodValue) / previousPeriodValue) * 100;
      setPercentageChange(parseFloat(percentage.toFixed(0))); // Set the percentage change with exactly two decimal places
    }
  };
  
  useEffect(() => {
    fetchBookingData();
    fetchCampaignSpendData();
  }, [startDate, endDate]);
  

  // Function to calculate booking type statistics
  const calculateBookingTypeStats = (data) => {
    const bookingTypeCount = data.reduce((acc, row) => {
      const bookingType = row.booking_type || 'Unknown';
      acc[bookingType] = (acc[bookingType] || 0) + 1;
      return acc;
    }, {});

    setBookingTypeStats(Object.entries(bookingTypeCount).map(([type, count]) => ({ type, count })));
  };

  // Function to calculate UTM source statistics
  const calculateUtmSourceStats = (data) => {
    const utmSourceCount = data.reduce((acc, row) => {
      const utmSource = row.utm_source || 'Unknown';
      acc[utmSource] = (acc[utmSource] || 0) + 1;
      return acc;
    }, {});

    setUtmSourceStats(Object.entries(utmSourceCount).map(([source, count]) => ({ source, count })));
  };

  // Function to calculate Campaign statistics
  const calculateCampaignStats = (data) => {
    const campaignCount = data.reduce((acc, row) => {
      const campaign = row.utm_campaign || 'Unknown';
      acc[campaign] = (acc[campaign] || 0) + 1;
      return acc;
    }, {});

    setCampaignStats(Object.entries(campaignCount).map(([campaign, count]) => ({ campaign, count })));
  };

  // Define the columns for the DataGridPro
  const columns = [
    { field: 'date_created', headerName: 'Date Created', width: 200 },
    { field: 'first_name', headerName: 'First Name', width: 150 },
    { field: 'last_name', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'utm_source', headerName: 'UTM Source', width: 200 },
    { field: 'utm_medium', headerName: 'UTM Medium', width: 200 },
    { field: 'utm_campaign', headerName: 'UTM Campaign', width: 200 },
    { field: 'booking_type', headerName: 'Booking Type', width: 200 },
    // { field: 'current_school_student_1', headerName: 'Student 1 School', width: 200 },
    // { field: 'current_school_student_2', headerName: 'Student 2 School', width: 200 },
    { field: 'price', headerName: 'Price ($)', width: 180, type: 'number' },  // Revenue Column
    { field: 'trial', headerName: 'Trial', width: 180, type: 'number' },  // Number of Trials Column
  ];

  // Calculate totals for footer (if required)
  const totalRevenue = bookingData.reduce((sum, row) => sum + (Number(row.price) || 0), 0);

  const totalTrials = bookingData.reduce((sum, row) => sum + (row.trial ? 1 : 0), 0);



  const setFilterLabel = (label) => {
    setDateLabel(label);
  };

  // Date shortcuts
  const setThisWeek = () => {
    setStartDate(dayjs().startOf('week'));
    setEndDate(dayjs().endOf('week'));
    setFilterLabel('This Week');
    setIsYearlyMode(false);
  };
  
  const setLastWeek = () => {
    setStartDate(dayjs().subtract(1, 'week').startOf('week'));
    setEndDate(dayjs().subtract(1, 'week').endOf('week'));
    setFilterLabel('Last Week');
    setIsYearlyMode(false);
  };
  
  const setThisMonth = () => {
    setStartDate(dayjs().startOf('month'));
    setEndDate(dayjs().endOf('month'));
    setFilterLabel('This Month');
    setIsYearlyMode(false);
  };
  
  const setLastMonth = () => {
    setStartDate(dayjs().subtract(1, 'month').startOf('month'));
    setEndDate(dayjs().subtract(1, 'month').endOf('month'));
    setFilterLabel('Last Month');
    setIsYearlyMode(false);
  };
  
  const setThisYear = () => {
    setStartDate(dayjs().startOf('year'));
    setEndDate(dayjs()); // Even if today is not Dec 31
    setFilterLabel('This Year');
    setIsYearlyMode(true);
  };
  
  
  const setLastYear = () => {
    setStartDate(dayjs().subtract(1, 'year').startOf('year'));
    setEndDate(dayjs().subtract(1, 'year').endOf('year'));
    setFilterLabel('Last Year');
    setIsYearlyMode(true);
  };
  

  const setQ1 = () => {
    setStartDate(dayjs().startOf('year'));  // January 1st
    setEndDate(dayjs().startOf('year').add(2, 'month').endOf('month')); // March 31st
    setFilterLabel('Q1');  // Update the label
  };

  const setQ2 = () => {
    setStartDate(dayjs().startOf('year').add(3, 'month'));  // April 1st
    setEndDate(dayjs().startOf('year').add(5, 'month').endOf('month'));  // June 30th
    setFilterLabel('Q2');  // Update the label
  };

  const setQ3 = () => {
    setStartDate(dayjs().startOf('year').add(6, 'month'));  // July 1st
    setEndDate(dayjs().startOf('year').add(8, 'month').endOf('month'));  // September 30th
    setFilterLabel('Q3');  // Update the label
  };

  const setQ4 = () => {
    setStartDate(dayjs().startOf('year').add(9, 'month'));  // October 1st
    setEndDate(dayjs().startOf('year').add(11, 'month').endOf('month'));  // December 31st
    setFilterLabel('Q4');  // Update the label
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: '100%' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchBookingData}
          disabled={loading}
          sx={{ mb: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : 'Refresh Data'}
        </Button>

        {/* Date Range Pickers */}
        <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        {/* Date Range Shortcuts */}
        <Box sx={{ mb: 2 }}>
          <Button variant="outlined" onClick={setThisWeek} sx={{ mr: 1 }}>
            This Week
          </Button>
          <Button variant="outlined" onClick={setLastWeek} sx={{ mr: 1 }}>
            Last Week
          </Button>
          <Button variant="outlined" onClick={setThisMonth} sx={{ mr: 1 }}>
            This Month
          </Button>
          <Button variant="outlined" onClick={setLastMonth} sx={{ mr: 1 }}>
            Last Month
          </Button>
          <Button variant="outlined" onClick={setThisYear} sx={{ mr: 1 }}>
            This Year
          </Button>
          <Button variant="outlined" onClick={setLastYear} sx={{ mr: 1 }}>
            Last Year
          </Button>

          {/* Quarter Shortcuts */}
          <Button variant="outlined" onClick={setQ1} sx={{ mr: 1 }}>
            Q1
          </Button>
          <Button variant="outlined" onClick={setQ2} sx={{ mr: 1 }}>
            Q2
          </Button>
          <Button variant="outlined" onClick={setQ3} sx={{ mr: 1 }}>
            Q3
          </Button>
          <Button variant="outlined" onClick={setQ4}>
            Q4
          </Button>
        </Box>

        {/* Statistics */}
        <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', justifyContent: 'left', mt: 4, mb: 4 }}>
          <StatisticCard
            title="Total Revenue"
            value={numberFormatter.format(totalRevenue)}  // Using the number formatter
            percentageChange={percentageChange}
            dateLabel={dateLabel} // Pass the dynamic date label
          />
          <StatisticCard
            title="Total Trials"
            value={totalTrials}  // Display total trials
            percentageChange={percentageChange}
            dateLabel={dateLabel} // Pass the dynamic date label
          />
        </Box>


        <div className="border-b border-gray-200 pb-5 mb-4">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
          Campaign Stats
        </h3>
        </div>
        <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', justifyContent: 'left', mb: 4 }}>
  {combinedCampaignStats.map(({ campaign, total, count }) => {
    const renameData = campaignRenames[campaign] || {};
    const friendlyName = renameData.label || campaign;
    return (
      <StatisticCard
        key={campaign}
        title={friendlyName}
        cost={total}               // Spend amount (aggregated based on the date range)
        costLabel={spendLabel}     // "Total Spend:" if isYearlyMode is true, otherwise "Monthly Spend:"
        value={count}              // Booking count
        percentageChange={percentageChange}
        dateLabel={dateLabel}
        sx={{ flex: '1 0 16%', maxWidth: '16%' }}
      />
    );
  })}
</Box>






<div className="border-b border-gray-200 pb-5 mb-4">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
            Booking Type Stats
        </h3>
        </div>
        
        <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', justifyContent: 'left', mb: 4 }}>
          {bookingTypeStats.map(({ type, count }, index) => (
            <StatisticCard
              key={type}
              title={`${type}`}
              value={count}  // Display booking type counts
              percentageChange={percentageChange}
            dateLabel={dateLabel} // Pass the dynamic date label
              sx={{
                flex: '1 0 16%', // Ensures 6 cards per row
        maxWidth: '16%',
              }}
            />
          ))}
        </Box>

       
<div className="border-b border-gray-200 pb-5 mb-4">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
            Source Stats
        </h3>
        </div>
        
      
        <Box sx={{ display: 'flex', gap: 3, flexWrap: 'wrap', justifyContent: 'left', mt: 4, mb: 4 }}>
          {utmSourceStats.map(({ source, count }) => (
            <StatisticCard
              key={source}
              title={`${source}`}
              value={count}  // Display UTM source counts
              percentageChange={percentageChange}
            dateLabel={dateLabel} // Pass the dynamic date label
              sx={{
                flex: '1 0 16%', // Ensures 6 cards per row
                maxWidth: '16%',
              }}
            />
          ))}
        </Box>

            
<div className="border-b border-gray-200 pb-5 mb-4">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
            Gravity Entries
        </h3>
        </div>

        <DataGridPro
          rows={bookingData}
          columns={columns}
          getRowId={(row) => row.id} // Use `id` for unique row identification
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[5, 10, 25, 50]}
          pagination
          autoHeight
          slots={{
            toolbar: GridToolbar,
          }}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default GravityBookingsStats;
