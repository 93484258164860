import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const EnhancedBarChart = ({ revenueData = [] }) => {
  // Define loading state
  const [loading, setLoading] = useState(true);

  // Simulate data loading (you can replace this with your actual data fetching logic)
  useEffect(() => {
    // Simulating an API call with a delay
    const timer = setTimeout(() => {
      setLoading(false); // Set loading to false once data is "loaded"
    }, 2000);

    // Cleanup timer
    return () => clearTimeout(timer);
  }, []);

  // Ensure revenueData is an array before using it
  const safeRevenueData = Array.isArray(revenueData) ? revenueData : [];

// Show loading spinner while data is loading
if (loading) {
  return (
    <Box
      sx={{
        height: 400,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2, // Add some spacing between the spinner and the text
        flexDirection: 'column', // Arrange spinner and text vertically
      }}
    >
      <CircularProgress />
      <Typography
        variant="h6"
        sx={{
          marginTop: 2,
          fontWeight: 'bold',
          color: 'text.secondary', // Use a theme color for better styling consistency
        }}
      >
        Fetching Data...
      </Typography>
    </Box>
  );
}

  // Early return if there's no data to avoid rendering errors
  if (!safeRevenueData || safeRevenueData.length === 0) {
    return (
      <Box sx={{ height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        No data available
      </Box>
    );
  }

  // Example data for the bar chart
  const chartLabels = safeRevenueData.map((row) => row.label);

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Total Revenue',
        data: safeRevenueData.map((row) => row.totalRevenue),
        backgroundColor: '#01579b',
        borderColor: '#01579b',
        borderWidth: 0,
      },
      {
        label: 'Total Tutor Cost',
        data: safeRevenueData.map((row) => row.totalContractorCost),
        backgroundColor: '#1976d2',
        borderColor: '#1976d2',
        borderWidth: 0,
      },
      {
        label: 'Total Profit',
        data: safeRevenueData.map((row) => row.totalProfit),
        backgroundColor: '#42a5f5',
        borderColor: '#42a5f5',
        borderWidth: 0,
        borderRadius: 10,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Allow chart to fill container
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#212121',
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        backgroundColor: '#fff',
        titleColor: '#333',
        bodyColor: '#333',
        titleFont: { size: 16 },
        bodyFont: { size: 14 },
        padding: 12,
        displayColors: false,
        borderWidth: 0,
        borderRadius: 10,
        boxPadding: 6,
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.dataset.label}: $${tooltipItem.raw.toLocaleString()}`;
          },
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: { display: false },
        ticks: { color: '#212121' },
      },
      y: {
        stacked: true,
        grid: { color: 'rgba(0,0,0,0.05)' },
        ticks: {
          color: '#212121',
          callback: function (value) {
            return `$${value}`;
          },
        },
      },
    },
    layout: {
      padding: {
        left: 10,
        right: 10,
        top: 10,
        bottom: 10,
      },
    },
    elements: {
      bar: {
        barThickness: 'flex', // Automatically adjusts thickness based on available space
      },
    },
  };

  return (
    <Box sx={{ height: 400, width: '100%', backgroundColor: 'white', borderRadius: 3, p: 2 }}>
      <Bar data={chartData} options={chartOptions} />
    </Box>
  );
};

export default EnhancedBarChart;
