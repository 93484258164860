import { useState, useEffect } from 'react';
import { Tabs, Tab, Box, CircularProgress, Button } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import axios from 'axios';

const GravityData = () => {
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0); // Manage the active tab
  const [bookingData, setBookingData] = useState([]); // Booking Form data
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  // Function to fetch data from the database
  const fetchBookingDataFromDB = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://chessat3analytics-16f70ecb95d8.herokuapp.com/gravity-data/bookings'); // Load saved bookings from DB
      setBookingData(response.data || []);
    } catch (error) {
      console.error('Error fetching booking data from DB:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount to display database records
  useEffect(() => {
    fetchBookingDataFromDB(); // Fetch the booking data when the component loads
  }, []); // Empty dependency array ensures this runs once on mount

  // Fetch new data from Gravity Forms and update the database
  const fetchBookingDataFromGravityForms = async () => {
    setLoading(true);
    try {
      await axios.get('https://chessat3analytics-16f70ecb95d8.herokuapp.com/gravity-data/fetch-bookings'); // Endpoint to fetch from Gravity Forms
      fetchBookingDataFromDB(); // Fetch the updated data from DB after the API call
    } catch (error) {
      console.error('Error fetching booking data from Gravity Forms:', error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    { field: 'id', headerName: 'Entry ID', width: 150 },
    { field: 'first_name', headerName: 'First Name', width: 150 },
    { field: 'middle_name', headerName: 'Middle Name', width: 150 },
    { field: 'last_name', headerName: 'Last Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'phone', headerName: 'Phone', width: 150 },
    { field: 'date_created', headerName: 'Date Created', width: 200 },
    { field: 'utm_content', headerName: 'UTM Content', width: 150 },
    { field: 'utm_term', headerName: 'UTM Term', width: 150 },
    { field: 'utm_source', headerName: 'UTM Source', width: 150 },
    { field: 'utm_medium', headerName: 'UTM Medium', width: 150 },
    { field: 'utm_campaign', headerName: 'UTM Campaign', width: 150 },
    { field: 'gclid', headerName: 'GCLID', width: 150 },
    { field: 'referral_code', headerName: 'Referral Code', width: 150 },
    { field: 'tutor_referral_code', headerName: 'Tutor Referral Code', width: 180 },
    { field: 'event_code', headerName: 'Event Code', width: 150 },
    { field: 'campaign_code', headerName: 'Campaign Code', width: 150 },
    { field: 'booking_type', headerName: 'Booking Type', width: 200 },
    { field: 'price', headerName: 'Price', width: 120, type: 'number' },
    { field: 'booking_type_price', headerName: 'Booking Type Price', width: 180, type: 'number' },
    { field: 'trial', headerName: 'Trial', width: 100 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'stripe_customer_id', headerName: 'Stripe Customer ID', width: 180 },
    { field: 'pm', headerName: 'PM', width: 100 },
    { field: 'pmc', headerName: 'PMC', width: 100 },
    { field: 'amt', headerName: 'Amount', width: 120, type: 'number' },
    { field: 'student_type', headerName: 'Student Type', width: 150 },
    { field: 'number_of_students', headerName: 'Number of Students', width: 200, type: 'number' },
    { field: 'current_school_student_1', headerName: 'Student 1 School', width: 200 },
    { field: 'current_school_student_2', headerName: 'Student 2 School', width: 200 },
    { field: 'timezone', headerName: 'Timezone', width: 150 },
    { field: 'payment_successful', headerName: 'Payment Successful', width: 180 },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      {/* Tabs for switching between Booking Form and Club Form */}
      <Tabs value={tabIndex} onChange={(event, newValue) => setTabIndex(newValue)}>
        <Tab label="Booking Form" />
        <Tab label="Club Booking Form" />
      </Tabs>

      {/* Button to fetch new data */}
      <Box sx={{ margin: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchBookingDataFromGravityForms}
          disabled={loading}
        >
          Fetch Booking Form Entries
        </Button>
      </Box>

      {/* Display loading spinner while fetching data */}
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="400px">
          <CircularProgress />
        </Box>
      ) : (
        <TabPanel value={tabIndex} index={0}>
          <DataGridPro
            rows={bookingData}
            columns={columns}
            getRowId={(row) => row.id}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[5, 10, 25, 50]}
            pagination
            autoHeight
          />
        </TabPanel>
      )}
    </Box>
  );
};

// TabPanel Component
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default GravityData;
