import React, { useRef, useState, useEffect } from "react";

import {
  Modal,
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Button,
  Stack, Chip

} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import EmailModal from "./EmailModal";


const emailSignatures = {
  Jessica: `<img src="https://i.imgur.com/WNQUlmV.jpeg" alt="Footer Image" style="width: 70%; height: auto; display: block; margin: 0 auto;" />`,
  Caitlin: `<img src="https://i.imgur.com/GYEU1tf.jpeg" alt="New Footer Image" style="width: 70%; height: auto; display: block; margin: 0 auto;" />`,
};


const RetentionReportModal = ({ open, onClose, reportData = {}, tutorId, tutorData = [], loading = false, excludedTutors = [] }) => {
  const isExcluded = excludedTutors.includes(String(tutorId)) || excludedTutors.includes(Number(tutorId));


  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [subject, setSubject] = useState(""); // Add subject state
  const [selectedSignature, setSelectedSignature] = useState("Jessica");

  const handleEmailReport = async (email, subject, message, selectedSignature) => {
    if (!email) {
      alert("Email is required.");
      return;
    }
    console.log("Selected Signature:", selectedSignature); // Debugging line

    
    const generateSvgChart = (percentage, color) => {
      const circumference = 36 * Math.PI; // Circle circumference (radius = 18)
      const progress = (percentage / 100) * circumference;
      const remaining = circumference - progress;
    
      return `
        <svg width="100" height="100" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            stroke="#e0e0e0"
            stroke-width="4"
          />
          <circle
            cx="18"
            cy="18"
            r="16"
            fill="none"
            stroke="${color}"
            stroke-width="4"
            stroke-dasharray="${progress.toFixed(2)} ${remaining.toFixed(2)}"
            stroke-dashoffset="25"
            stroke-linecap="round"
            transform="rotate(-90 18 18)"
          />
          <text
            x="18"
            y="22"
            font-size="8"
            fill="${color}"
            text-anchor="middle"
            font-weight="bold"
          >
            ${percentage}%
          </text>
        </svg>
      `;
    };
    
    const monthlyRetention = reportData.retentionRates.monthly || 0;
const avgMonthlyAllTutors = reportData.retentionRates.avgMonthlyAllTutors || 0;


    
const emailContent = `
<html>
  <head>
    <meta charset="UTF-8" />
    <title>Monthly Report</title>

    <style>
      body {
        margin: 0;
        padding: 20px;
        font-family: 'Roboto', Arial, sans-serif;
        background-color: #f9f9f9;
      }
      .container {
        max-width: 800px;
        margin: 20px auto;
        background: #fff;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      }
      .header {
        background-color: #6a469d;
        padding: 20px;
        text-align: center;
        color: #fff;
      }
      .header img {
        height: 40px;
        margin-bottom: 10px;
      }
      .section {
        padding: 20px;
        background-color: #fff;
        margin: 10px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      }
      .section h2 {
        color: #6a469d;
        border-bottom: 2px solid #eee;
        padding-bottom: 8px;
        margin-bottom: 20px;
      }
      .grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
      .box {
        background: #f9f9f9;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 20px;
        text-align: center;
      }
      .box h4 {
        color: #6a469d;
        margin-bottom: 10px;
      }
      .box p {
        font-size: 16px;
        font-weight: bold;
      }
      .congrats {
        padding: 20px;
        text-align: center;
        background-color: #F0FFF4;
        border: 2px dashed #4CAF50;
        color: #4CAF50;
        border-radius: 8px;
      }
      .congrats h3 {
        font-size: 1.5rem;
        font-weight: bold;
      }
    </style>

  </head>
  <body style="margin:0; padding:20px; font-family: 'Roboto', Arial, sans-serif; background-color: #f9f9f9;">
    <div style="max-width: 800px; margin: 20px auto; background: #fff; border-radius: 8px; overflow: hidden; box-shadow: 0 2px 4px rgba(0,0,0,0.1);">
      <!-- Header -->
      <div style="background-color: #6a469d; padding: 20px; text-align: center; color: #fff;">
        <img src="https://cdn.prod.website-files.com/64d4e8b883dfdc36c02531c1/66475753485c383c7c619f64_new-logo-w.png" alt="Logo" style="height: 40px; margin-bottom: 10px;" />
        <h1 style="margin: 0; font-size: 24px;">Monthly Report</h1>
        <p style="margin: 5px 0 0; font-size: 16px;">Report for ${reportData.tutorName || "Unknown Tutor"} - ${reportData.month || "Unknown Month"}</p>
      </div>

      <!-- Personal Message -->
      <div style="padding: 20px; background-color: #f4f4f4;">
        <h3 style="color: #6a469d; margin-top: 0;">Hi ${reportData.tutorName || "Tutor"},</h3>
        <p style="font-size: 14px; line-height: 1.5;">
          ${message || "We’re excited to share your performance report for this month. Keep up the great work!"}
        </p>
        <!-- Email Signature -->
        <div style="margin-top: 20px; text-align: center;">
          ${emailSignatures[selectedSignature] || emailSignatures.Jessica}
        </div>
      </div>

   <!-- Main Content -->

  <!-- Lessons Summary -->
<!-- Lessons Summary -->
<div style="padding: 20px; background-color: #FFF;">
  <h2 style="color: #6a469d; border-bottom: 2px solid #eee; padding-bottom: 8px; margin-bottom: 25px; text-align: center;">
    Lessons Summary
  </h2>

  <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0" style="width: 100%; max-width: 600px; margin: 0 auto;">
    <tr>
      ${[
        { label: "Total Lessons", value: finalLessons.total || 0 },
        { label: "Consistency Bonus", value: `$${finalLessons.consistencyBonus || 0}` },
      ]
        .map(
          item => `
            <td style="
              width: 50%;
              padding: 10px;
              text-align: center;
              vertical-align: top;
            ">
              <div style="
                background: #f9f9f9;
                border: 1px solid #ddd; /* Shadow Effect */
                border-radius: 8px;
                padding: 15px;
                text-align: center;
                display: inline-block;
                width: 90%;
              ">
                <h4 style="color: #6a469d; margin: 0 0 10px;">${item.label}</h4>
                <p style="font-size: 16px; font-weight: bold; margin: 0;">${item.value}</p>
              </div>
            </td>
          `
        )
        .join('')}
    </tr>
    <tr>
      ${[
        { label: "Additional Students", value: finalLessons.additionalStudents || 0 },
        { label: "Group Bonus", value: `$${finalLessons.groupBonus || 0}` },
      ]
        .map(
          item => `
            <td style="
              width: 50%;
              padding: 10px;
              text-align: center;
              vertical-align: top;
            ">
              <div style="
                background: #f9f9f9;
                border: 1px solid #ddd; /* Shadow Effect */
                border-radius: 8px;
                padding: 15px;
                text-align: center;
                display: inline-block;
                width: 90%;
              ">
                <h4 style="color: #6a469d; margin: 0 0 10px;">${item.label}</h4>
                <p style="font-size: 16px; font-weight: bold; margin: 0;">${item.value}</p>
              </div>
            </td>
          `
        )
        .join('')}
    </tr>
  </table>
</div>

<!-- Consistency Bonus -->
<div style="padding: 20px; background-color: #FFF;">
  <h2 style="color: #6a469d; border-bottom: 2px solid #eee; padding-bottom: 8px; margin-bottom: 25px; text-align: center;">
    Consistency Bonus
  </h2>

  <table role="presentation" width="100%" cellspacing="0" cellpadding="0" border="0" style="width: 100%; max-width: 600px; margin: 0 auto;">
    <tr>
      ${consistencyProgress
        .map(
          (bonus, index) => `
            <td style="
              width: 33.33%;
              padding: 10px;
              text-align: center;
              vertical-align: top;
            ">
              <div style="
                background: #f9f9f9;
                border: 1px solid #ddd; /* Shadow Effect */
                border-radius: 8px;
                padding: 15px;
                text-align: center;
                display: inline-block;
                width: 90%;
              ">
                <div style="margin-bottom: 15px;">
                  ${generateSvgChart(
                    bonus.progress,
                    index === 0 ? "#FFC107" : index === 1 ? "#03A9F4" : "#4CAF50"
                  )}
                </div>
                <h4 style="color: #6a469d; margin: 0 0 8px;">${bonus.threshold} Lessons</h4>
                <p style="margin: 5px 0; font-weight: bold;">${bonus.bonus}</p>
                <p style="margin: 0; color: #757575; font-size: 14px;">${bonus.status}</p>
              </div>
            </td>
          `
        )
        .join('')}
    </tr>
  </table>
</div>


        ${!isExcluded ? `
          <!-- Group Bonus Info -->
          <div style="padding: 20px; background-color: #FFF;">
          <h2 style="color: #6a469d; border-bottom: 2px solid #eee; padding-bottom: 5px;">Group Bonus Info</h2>
          <div style="display: flex; gap: 10px; flex-wrap: wrap; justify-content: center; padding: 10px; background: #f9f9f9; border: 1px solid #eee; border-radius: 8px; margin: 20px 0;">
            <span style="background-color: #e0f7fa; padding: 6px 12px; border-radius: 4px; font-weight: bold;">1 Student = $0</span>
            <span style="background-color: #ffecb3; padding: 6px 12px; border-radius: 4px; font-weight: bold;">2 = $10</span>
            <span style="background-color: #ffcdd2; padding: 6px 12px; border-radius: 4px; font-weight: bold;">3 = $20</span>
            <span style="background-color: #d1c4e9; padding: 6px 12px; border-radius: 4px; font-weight: bold;">4 = $30</span>
            <span style="background-color: #c8e6c9; padding: 6px 12px; border-radius: 4px; font-weight: bold;">5+ = $40+</span>
          </div>
          </div>
        ` : ''}

        <!-- Leaderboard -->
        <div style="padding: 20px; background-color: #FFF;">
        <h2 style="color: #6a469d; border-bottom: 2px solid #eee; padding-bottom: 5px;">Leaderboard (Top 5 Tutors)</h2>
        <table style="width: 100%; border-collapse: collapse; margin: 20px 0;">
          <thead>
            <tr style="background: #f4f4f4;">
              <th style="padding: 10px; border: 1px solid #ddd; text-align: left;">Tutor Name</th>
              <th style="padding: 10px; border: 1px solid #ddd; text-align: left;">Total Hours</th>
            </tr>
          </thead>
          <tbody>
            ${safeLeaderboard.length
              ? safeLeaderboard.map(entry => `
                <tr>
                  <td style="padding: 10px; border: 1px solid #ddd;">${entry.tutorName || "N/A"}</td>
                  <td style="padding: 10px; border: 1px solid #ddd;">${entry.totalHours || 0}</td>
                </tr>
              `).join('')
              : `<tr><td colspan="2" style="padding: 10px; text-align: center;">No data available</td></tr>`
            }
          </tbody>
        </table>

        <!-- Reviews -->
        <h2 style="color: #6a469d; border-bottom: 2px solid #eee; padding-bottom: 5px;">Reviews</h2>
        <div style="padding: 20px; background: #f9f9f9; border: 2px dashed #6a469d; border-radius: 8px; text-align: center; margin: 20px 0;">
          ${safeReviews.length > 0 
            ? safeReviews.map(review => `
                <div style="margin-bottom: 10px; padding-bottom: 10px; border-bottom: 1px solid #ddd;">
                  <strong>${review.reviewerName || "Anonymous"}:</strong> ${"⭐".repeat(review.rating || 0)}<br>
                  <p style="margin-top: 5px; font-size: 14px;">${review.text || "No review text available."}</p>
                </div>
              `).join('')
            : `
              <div>
                <p style="font-size: 1.2rem; font-weight: bold; color: #6a469d; margin-bottom: 10px;">😔 No reviews yet</p>
                <p style="font-size: 1rem; color: #555; margin-bottom: 10px;">You haven't received any reviews from your clients yet.</p>
                <p style="font-size: 1rem; color: #555;">Clients are sent an automatic review request every 5 lessons, but you can also encourage them to share feedback.</p>
              </div>
            `
          }
        </div>
        </div>

      <!-- Retention Overview -->
      <div style="padding: 20px; background-color: #FFF;">
<h2 style="color: #6a469d; border-bottom: 2px solid #eee; padding-bottom: 8px; margin-bottom: 25px;">
  Retention Overview
</h2>
<div style="display: grid; grid-template-columns: repeat(2, 1fr); gap: 20px; margin-bottom: 30px;">
  ${[
    {
      label: "Monthly Retention",
      description: "The percentage of clients who took lessons both last month and this month, showing how well you’re keeping clients.",
      value: retentionRates.monthly || 0,
      color: "#4CAF50"
    },
    {
      label: "All Tutors Avg Monthly Retention",
      description: "This metric represents the average monthly retention rate among all of our tutors, giving you a clear benchmark to see how your retention compares.",
      value: retentionRates.avgMonthlyAllTutors || 0,
      color: "#FFC107"
    }
  ]
    .map(metric => `
      <div style="background: #f9f9f9; border: 1px solid #ddd; border-radius: 8px; padding: 20px; text-align: center;">
        <div style="margin-bottom: 15px;">
          ${generateSvgChart(metric.value, metric.color)}
        </div>
        <h4 style="color: #6a469d; margin: 0 0 10px;">${metric.label}</h4>
        <p style="font-size: 18px; font-weight: bold; color: ${metric.color}; margin: 0 0 10px;">${metric.value}%</p>
        <p style="font-size: 14px; color: #555; margin: 0;">${metric.description}</p>
      </div>
    `)
    .join('')}
</div>
</div>


        <!-- Gone Cold Clients -->
        <div style="padding: 20px; background-color: #FFF;">
        <h2 style="color: #6a469d; border-bottom: 2px solid #eee; padding-bottom: 5px;">Gone Cold Clients</h2>
        <div style="padding: 20px; border-radius: 8px; margin: 20px 0; text-align: center; background-color: ${
          lostClients.filter(client => {
            const validClientName = client.client_name && !["unknown client", "no date available"].includes(client.client_name.trim().toLowerCase());
            const validLessonData = client.last_lesson_date && client.last_lesson_date.trim().toLowerCase() !== "invalid date" && client.total_lesson_count > 0;
            return validClientName && validLessonData;
          }).length === 0 ? '#F0FFF4' : '#FFCDD2'
        }; border: 2px dashed ${
          lostClients.filter(client => {
            const validClientName = client.client_name && !["unknown client", "no date available"].includes(client.client_name.trim().toLowerCase());
            const validLessonData = client.last_lesson_date && client.last_lesson_date.trim().toLowerCase() !== "invalid date" && client.total_lesson_count > 0;
            return validClientName && validLessonData;
          }).length === 0 ? '#4CAF50' : '#F44336'
        }; color: ${
          lostClients.filter(client => {
            const validClientName = client.client_name && !["unknown client", "no date available"].includes(client.client_name.trim().toLowerCase());
            const validLessonData = client.last_lesson_date && client.last_lesson_date.trim().toLowerCase() !== "invalid date" && client.total_lesson_count > 0;
            return validClientName && validLessonData;
          }).length === 0 ? '#4CAF50' : '#F44336'
        };">
          ${
            lostClients.filter(client => {
              const validClientName = client.client_name && !["unknown client", "no date available"].includes(client.client_name.trim().toLowerCase());
              const validLessonData = client.last_lesson_date && client.last_lesson_date.trim().toLowerCase() !== "invalid date" && client.total_lesson_count > 0;
              return validClientName && validLessonData;
            }).length === 0
            ? `
              <h3 style="font-size: 1.5rem; font-weight: bold; margin-bottom: 10px;">🎉 Congratulations!</h3>
              <p style="font-size: 1rem; margin-bottom: 10px;">No clients have gone cold this period. You're doing an excellent job retaining your clients!</p>
              <p style="font-size: 0.9rem; color: #388e3c;">Keep up the great work!</p>
            `
            : `
              <table style="width: 100%; border-collapse: collapse; margin: 0 auto;">
                <thead>
                  <tr style="background: #f4f4f4;">
                    <th style="padding: 10px; border: 1px solid #ddd; text-align: left;">Client Name</th>
                    <th style="padding: 10px; border: 1px solid #ddd; text-align: left;">Last Lesson Date</th>
                    <th style="padding: 10px; border: 1px solid #ddd; text-align: left;">Total Lessons</th>
                  </tr>
                </thead>
                <tbody>
                  ${lostClients
                    .filter(client => {
                      const validClientName = client.client_name && !["unknown client", "no date available"].includes(client.client_name.trim().toLowerCase());
                      const validLessonData = client.last_lesson_date && client.last_lesson_date.trim().toLowerCase() !== "invalid date" && client.total_lesson_count > 0;
                      return validClientName && validLessonData;
                    })
                    .sort((a, b) => b.total_lesson_count - a.total_lesson_count)
                    .map(client => `
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">${client.client_name.trim()}</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">${dayjs(client.last_lesson_date).isValid() ? dayjs(client.last_lesson_date).format("MMMM D, YYYY") : "No Date Available"}</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">${client.total_lesson_count || 0}</td>
                      </tr>
                    `).join('')
                  }
                </tbody>
              </table>
            `
          }
        </div>
        </div>

        ${!isExcluded ? `
          <!-- Group Bonus Breakdown -->
          <div style="padding: 20px; background-color: #FFF;">
          <h2 style="color: #6a469d; border-bottom: 2px solid #eee; padding-bottom: 5px;">Group Bonus Breakdown</h2>
          <table style="width: 100%; border-collapse: collapse; margin: 20px 0;">
            <thead>
              <tr style="background: #f4f4f4;">
                <th style="padding: 10px; border: 1px solid #ddd;">Lesson ID</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Lesson Status</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Eligible Students</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Counted Students</th>
                <th style="padding: 10px; border: 1px solid #ddd;">Bonus Earned</th>
              </tr>
            </thead>
            <tbody>
              ${groupSessions.length > 0 
                ? groupSessions
                    .filter(session => Number(session.counted_students) !== 0)
                    .map(session => `
                      <tr>
                        <td style="padding: 10px; border: 1px solid #ddd;">
                          <a href="https://secure.tutorcruncher.com/cal/appointments/${session.appointment_id || "N/A"}" target="_blank" style="text-decoration: none; color: #6a469d; font-weight: bold;">
                            ${session.appointment_id || "N/A"}
                          </a>
                        </td>
                        <td style="padding: 10px; border: 1px solid #ddd;">${session.appointment_status || "N/A"}</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">${session.total_students || "N/A"}</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">${session.counted_students || "N/A"}</td>
                        <td style="padding: 10px; border: 1px solid #ddd;">$${calculateGroupBonus(Number(session.counted_students) || 0)}</td>
                      </tr>
                    `).join('') 
                : `
                  <tr>
                    <td colspan="5" style="padding: 10px; text-align: center; font-weight: bold; color: #757575;">❌ No Group Sessions Found</td>
                  </tr>
                `
              }
            </tbody>
          </table>
        ` : ''}

      </div>
      </div>
    </div>
  </body>
</html>
`;

  
    try {
      const response = await fetch("/send-email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, subject, message: emailContent }), // Use emailContent here
      });
  
      const data = await response.json();
      if (response.ok) {
        alert("Email sent successfully!");
      } else {
        alert(`Error: ${data.error}`);
      }
    } catch (error) {
      console.error("Error sending email:", error);
      alert("Failed to send email.");
    }
  };

  function calculateGroupBonus(countedStudents) {
    if (countedStudents >= 5) return 40;
    if (countedStudents >= 4) return 30;
    if (countedStudents >= 3) return 20;
    if (countedStudents >= 2) return 10;
    return 0; // for countedStudents = 1
  }
  
  
  
  // near the top of RetentionReportModal, after we do `reportData = {}`
const {
  tutor_id: reportTutorId,
  tutorName = "Unknown Tutor",
  month = "Unknown Month",
  lessons = { total: 0, consistencyBonus: 0, additionalStudents: 0, groupBonus: 0 },
  reviews = [],
  retentionRates = { monthly: 0, annual: 0, avgMonthly: 0, avgAnnual: 0 },
  leaderboard = [],
} = reportData || {};


// Now you can do either:
// 1) Just use `retentionRates.avgMonthlyAllTutors` directly
// or 
// 2) Another destructure step:
const {
  monthly,
  annual,
  avgMonthly,
  avgAnnual,
  avgMonthlyAllTutors,
} = retentionRates;

const groupSessions = reportData?.groupSessionData || [];

// 2) Sum up all counted_students across sessions
const totalCountedStudents = groupSessions.reduce(
  (sum, session) => sum + Number(session.counted_students || 0),
  0
);
// Instead of a single call, sum up each row's bonus:
const totalGroupBonus = groupSessions.reduce((sum, session) => {
  return sum + calculateGroupBonus(Number(session.counted_students) || 0);
}, 0);

// 4) Create a “final” lessons object that overrides what the parent gave us
const finalLessons = {
  total: reportData?.lessons?.total || 0,  // ✅ Make sure we're pulling from the correct object
  consistencyBonus: lessons.consistencyBonus || 0,
  additionalStudents: isExcluded ? 0 : totalGroupBonus / 10,
  groupBonus: isExcluded ? 0 : totalGroupBonus,
};



    const modalContentRef = useRef();

    const handleDownloadPDF = async () => {
        if (!modalContentRef.current) {
          console.error("Element not found for PDF generation.");
          return;
        }
      
        // Temporarily hide elements with the class `hide-in-pdf`
        const elementsToHide = document.querySelectorAll(".hide-in-pdf");
        elementsToHide.forEach((el) => (el.style.display = "none"));
      
        try {
          const element = modalContentRef.current;
          const canvas = await html2canvas(element, { scale: 2 }); // Capture the element as a high-resolution canvas
          const imgData = canvas.toDataURL("image/png");
      
          const pdf = new jsPDF("p", "mm", [canvas.width * 0.264583, canvas.height * 0.264583]); // Adjust PDF size dynamically
      
          pdf.addImage(imgData, "PNG", 0, 0, canvas.width * 0.264583, canvas.height * 0.264583); // Add image to PDF
          pdf.save(`${tutorName}_Report_${month}.pdf`);
        } catch (error) {
          console.error("Error generating PDF:", error);
        }
      
        // Restore the display for elements
        elementsToHide.forEach((el) => (el.style.display = "block"));
      };
      
      
      
    const selectedTutor = tutorData.find((tutor) => tutor.tutor_name === tutorName);
    // Extract lost clients from tutorData
  const lostClients = selectedTutor?.lost_clients_details || [];

  const consistencyThresholds = [40, 60, 80];
  const groupBonus = [0, 10, 20, 30, 40];

  const calculateConsistencyProgress = (totalLessons) =>
    consistencyThresholds.map((threshold) => {
      const percentage = Math.min((totalLessons / threshold) * 100, 100).toFixed(1); // Limit to 1 decimal
      let status;
  
      // Determine the status message based on the progress percentage
      if (percentage == 100) {
        status = "You Did It!";
      } else if (percentage >= 71) {
        status = "Almost There!";
      } else if (percentage >= 40) {
        status = "So Close!";
      } else {
        status = "Future Goal!";
      }
  
      return {
        threshold,
        bonus: `$${threshold === 40 ? 200 : threshold === 60 ? 400 : threshold === 80 ? 600 : 0}`, // Fixed values
        progress: parseFloat(percentage), // Convert back to a number for UI display if needed
        status,
      };
    });
  
  
   
    

    useEffect(() => {
      console.log("🛠 Fetched Report Data:", reportData);
    }, [reportData]);

  const consistencyProgress = calculateConsistencyProgress(lessons?.total || 0);

  const calculateConsistencyBonus = (totalLessons) => {
    let lastBonus = 0;
    for (const threshold of consistencyThresholds) {
      if (totalLessons >= threshold) {
        lastBonus = threshold * 5; // Calculate bonus for the threshold met
      } else {
        break; // Stop checking if the current threshold isn't met
      }
    }
    return lastBonus;
  };
  
  
  console.log("Selected Tutor Lost Clients:", lostClients);


  const safeReviews = Array.isArray(reviews) ? reviews : [];
  
  const safeLeaderboard = Array.isArray(leaderboard) ? leaderboard : [];
 


  // Helper to render stars for reviews
  const renderStars = (rating) => "⭐".repeat(rating);

  if (loading) {
    return (
      <Modal open={open} onClose={onClose} aria-labelledby="loading-report-modal">
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: 4,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <CircularProgress size={60} sx={{ color: "#6a469d" }} />
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#6a469d" }}>
            Loading Report...
          </Typography>
        </Paper>
      </Modal>
    );
  }

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="retention-report-modal">
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: "800px",
          maxHeight: "90vh",
          overflowY: "auto",
          padding: 0, // Remove padding to allow header to span full width
        }}
      >


<Box
          sx={{
            backgroundColor: "#6a469d", // Purple background
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
<Button
    onClick={handleDownloadPDF}
    variant="contained"
    color="secondary"
    size="small"
    sx={{ marginRight: 2 }}
  >
    Download PDF
  </Button>

  <Button
  onClick={() => setEmailModalOpen(true)}
    variant="contained"
    color="secondary"
    size="small"
    sx={{ marginRight: 2 }}
  >
   Email Report
  </Button>

</Box>

        {/* Purple Header with Logo */}
        <Box ref={modalContentRef}>

        <Box
          sx={{
            backgroundColor: "#6a469d", // Purple background
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          <img
            src="https://cdn.prod.website-files.com/64d4e8b883dfdc36c02531c1/66475753485c383c7c619f64_new-logo-w.png"
            alt="Logo"
            style={{ height: "40px" }}
          />
          <Typography variant="h5" sx={{ color: "#fff", fontWeight: "bold" }}>
            Monthly Report
          </Typography>
         
            
 

          <IconButton className="hide-in-pdf" onClick={onClose} sx={{ color: "#fff" }}>
  <CloseIcon />
</IconButton>

          
         
        </Box>

        {/* Main Content */}
      

          {/* Tutor and Month Info */}
          <Box sx={{ padding: 4 }}>

  <Typography variant="h4" sx={{ fontWeight: "bold", color: "#6a469d",  mb: 4}}>
    Report for {tutorName} - {month}
  </Typography>



   


          {/* Lessons Section */}
        {/* Lessons Section */}
        <Typography variant="h5" sx={{ fontWeight: "bold", color: "#6a469d", mb: 2 }}>
  Lessons Summary
</Typography>



<Grid container spacing={2} sx={{ mb: 4 }}>
  {[
{ label: "Total Lessons", value: finalLessons.total || 0 },
{ label: "Consistency Bonus", value: `$${finalLessons.consistencyBonus || 0}` },
  ...(!isExcluded
    ? [
        { label: "Additional Students", value: finalLessons.additionalStudents || 0 },
        { label: "Group Bonus", value: `$${finalLessons.groupBonus || 0}` },
      ]
    : []),
].map((item, index) => (

    <Grid item xs={12} sm={6} key={index}>
    <Paper sx={{ padding: 3, textAlign: "center", border: "1px solid #eee", borderRadius: "8px" }}>

        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {item.label}
        </Typography>
        <Typography variant="h6">{item.value}</Typography>
      </Paper>
    </Grid>
  ))}
</Grid>


{/* Consistency Bonus Section */}
<Typography variant="h5" sx={{ fontWeight: "bold", color: "#6a469d", mb: 2}}>
  Consistency Bonus
</Typography>
<Grid container spacing={2} sx={{ mb: 4, }}>
  {consistencyProgress.map((bonus, index) => (
    <Grid item xs={12} sm={4} key={index} sx={{ display: "flex" }}>
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        {/* Full Grey Background Circle */}
        <CircularProgress
          variant="determinate"
          value={100}
          size={100}
          sx={{ color: "#e0e0e0", position: "absolute" }} // Light grey background
        />

        {/* Progress Circle with Custom Colors */}
        <CircularProgress
          variant="determinate"
          value={bonus.progress}
          size={100}
          sx={{
            color: index === 0 ? "#FFC107" : index === 1 ? "#03A9F4" : "#4CAF50", // Yellow, Blue, Green
          }}
        />

        {/* Display Bonus Value in the Center */}
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            fontWeight: "bold",
            fontSize: "1.5rem",
            color: "#6a469d",
            fontFamily: "Roboto, sans-serif", // Use a modern font
          }}
        >
          {bonus.progress}%
        </Box>
      </Box>

      {/* Bonus Information */}
      <Box sx={{ textAlign: "left", mt: 2, ml: 2 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", fontSize: "1rem", fontFamily: "Roboto, sans-serif" }}>
          {bonus.threshold} Lessons = {bonus.bonus}
        </Typography>
        <Typography variant="body2" sx={{ fontSize: "0.875rem", color: "#757575", fontFamily: "Roboto, sans-serif" }}>
          {bonus.status}
        </Typography>
      </Box>
    </Grid>
  ))}
</Grid>
{!isExcluded ? (
  <>
    <Typography variant="h5" sx={{ fontWeight: "bold", color: "#6a469d", mb: 2 }}>
      Group Bonus Info
    </Typography>

    <Box sx={{ width: "100%", padding: 2, border: "1px solid #eee", borderRadius: 2, mb: 2 }}>
      <Stack direction="row" spacing={2} justifyContent="space-evenly">
        <Chip label="1 Student = $0" sx={{ backgroundColor: "#e0f7fa", fontWeight: "bold" }} />
        <Chip label="2 = $10" sx={{ backgroundColor: "#ffecb3", fontWeight: "bold" }} />
        <Chip label="3 = $20" sx={{ backgroundColor: "#ffcdd2", fontWeight: "bold" }} />
        <Chip label="4 = $30" sx={{ backgroundColor: "#d1c4e9", fontWeight: "bold" }} />
        <Chip label="5+ = $40+" sx={{ backgroundColor: "#c8e6c9", fontWeight: "bold" }} />
      </Stack>
    </Box>

   
  </>
) : null } {/* 🔥 Completely Hide When isExcluded === true */}


    {/* Leaderboard */}
       <Box sx={{ mb: 4 }}>
  <Typography variant="h5" sx={{ fontWeight: "bold", color: "#6a469d", mb: 2 }}>
    Leaderboard (Top 5 Tutors)
  </Typography>
  <Table
    sx={{
      border: "1px solid #eee",
      width: "100%",
      "& .MuiTableCell-root": {
        padding: "4px 8px", // Smaller padding for a condensed look
        fontSize: "0.875rem", // Slightly smaller font size
        border: "1px solid #eee",
        textAlign: "center", // Center-align content
      },
      "& .MuiTableHead-root .MuiTableCell-root": {
        fontWeight: "bold", // Bold headers
        backgroundColor: "#f4f4f4", // Light background for headers
      },
    }}
  >
    <TableHead>
      <TableRow>
        <TableCell>Tutor Name</TableCell>
       
        <TableCell>Total Hours</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {leaderboard.map((entry, index) => (
        <TableRow key={index}>
          <TableCell>{entry.tutorName || "N/A"}</TableCell>
         
          <TableCell>{entry.totalHours || 0}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</Box>


      {/* Reviews Section */}
<Typography variant="h5" sx={{ fontWeight: "bold", color: "#6a469d", mb: 2, mt: 4 }}>
  Reviews
</Typography>
<Box sx={{ mb: 4 }}>
  {safeReviews.length > 0 ? (
    safeReviews.map((review, index) => (
      <Box key={index} sx={{ mb: 2 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          {renderStars(review.rating || 0)} {review.reviewerName || "Anonymous"}
        </Typography>
        <Typography variant="body1">{review.text || "No review text available"}</Typography>
      </Box>
    ))
  ) : (
    <Box
      sx={{
        textAlign: "center",
        padding: 4,
        border: "2px dashed #6a469d",
        borderRadius: 2,
        backgroundColor: "#f9f9f9",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.2rem",
          fontWeight: "bold",
          color: "#6a469d",
          mb: 1,
        }}
      >
        😔 No reviews yet
      </Typography>
      <Typography
        sx={{
          fontSize: "1rem",
          color: "#555",
          mb: 2,
        }}
      >
        You haven't received any reviews from your clients yet.
      </Typography>
      <Typography
        sx={{
          fontSize: "1rem",
          color: "#555",
          mb: 2,
        }}
      >
       Clients are sent an automatic review request every 5 lessons, ask your parents to let you know how you’re doing!
      </Typography>
     
    </Box>
  )}
</Box>


       {/* Retention Overview */}
<Typography variant="h5" sx={{ fontWeight: "bold", color: "#6a469d", mb: 3 }}>
  Retention Overview
</Typography>

{/* Monthly Retention Row */}
<Grid container spacing={3} sx={{ mb: 4 }}>
  {/* Monthly Retention */}
  <Grid item xs={12} sm={6}>
    <Paper
      sx={{
        padding: 3,
        textAlign: "center",
        border: "1px solid #eee",
        borderRadius: "8px",
      }}
    >
      {/* Circular Progress with Grey Outline */}
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        {/* Full Grey Circle */}
        <CircularProgress
          variant="determinate"
          value={100} // Full circle
          size={80}
          sx={{ color: "#e0e0e0", position: "absolute" }}
        />
        {/* Actual Progress */}
        <CircularProgress
          variant="determinate"
          value={retentionRates.monthly || 0}
          size={80}
          sx={{ color: "#4CAF50" }}
        />
      </Box>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        Monthly Retention
      </Typography>
      <Typography variant="h4" sx={{ color: "#4CAF50" }}>
        {retentionRates.monthly || 0}%
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        The percentage of clients who took lessons both last month and this
        month, showing how well you’re keeping clients.
      </Typography>
    </Paper>
  </Grid>

  {/* Avg Monthly Retention */}
  <Grid item xs={12} sm={6}>
    <Paper
      sx={{
        padding: 3,
        textAlign: "center",
        border: "1px solid #eee",
        borderRadius: "8px",
      }}
    >
      {/* Circular Progress with Grey Outline */}
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        {/* Full Grey Circle */}
        <CircularProgress
          variant="determinate"
          value={100} // Full circle
          size={80}
          sx={{ color: "#e0e0e0", position: "absolute" }}
        />
        {/* Actual Progress */}
        <CircularProgress
          variant="determinate"
          value={retentionRates.avgMonthlyAllTutors}
          size={80}
          sx={{ color: "#FFC107" }}
        />
      </Box>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 2 }}>
        All Tutors Avg Monthly Retention
      </Typography>
      <Typography variant="h4" sx={{ color: "#FFC107" }}>
      {retentionRates.avgMonthlyAllTutors}%
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
      This metric represents the average monthly retention rate among all of our tutors, giving you a clear benchmark to see how your retention compares.
</Typography>
    </Paper>
  </Grid>

  {/* Annual Retention */}
  {/* <Grid item xs={12} sm={4}>
    <Paper
      sx={{
        padding: 3,
        textAlign: "center",
        border: "1px solid #eee",
        borderRadius: "8px",
      }}
    >
         <CircularProgress
          variant="determinate"
          value={100} // Full circle
          size={80}
          sx={{ color: "#e0e0e0", position: "absolute" }}
        />
      <CircularProgress
        variant="determinate"
        value={retentionRates.annual || 0}
        size={80}
        sx={{ mb: 2, color: "#FF5722" }}
      />
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Annual Retention
      </Typography>
      <Typography variant="h4" sx={{ color: "#FF5722" }}>
        {retentionRates.annual || 0}%
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        The percentage of clients who stayed with you over the past 12 months,
        highlighting long-term retention.
      </Typography>
    </Paper>
  </Grid> */}

  {/* Avg Annual Retention */}
  {/* <Grid item xs={12} sm={6}>
    <Paper
      sx={{
        padding: 3,
        textAlign: "center",
        border: "1px solid #eee",
        borderRadius: "8px",
      }}
    >
         <CircularProgress
          variant="determinate"
          value={100} // Full circle
          size={80}
          sx={{ color: "#e0e0e0", position: "absolute" }}
        />
      <CircularProgress
        variant="determinate"
        value={retentionRates.avgAnnual || 0}
        size={80}
        sx={{ mb: 2, color: "#03A9F4" }}
      />
      <Typography variant="h6" sx={{ fontWeight: "bold" }}>
        Avg Annual Retention
      </Typography>
      <Typography variant="h4" sx={{ color: "#03A9F4" }}>
        {retentionRates.avgAnnual || 0}%
      </Typography>
      <Typography variant="body2" sx={{ mt: 1 }}>
        Average annual retention rate over multiple years, offering insight
        into long-term retention trends.
      </Typography>
    </Paper>
  </Grid> */}
</Grid>
<Typography variant="h5" sx={{ fontWeight: "bold", color: "#6a469d", mb: 3, mt: 3 }}>
  Gone Cold Clients (Lost Clients Period)
</Typography>

{lostClients.filter((client) => {
  // Filter out invalid data
  const validClientName =
    client.client_name &&
    client.client_name.trim().toLowerCase() !== "unknown client" &&
    client.client_name.trim().toLowerCase() !== "no date available";

  const validLessonData =
    client.last_lesson_date &&
    client.last_lesson_date.trim().toLowerCase() !== "invalid date" &&
    client.total_lesson_count > 0;

  return validClientName && validLessonData;
}).length === 0 ? (
  <Box
    sx={{
      textAlign: "center",
      padding: 4,
      border: "2px dashed #4CAF50",
      borderRadius: 2,
      backgroundColor: "#f0fff4",
      color: "#4CAF50",
      mb: 3,
    }}
  >
    <Typography
      sx={{
        fontSize: "1.5rem",
        fontWeight: "bold",
        mb: 1,
      }}
    >
      🎉 Congratulations!
    </Typography>
    <Typography
      sx={{
        fontSize: "1rem",
        fontWeight: "normal",
        color: "#388e3c",
      }}
    >
      No clients have gone cold in this period. You're doing an excellent job retaining your clients!
    </Typography>
    <Typography
      sx={{
        fontSize: "0.9rem",
        fontWeight: "normal",
        mt: 1,
        color: "#388e3c",
      }}
    >
      Keep up the great work and continue to engage with your students for long-term success. 💪
    </Typography>
  </Box>
) : (
  <Table
    sx={{
      border: "1px solid #eee",
      width: "100%",
      "& .MuiTableCell-root": {
        padding: "4px 8px",
        fontSize: "0.875rem",
        border: "1px solid #eee",
        textAlign: "center",
      },
      "& .MuiTableHead-root .MuiTableCell-root": {
        fontWeight: "bold",
        backgroundColor: "#f4f4f4",
      },
    }}
  >
    <TableHead>
      <TableRow>
        <TableCell>Client Name</TableCell>
        <TableCell>Last Lesson Date</TableCell>
        <TableCell>Total Lifetime Lessons</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {lostClients
        .filter((client) => {
          // Filter out invalid data
          const validClientName =
            client.client_name &&
            client.client_name.trim().toLowerCase() !== "unknown client" &&
            client.client_name.trim().toLowerCase() !== "no date available";

          const validLessonData =
            client.last_lesson_date &&
            client.last_lesson_date.trim().toLowerCase() !== "invalid date" &&
            client.total_lesson_count > 0;

          return validClientName && validLessonData;
        })
        .sort((a, b) => b.total_lesson_count - a.total_lesson_count) // Sort by total_lesson_count (descending)
        .map((client, index) => (
          <TableRow key={index}>
            <TableCell>{client.client_name.trim()}</TableCell>
            <TableCell>
              {dayjs(client.last_lesson_date).isValid()
                ? dayjs(client.last_lesson_date).format("MMMM D, YYYY")
                : "No Date Available"}
            </TableCell>
            <TableCell>{client.total_lesson_count || 0}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  </Table>

  
)}

{!isExcluded && (
  <>
    <Typography variant="h5" sx={{ fontWeight: "bold", color: "#6a469d", mb: 2, mt: 4 }}>
      Group Bonus Breakdown
    </Typography>

    <Table
      sx={{
        border: "1px solid #eee",
        width: "100%",
        "& .MuiTableCell-root": {
          padding: "4px 8px",
          fontSize: "0.875rem",
          border: "1px solid #eee",
          textAlign: "center",
        },
        "& .MuiTableHead-root .MuiTableCell-root": {
          fontWeight: "bold",
          backgroundColor: "#f4f4f4",
        },
      }}
    >
      <TableHead>
        <TableRow>
          <TableCell>Lesson ID</TableCell>
          <TableCell>Lesson Status</TableCell>
          <TableCell>Eligible Students</TableCell>
          <TableCell>Counted Students</TableCell>
          <TableCell>Bonus Earned</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {groupSessions.length > 0 ? (
          groupSessions
            .filter((session) => Number(session.counted_students) !== 0)
            .map((session, index) => (
              <TableRow key={session.appointment_id || index}>
                <TableCell>
                  <a
                    href={`https://secure.tutorcruncher.com/cal/appointments/${session.appointment_id || "N/A"}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none", color: "#6a469d", fontWeight: "bold" }}
                  >
                    {session.appointment_id || "N/A"}
                  </a>
                </TableCell>
                <TableCell>{session.appointment_status || "N/A"}</TableCell>
                <TableCell>{session.total_students || "N/A"}</TableCell>
                <TableCell>{session.counted_students || "N/A"}</TableCell>
                <TableCell>
                  ${calculateGroupBonus(Number(session.counted_students) || 0)}
                </TableCell>
              </TableRow>
            ))
        ) : (
          <TableRow>
            <TableCell colSpan={5} sx={{ textAlign: "center", fontWeight: "bold", color: "#757575" }}>
              ❌ No Group Sessions Found
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </>
)}



</Box>
        </Box>

        <EmailModal
  open={emailModalOpen}
  onClose={() => setEmailModalOpen(false)}
  onSend={handleEmailReport}
  defaultTutorId={tutorId} // Pass the tutor ID from the modal props
  defaultTutorName={tutorName || ""} // Optional, for passing the tutor's name
  selectedSignature={selectedSignature} // Ensure selectedSignature is passed down

/>




      </Paper>
    </Modal>
  );
};

export default RetentionReportModal;
