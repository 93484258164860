import React from 'react';
import { Tooltip, Chip, SvgIcon } from '@mui/material';
import { Box, Typography } from '@mui/joy';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Avatar from '@mui/joy/Avatar';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Currency formatter
const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const StatisticCard = ({
  title,
  cost, 
  costLabel = "Total Spend:",  // Default label; can be overridden
  subValue,                    // Optional additional small text
  value,
  percentageChange,
  isPositive,
  hidePercentageChange,
  icon = null,                 // Optional icon for the circle
  bgColor = 'neutral.softBg',  // Background color for the card
  iconBgColor = 'neutral.softBg', // Background color for the icon circle
  iconColor = 'neutral.solidActive', // Color for the icon
  ...props
}) => {
  return (
    <Card
      variant="solid"
      sx={{
        minWidth: 275,
        borderRadius: 'md',
        display: 'flex',
        alignItems: 'left',
        padding: 2,
        gap: 2,
        backgroundColor: bgColor,
        color: 'white',
        ...props.sx,
      }}
    >
      {/* Optional Icon */}
      {icon && (
        <Avatar
          size="lg"
          sx={{
            bgcolor: iconBgColor,
            color: iconColor,
            width: 56,
            height: 56,
          }}
        >
          <SvgIcon>{icon}</SvgIcon>
        </Avatar>
      )}

      <CardContent
        orientation="vertical"
        sx={{
          flex: 1,
          textAlign: 'left',
        }}
      >
        {/* Title and optional subValue (if you wish to display it next to the title) */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Typography level="body-md" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {subValue && (
            <Typography level="body-xs" sx={{ ml: 2, color: 'gray' }}>
              {subValue}
            </Typography>
          )}
        </Box>

        {/* Cost line */}
        {cost != null && (
          <Typography level="body-xs" sx={{ mt: 0.25 }}>
            {costLabel} {currencyFormatter.format(cost)}
          </Typography>
        )}

        {/* Main Value */}
        <Typography level="h2" sx={{ fontWeight: 'bold' }}>
          {value}
        </Typography>

        {/* Percentage Change Chip */}
        {!hidePercentageChange && (
          <Chip
            size="small"
            sx={{
              backgroundColor: isPositive ? 'rgba(0, 200, 0, 0.1)' : 'rgba(200, 0, 0, 0.1)',
              color: isPositive ? 'green' : 'red',
              borderRadius: '16px',
              padding: '0 8px',
              fontWeight: 'bold',
              display: 'flex',
              alignItems: 'center',
              marginTop: 1,
            }}
            icon={isPositive ? <ArrowUpwardIcon fontSize="small" /> : <ArrowDownwardIcon fontSize="small" />}
            label={`${Math.abs(percentageChange)}%`}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default StatisticCard;
