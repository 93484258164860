import { useEffect, useState } from 'react';
import { Tabs, Tab, Button, Box, CircularProgress, Paper, Stack, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';


import { DataGridPro } from '@mui/x-data-grid-pro';
import axios from 'axios';

const fetchData = async (endpoint) => {
  try {
    const token = localStorage.getItem('token');  // Assuming you store the token in localStorage
    const response = await axios.get(`/${endpoint}`, {
      headers: {
        'Authorization': `Bearer ${token}`  // Include the token in the request headers
      }
    });
    console.log("API Response for", endpoint, response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    return [];
  }
};




  const ServiceDetailsModal = ({ open, onClose, recipients, contractors }) => (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Service Details</DialogTitle>
      <DialogContent>
        {/* Recipients */}
        <Typography variant="h6">Recipients</Typography>
        <DataGridPro
          density="compact"
          columns={[
            { field: 'name', headerName: 'Recipient Name', flex: 1 },
            
          ]}
          rows={
            recipients.length > 0
              ? recipients.map((recipient, index) => ({
                  id: `recipient-${index}`,
                  name: recipient.recipient_name,
                  
                }))
              : []
          }
          autoHeight
          hideFooter
        />
  
        {/* Contractors */}
        <Typography variant="h6" sx={{ mt: 3 }}>Contractors</Typography>
        <DataGridPro
          density="compact"
          columns={[
            { field: 'name', headerName: 'Contractor Name', flex: 1 },
          
          ]}
          rows={
            contractors.length > 0
              ? contractors.map((contractor, index) => ({
                  id: `contractor-${index}`,
                  name: contractor.contractor_name,
                 
                }))
              : []
          }
          autoHeight
          hideFooter
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
  

  
  
  // Modal to show appointment details
const AppointmentDetailsModal = ({ open, onClose, recipients, contractors }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
    <DialogTitle>Appointment Details</DialogTitle>
    <DialogContent>
      {/* Recipients */}
      <Typography variant="h6">Recipients</Typography>
      <DataGridPro
        density="compact"
        columns={[
          { field: 'name', headerName: 'Recipient Name', flex: 1 },
          { field: 'status', headerName: 'Status', flex: 1  },
          { field: 'charge_rate', headerName: 'Charge Rate', type: 'number', width: 150 },
        ]}
        rows={
          recipients.length > 0
            ? recipients.map((recipient, index) => ({
                id: `recipient-${index}`,
                name: recipient.recipient_name,
                status: recipient.recipient_status,
                charge_rate: recipient.recipient_charge_rate,
              }))
            : []
        }
        autoHeight
        hideFooter
      />

      {/* Contractors */}
      <Typography variant="h6" sx={{ mt: 3 }}>Contractors</Typography>
      <DataGridPro
        density="compact"
        columns={[
          { field: 'name', headerName: 'Contractor Name', flex: 1 },
          { field: 'pay_rate', headerName: 'Pay Rate', type: 'number', width: 150 },
        ]}
        rows={
          contractors.length > 0
            ? contractors.map((contractor, index) => ({
                id: `contractor-${index}`,
                name: contractor.contractor_name,
                pay_rate: contractor.contractor_pay_rate,
              }))
            : []
        }
        autoHeight
        hideFooter
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);
  
  
  
// Main Component
const TutorCruncherData = () => {
  const [loading, setLoading] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [data, setData] = useState({ clients: [], services: [], recipients: [], appointments: [] });
  const [selectedAppointment, setSelectedAppointment] = useState(null); // State to hold the currently selected appointment details
  const [selectedService, setSelectedService] = useState(null); // State to hold the currently selected service details
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false); // Service modal state
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false); // Appointment modal state
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

   // Handle Fix Dates button click
   const handleFixDates = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/fix-dates');
      alert(response.data); // Show success message
    } catch (error) {
      console.error('Error fixing dates:', error);
      alert('Failed to fix dates');
    } finally {
      setLoading(false);
    }
  };

 // Handle pagination changes
 const handlePaginationModelChange = (model) => {
  setPaginationModel(model);
};


const handleInitialDataFetch = async () => {
  setLoading(true);
  try {
    const token = localStorage.getItem('token'); // Retrieve the token from localStorage
    if (!token) {
      alert('No authentication token found. Please log in.');
      setLoading(false);
      return;
    }

    await axios.get('/update-data', {
      headers: {
        'Authorization': `Bearer ${token}`, // Include the token in the request headers
      },
    });

    alert('Data updated successfully!');
    loadData(tabIndex); // Reload data for the currently active tab
  } catch (error) {
    console.error('Error updating data:', error);
    alert('Failed to update data');
  } finally {
    setLoading(false);
  }
};


   // Load data for the selected tab
  //  const loadData = async (tab) => {
  //   setLoading(true);
  //   const endpoints = ['clients', 'recipients', 'services', 'appointments'];
  //   const result = await fetchData(endpoints[tab]);
  //   if (result && result.length > 0) {
  //     setData((prev) => ({ ...prev, [endpoints[tab]]: result }));
  //   } else {
  //     console.warn(`No data found for ${endpoints[tab]}`);
  //   }
  //   setLoading(false);
  // };

  // Load data for the selected tab
const loadData = async (tab) => {
  setLoading(true);
  const endpoints = ['clients', 'recipients', 'services', 'appointments'];
  const result = await fetchData(endpoints[tab]);
  if (result && result.length > 0) {
    // Enrich appointment data with labels from services
    if (tab === 3) { // If the current tab is appointments
      const services = data.services; // Assuming services have already been loaded
      const enrichedAppointments = result.map((appointment) => {
        const service = services.find((s) => s.service_id === appointment.service_id);
        return {
          ...appointment,
          labels: service ? service.labels : [], // Add labels from the associated service
        };
      });
      setData((prev) => ({ ...prev, [endpoints[tab]]: enrichedAppointments }));
    } else {
      setData((prev) => ({ ...prev, [endpoints[tab]]: result }));
    }
  } else {
    console.warn(`No data found for ${endpoints[tab]}`);
  }
  setLoading(false);
};


 // Open the modal when the "View Details" button is clicked for services
 const handleViewServiceDetailsClick = (row) => {
  console.log("Selected Service:", row);
  setSelectedService(row);
  setIsServiceModalOpen(true);
};


// Open the modal when the "View Details" button is clicked for appointments
const handleViewAppointmentDetailsClick = (row) => {
  setSelectedAppointment(row);
  setIsAppointmentModalOpen(true);
};

// Close the service modal
const handleCloseServiceModal = () => {
  setIsServiceModalOpen(false);
  setSelectedService(null);
};

// Close the appointment modal
const handleCloseAppointmentModal = () => {
  setIsAppointmentModalOpen(false);
  setSelectedAppointment(null);
};

const handleTabChange = (event, newValue) => {
  setTabIndex(newValue);
  loadData(newValue);
};


useEffect(() => {
  // Load all data initially to ensure services are available when appointments are loaded
  const loadAllData = async () => {
    setLoading(true);
    const services = await fetchData('services');
    const appointments = await fetchData('appointments');

    if (services.length > 0 && appointments.length > 0) {
      // Enrich appointments with labels from services
      const enrichedAppointments = appointments.map((appointment) => {
        const service = services.find((s) => s.service_id === appointment.service_id);
        return {
          ...appointment,
          labels: service ? service.labels : [], // Add labels from the associated service
        };
      });
      setData({
        clients: data.clients,
        services: services,
        recipients: data.recipients,
        appointments: enrichedAppointments,
      });
    } else {
      console.warn('No data found for services or appointments');
    }
    setLoading(false);
  };

  loadAllData(); // Load all data initially
}, []);


  const columns = {
    clients: [
      { field: 'client_id', headerName: 'Client ID', width: 150 },
      { field: 'first_name', headerName: 'First Name', width: 200 },
      { field: 'last_name', headerName: 'Last Name', width: 200 },
      { field: 'email', headerName: 'Email', width: 250 },
     
      { field: 'status', headerName: 'Status', width: 150 },
    ],
    
    services: [
      { field: 'service_id', headerName: 'Job ID', width: 150 },
      { field: 'name', headerName: 'Job Name', width: 200 },
      //{ field: 'description', headerName: 'Description', width: 250 },
      { field: 'dft_charge_type', headerName: 'Charge Type', width: 150 },
      { field: 'dft_charge_rate', headerName: 'Charge Rate', width: 150 },
      { field: 'dft_contractor_rate', headerName: 'Tutor Rate', width: 150 },
      { field: 'status', headerName: 'Status', width: 150 },
      {
        field: 'labels',
        headerName: 'Labels',
        width: 250,
        renderCell: (params) => {
          // Check if the labels value is an array
          const labelsArray = params.value;
      
          // If labelsArray exists and is an array, join the labels into a comma-separated string
          return Array.isArray(labelsArray) 
            ? labelsArray.join(', ') 
            : 'No labels'; // Default if there are no labels
        },
      },
      
      // {
      //   field: 'details',
      //   headerName: 'Details',
      //   renderCell: (params) => (
      //     <Button variant="outlined" onClick={() => handleViewServiceDetailsClick(params.row)}>
      //       View Details
      //     </Button>
      //   ),
      //   width: 150,
      // },
    ],
  
    recipients: [
      { field: 'recipient_id', headerName: 'Student ID', width: 150 },
      { field: 'first_name', headerName: 'First Name', width: 200 },
      { field: 'last_name', headerName: 'Last Name', width: 200 },
     
    ],
  
    appointments: [
      { field: 'appointment_id', headerName: 'Lesson ID', width: 150 },
      { field: 'service_id', headerName: 'Job ID', width: 150 },
      { field: 'start', headerName: 'Start', width: 200 },
      { field: 'finish', headerName: 'Finish', width: 200 },
      { field: 'units', headerName: 'Units', width: 150 },
      { field: 'status', headerName: 'Status', width: 150 },
      {
        field: 'labels',
        headerName: 'Labels',
        width: 250,
        renderCell: (params) => {
          // Check if the labels value is an array
          const labelsArray = params.value;
  
          // If labelsArray exists and is an array, join the labels into a comma-separated string
          return Array.isArray(labelsArray)
            ? labelsArray.join(', ')
            : 'No labels'; // Default if there are no labels
        },
      },
      {
        field: 'details',
        headerName: 'Details',
        renderCell: (params) => (
          <Button variant="outlined" onClick={() => handleViewAppointmentDetailsClick(params.row)}>
            View Details
          </Button>
        ),
        width: 150,
      },
    ],
  };

  


  return (
    
    <Box sx={{ width: '100%' }}>
      {/* <Button 
        variant="contained" 
        color="primary" 
        onClick={handleInitialDataFetch}  
        disabled={loading}
      >
        {loading ? <CircularProgress size={24}/> : 'Data Fetch'}
      </Button> */}


      <Tabs value={tabIndex} onChange={handleTabChange}>
        <Tab label="Clients" />
        <Tab label="Students" />
        <Tab label="Jobs" />
        <Tab label="Lessons" />
      </Tabs>

      <TabPanel value={tabIndex} index={0}>
        <DataGridPro
          rows={data.clients}
          columns={columns.clients}
          getRowId={(row) => row.id} 
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[5, 10, 25, 50]}
          pagination
          autoHeight
        />
      </TabPanel>

      <TabPanel value={tabIndex} index={1}>
        <DataGridPro
          rows={data.recipients}
          columns={columns.recipients}
          getRowId={(row) => row.id} 
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[5, 10, 25, 50]}
          pagination
          autoHeight
        />
      </TabPanel>

      <TabPanel value={tabIndex} index={2}>
        <DataGridPro
          rows={data.services}
          columns={columns.services}
          getRowId={(row) => row.service_id} 
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[5, 10, 25, 50]}
          pagination
          autoHeight
        />
      </TabPanel>

      <TabPanel value={tabIndex} index={3}>
        <DataGridPro
          rows={data.appointments}
          columns={columns.appointments}
          getRowId={(row) => row.appointment_id} 
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[5, 10, 25, 50]}
          pagination
          autoHeight
        />
      </TabPanel>


      {/* Modal for showing service details */}
      {selectedService && (
        <ServiceDetailsModal
        open={isServiceModalOpen}
        onClose={handleCloseServiceModal}
        recipients={selectedService.recipients || []}  // Ensure this is not undefined
        contractors={selectedService.contractors || []}  // Ensure this is not undefined
      />
      
      )}

      {/* Modal for showing appointment details */}
      {selectedAppointment && (
        <AppointmentDetailsModal
          open={isAppointmentModalOpen}
          onClose={handleCloseAppointmentModal}
          recipients={selectedAppointment.recipients || []}
          contractors={selectedAppointment.contractors || []}
        />
      )}
    </Box>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export default TutorCruncherData;