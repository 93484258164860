import { useState, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';

import axios from 'axios';
import { darken, lighten, styled } from '@mui/material/styles';
import {
  DataGridPro,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";


// Helper function to get background color
const getBackgroundColor = (color, theme, coefficient) => ({
  backgroundColor: darken(color, coefficient),
  ...(theme.palette.mode === 'light' && {
    backgroundColor: lighten(color, coefficient),
  }),
});

// Styled DataGrid with row color styling based on revenue
const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  '& .super-app-theme--High': {
    ...getBackgroundColor(theme.palette.success.main, theme, 0.6),
    '&:hover': {
      ...getBackgroundColor(theme.palette.success.main, theme, 0.5),
    },
  },
  '& .super-app-theme--Medium': {
    ...getBackgroundColor(theme.palette.warning.main, theme, 0.6),
    '&:hover': {
      ...getBackgroundColor(theme.palette.warning.main, theme, 0.5),
    },
  },
  '& .super-app-theme--Low': {
    ...getBackgroundColor(theme.palette.error.main, theme, 0.6),
    '&:hover': {
      ...getBackgroundColor(theme.palette.error.main, theme, 0.5),
    },
  },
}));



const ClientOverview = () => {
  const [loading, setLoading] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const fetchClientOverviewData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage after login
      if (!token) {
        throw new Error('No authentication token found. Please login.');
      }

      const response = await axios.post('/api/client-overview', {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setClientData(response.data.clientOverview || []);
    } catch (error) {
      console.error('Error fetching client overview data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientOverviewData();
  }, []);

  useEffect(() => {
    console.log('Client Data:', clientData);
  }, [clientData]);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fileName: "LessonDetails" }} />
      </GridToolbarContainer>
    );
  };

  const columns = [
    { field: 'client_id', headerName: 'TutorCruncher ID', width: 200 },
    { field: 'client_name', headerName: 'Client Name', width: 200 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'source', headerName: 'Source (from Gravity)', width: 200 },
    { 
      field: 'total_revenue', 
      headerName: 'Total Revenue $ (All Complete & CBC)', 
      width: 250, 
      type: 'number',
      
      cellClassName: (params) => {
        if (params.value > 3500) {
          return 'super-app-theme--High';
        } else if (params.value > 1500) {
          return 'super-app-theme--Medium';
        } else {
          return 'super-app-theme--Low';
        }
      },
    },
    { field: 'total_lessons', headerName: 'Total Lessons', width: 150, type: 'number' },
    { field: 'total_hours', headerName: 'Total Hours', width: 150, type: 'number' },
    { field: 'number_of_students', headerName: 'Number of Students', width: 200, type: 'number' },
  ];

  return (
    <Box sx={{ width: '100%' }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="400px">
          <CircularProgress />
        </Box>
      ) : (
        <StyledDataGrid
          rows={clientData} // Use clientData directly
          columns={columns}
          getRowId={(row) => row.client_id} // Use client_id as the unique identifier
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[5, 10, 25, 50]}
          pagination
          autoHeight
          initialState={{
            sorting: {
              sortModel: [{ field: 'total_revenue', sort: 'desc' }],
            },
          }}
           slots={{
                      toolbar: GridToolbar,
         }}
        />
      )}
    </Box>
  );
};

export default ClientOverview;
