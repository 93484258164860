import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import axios from "axios";

export default function UserManagement() {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    role: "user",
    password: "",
  });
  const [open, setOpen] = useState(false);
  const [editingUserId, setEditingUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch users when component mounts
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://chessat3analytics-16f70ecb95d8.herokuapp.com/api/users");
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleOpen = (user = null) => {
    if (user) {
      setFormData({
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        role: user.role,
        password: "",
      });
      setEditingUserId(user.id);
    } else {
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        role: "user",
        password: "",
      });
      setEditingUserId(null);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      role: "user",
      password: "",
    });
    setEditingUserId(null);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const url = editingUserId
      ? `https://chessat3analytics-16f70ecb95d8.herokuapp.com/api/users/${editingUserId}`
      : "https://chessat3analytics-16f70ecb95d8.herokuapp.com/api/users";
    const method = editingUserId ? "PUT" : "POST";
  
    // Map frontend field names to backend field names
    const payload = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      role: formData.role,
      ...(formData.password && { password: formData.password }), // Include password only if not empty
    };
  
    try {
      const response = await axios({
        method,
        url,
        data: payload,
      });
      fetchUsers();
      handleClose();
    } catch (error) {
      if (error.response) {
        console.error("Error saving user:", error.response.data);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error", error.message);
      }
    }
  };
  
  

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://chessat3analytics-16f70ecb95d8.herokuapp.com/api/users/${id}`);
      fetchUsers();
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const columns = [
    { field: "first_name", headerName: "First Name", flex: 1 },
    { field: "last_name", headerName: "Last Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "role", headerName: "Role", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: 200, // Set the column width
      renderCell: (params) => (
        <div style={{ display: 'flex', gap: '8px' }}> {/* Ensure buttons are spaced properly */}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleOpen(params.row)}
          >
            Edit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => handleDelete(params.row.id)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];
  

  return (
    <div className="mx-full p-6">
     
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>
        Add User
      </Button>

      <div style={{ height: 400, width: "100%", marginTop: 20 }}>
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          loading={loading}
          getRowId={(row) => row.id}
        />
      </div>

      {/* Dialog for Add/Edit User */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{editingUserId ? "Edit User" : "Add User"}</DialogTitle>
        <DialogContent>
          <form onSubmit={handleFormSubmit} className="space-y-4">
            <TextField
              label="First Name"
              value={formData.firstName}
              onChange={(e) =>
                setFormData({ ...formData, firstName: e.target.value })
              }
              fullWidth
              required
            />
            <TextField
              label="Last Name"
              value={formData.lastName}
              onChange={(e) =>
                setFormData({ ...formData, lastName: e.target.value })
              }
              fullWidth
              required
            />
            <TextField
              label="Email"
              type="email"
              value={formData.email}
              onChange={(e) =>
                setFormData({ ...formData, email: e.target.value })
              }
              fullWidth
              required
            />
            <TextField
              label="Role"
              select
              value={formData.role}
              onChange={(e) => setFormData({ ...formData, role: e.target.value })}
              fullWidth
              required
            >
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="admin">Admin</MenuItem>
            </TextField>
            {!editingUserId && (
  <TextField
    label="Password"
    type="password"
    value={formData.password}
    onChange={(e) =>
      setFormData({ ...formData, password: e.target.value })
    }
    fullWidth
    required={!editingUserId}
  />
)}

          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleFormSubmit} color="primary">
            {editingUserId ? "Update User" : "Add User"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
