import React, { useEffect, useState, useCallback } from "react"; // Move useCallback to the top
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import RetentionReportModal from "./RetentionReportModal"; // Ensure you import the component
import { Autocomplete, Dialog, DialogActions, DialogTitle, DialogContent, FormControlLabel, Checkbox } from "@mui/material";

import {
  DataGridPro,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { Modal, Paper, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import { darken, lighten, styled } from "@mui/material/styles";

// import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// Helper function to get background color
const getBackgroundColor = (color, theme, coefficient) => ({
  backgroundColor: darken(color, coefficient),
  ...theme.applyStyles("light", {
    backgroundColor: lighten(color, coefficient),
  }),
});

// Styled DataGrid with row color styling based on tutor retention rate
const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  "& .super-app-theme--Approved": {
    ...getBackgroundColor(theme.palette.success.main, theme, 0.6),
    "&:hover": {
      ...getBackgroundColor(theme.palette.success.main, theme, 0.5),
    },
  },
  "& .super-app-theme--Rejected, & .super-app-theme--Dormant": {
    ...getBackgroundColor(theme.palette.error.main, theme, 0.6),
    "&:hover": {
      ...getBackgroundColor(theme.palette.error.main, theme, 0.5),
    },
  },
}));

// Update row coloring logic
const getRowClassName = (params) => {
  const status = params.row.tutor_status?.toLowerCase(); // Normalize status
  if (status === "approved") return "super-app-theme--Approved"; // Green ✅
  if (status === "rejected" || status === "dormant") return "super-app-theme--Rejected"; // Red ❌
  return ""; // Default (No coloring for unknown status)
};

const ClientRetention = () => {
  const [loading, setLoading] = useState(false);
  const [reviewCount, setReviewCount] = useState(0); // Add state for review count
  const [startDate, setStartDate] = useState(
    dayjs().tz("America/New_York").subtract(1, "week").startOf("week")
  );
  const [endDate, setEndDate] = useState(
    dayjs().tz("America/New_York").subtract(1, "week").endOf("week")
  );
  const [filteredLessons, setFilteredLessons] = useState([]);
  const [lessonsData, setLessonsData] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [selectedTutorName, setSelectedTutorName] = useState(null);

  const [isLessonsModalOpen, setIsLessonsModalOpen] = useState(false);

  const LessonDetailsModal = ({ open, onClose, lessons, tutorName }) => {
    // Calculate the total sum of "units" (Duration in Hours)
    const totalHours = lessons.reduce((sum, lesson) => sum + parseFloat(lesson.units || 0), 0).toFixed(2);
    const totalLessons = lessons.length; // Count total number of lessons
  
    return (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
        <DialogTitle>Lesson Details for {tutorName}</DialogTitle>
  
        <DialogContent>
  {lessons.length > 0 ? (
    <StyledDataGrid
      rows={lessons.map((lesson) => ({
        ...lesson,
        id: lesson.lesson_id, // ✅ Ensure a unique ID for each row
      }))}
      columns={[
        { field: "lesson_id", headerName: "Lesson ID", width: 150 },
        { field: "duration_hours", headerName: "Duration (Hours)", width: 150 }, // ✅ Fix field name
        { field: "start", headerName: "Start Time", width: 200 },
        { field: "finish", headerName: "Finish Time", width: 200 },
      ]}
      getRowId={(row) => row.id} // ✅ Use lesson_id as unique identifier
      autoHeight
      pageSize={5}
      rowsPerPageOptions={[5, 10]}
      slots={{
        footer: () => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between", // Align total lessons left and total hours right
              padding: "10px",
              backgroundColor: "#f1f1f1",
              fontWeight: "bold",
            }}
          >
            <Typography>Total Lessons: {totalLessons}</Typography>

            <Typography>
              Total Hours:{" "}
              {lessons.reduce((sum, lesson) => sum + parseFloat(lesson.duration_hours || 0), 0).toFixed(2)}
            </Typography>
          </Box>
        ),
      }}
    />
  ) : (
    <Typography>No lessons found for this tutor in the selected date range.</Typography>
  )}
</DialogContent>

<DialogActions>
  <Button onClick={onClose} color="secondary">Close</Button>
</DialogActions>

      </Dialog>
    );
  };

  const [lessons, setLessons] = useState([]);
const [totalLessons, setTotalLessons] = useState(0);
  
  const [hoursData, setHoursData] = useState([]);
  const [selectedReportMonth, setSelectedReportMonth] = useState(
    dayjs().startOf("month").format("YYYY-MM")
  );
  const [tempExcludedTutors, setTempExcludedTutors] = useState([]); // Temporary selection state

  const [tutorData, setTutorData] = useState([]); // New state for tutor data
  const [activeTab, setActiveTab] = useState(0); // State for active tab
  const [reviews, setReviews] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetchTriggered, setFetchTriggered] = useState(false);
  const [excludedTutors, setExcludedTutors] = useState([]);
  const [openExclusionModal, setOpenExclusionModal] = useState(false);

  // Track individual fetch statuses
  const [churnDataFetched, setChurnDataFetched] = useState(false);
  const [tutorDataFetched, setTutorDataFetched] = useState(false);
  const [weeklyStatsFetched, setWeeklyStatsFetched] = useState(false);
  const [clientOverviewFetched, setClientOverviewFetched] = useState(false);

  
  // Derived state to check if all data is fetched
  const dataFetched =
    churnDataFetched &&
    tutorDataFetched &&
    weeklyStatsFetched &&
    clientOverviewFetched;

  const handleFetchData = async () => {
    setLoading(true);
    setFetchTriggered(true);

    // Reset fetch statuses for a fresh load
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);

    try {
      // 1) Fetch data in parallel
      await Promise.all([
        // fetchChurnData().then(() => setChurnDataFetched(true)),
        fetchTutorData().then(() => setTutorDataFetched(true)),
        // fetchWeeklyStats().then(() => setWeeklyStatsFetched(true)),
        // fetchClientOverviewData().then(() => setClientOverviewFetched(true)),
      ]);
    } catch (err) {
      console.error("Error fetching data or in post-processing:", err);
    } finally {
      // 3) Only now release the `loading` lock
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLessons();
  }, []);
  
  const fetchLessons = async (tutorId) => {
    console.log("fetchLessons called with tutorId:", tutorId);
    
    if (!tutorId) {
      console.error("❌ tutorId is undefined, cannot fetch lessons.");
      return;
    }
  
    try {
      const payload = JSON.stringify({ startDate, endDate, tutorId });
      console.log("Payload for /tutor-lessons:", payload);
      
      const response = await fetch("/tutor-lessons", {
        method: "POST",
        body: payload,
        headers: { "Content-Type": "application/json" },
      });
  
      // Check response status
      console.log("Response status:", response.status);
  
      const data = await response.json();
      console.log("Lessons data received:", data);
      setLessons(data.lessons || []);
      setTotalLessons(data.totalLessons || 0);
    } catch (error) {
      console.error("❌ Error fetching lessons:", error);
    }
  };
  
  
  

  useEffect(() => {
    const fetchExcludedTutors = async () => {
        try {
            const response = await axios.get("/api/excluded-tutors");
            setExcludedTutors(response.data);
        } catch (error) {
            console.error("Error fetching excluded tutors:", error);
        }
    };
    fetchExcludedTutors();
}, []);


const handleExclusionChange = async (tutorId) => {
  try {
      if (excludedTutors.includes(tutorId)) {
          await axios.delete(`/api/exclude-tutor/${tutorId}`);
          setExcludedTutors(prev => prev.filter(id => id !== tutorId));
      } else {
          await axios.post("/api/exclude-tutor", { tutorId });
          setExcludedTutors(prev => [...prev, tutorId]);
      }
  } catch (error) {
      console.error("Error updating exclusion list:", error);
  }
};


const renderExclusionModal = () => (
  <Dialog open={openExclusionModal} onClose={() => setOpenExclusionModal(false)}>
    <DialogTitle>Exclude Tutors from Group Bonus</DialogTitle>
    <DialogContent>
      {/* Tutor Selection Autocomplete with remove on X click */}
      <Autocomplete
        multiple
        options={tutorData}
        getOptionLabel={(option) => option.tutor_name || "Unknown Tutor"}
        value={tutorData.filter((tutor) => excludedTutors.includes(tutor.tutor_id))}
        onChange={async (event, newValue) => {
          // Extract tutor IDs from selected tutors
          const updatedExcludedTutors = newValue.map((tutor) => tutor.tutor_id);

          // Update state immediately
          setExcludedTutors(updatedExcludedTutors);
          
          // 🔥 API call to update backend in real-time
          try {
            await axios.post("/api/update-excluded-tutors", { tutorIds: updatedExcludedTutors });
          } catch (error) {
            console.error("Error updating exclusion list:", error);
          }
        }}
        renderInput={(params) => <TextField {...params} label="Excluded Tutors" />}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setOpenExclusionModal(false)} color="secondary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);



  // Reset dataFetched state when date range changes
  const handleStartDateChange = (newValue) => {
    setStartDate(dayjs(newValue).tz("America/New_York").startOf("day"));
    // Reset the triggered fetch flag & fetch booleans
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(dayjs(newValue).tz("America/New_York").endOf("day"));
    // Reset the triggered fetch flag & fetch booleans
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  const [retentionRates, setRetentionRates] = useState({
    monthly: 0,
    annual: 0,
    avgMonthly: 0,
    avgAnnual: 0,
    avgMonthlyAllTutors: 0,  // <- add this
  });
  

  const [expandedRows, setExpandedRows] = useState({});
  const [selectedTutor, setSelectedTutor] = useState(null); // Selected tutor for the report
  const [selectedMonth, setSelectedMonth] = useState(
    dayjs().startOf("month").format("YYYY-MM")
  ); // Default month
  const [isReportModalOpen, setIsReportModalOpen] = useState(false); // Modal visibility
  const [retentionReport, setRetentionReport] = useState(null); // Retention report data

  const calculateConsistencyBonus = (totalHours) => {
    if (totalHours >= 80) return 600;
    if (totalHours >= 60) return 400;
    if (totalHours >= 40) return 200;
    return 0;
  };

  const calculateGroupBonus = (students) => {
    if (students >= 5) return 40;
    if (students >= 4) return 30;
    if (students >= 3) return 20;
    if (students >= 2) return 10;
    return 0;
  };

  const openRetentionReportModal = async (tutor) => {
    setLoading(true);
    setSelectedTutor(tutor);
    setIsReportModalOpen(true);

    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const currentStartDate = startDate;
      const currentEndDate = endDate;

      console.log("🟢 Opening Retention Report Modal");
      console.log("🕒 Report Date Ranges:", {
        currentStartDate: currentStartDate.format("YYYY-MM-DD"),
        currentEndDate: currentEndDate.format("YYYY-MM-DD"),
      });

      console.log("📡 Fetching tutor overview for:", tutor.tutor_id);

      const tutorOverviewResponse = await axios.post(
        "/tutor-overview",
        {
          startDate: currentStartDate.format("YYYY-MM-DD"),
          endDate: currentEndDate.format("YYYY-MM-DD"),
          tutorId: tutor.tutor_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      

      console.log("✅ Tutor Overview Response:", tutorOverviewResponse.data);

      console.log("📡 Fetching group sessions for:", tutor.tutor_id);

      const groupSessionResponse = await axios.post(
        "/fetch-group-sessions",
        {
          tutorId: tutor.tutor_id,
          startDate: currentStartDate.format("YYYY-MM-DD"),
          endDate: currentEndDate.format("YYYY-MM-DD"),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("✅ Raw Group Session Response:", groupSessionResponse.data);

      // ✅ Grab ALL the sessions:
      const groupSessionData = groupSessionResponse.data.sessions || [];
      console.log("🟢 Group Session Data:", groupSessionData); // Debugging
      
      const reviewsResponse = await axios.get(
        `/api/reviews?tutor_id=${tutor.tutor_id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      console.log("✅ Reviews Response:", reviewsResponse.data);

      const allReviews = reviewsResponse.data.reviews || [];
      if (!Array.isArray(allReviews)) {
        console.error("❌ Reviews data is not an array:", allReviews);
        throw new Error("Unexpected reviews data format");
      }

      const filteredReviews = allReviews.filter((review) => {
        const reviewDate = dayjs(review.date_created);
        return reviewDate.isBetween(
          currentStartDate,
          currentEndDate,
          null,
          "[]"
        );
      });

      console.log("📝 Filtered Reviews:", filteredReviews);

      console.log("📡 Processing leaderboard...");

      const leaderboard = tutorData
        .filter((entry) => entry.tutor_status === "approved")
        .map((entry) => ({
          tutorName: entry.tutor_name || "N/A",
          totalHours: parseFloat(entry.tutor_total_hours_period || 0).toFixed(
            2
          ),
        }))
        .sort((a, b) => b.totalHours - a.totalHours)
        .slice(0, 5);

      console.log("🏆 Generated Leaderboard:", leaderboard);

      console.log("📡 Processing lost clients...");
      const lostClients = tutorOverviewResponse.data.lost_clients_details || [];

      console.log("🛑 Lost Clients Data:", lostClients);

      console.log("📊 Calculating Consistency Bonus...");
      const consistencyBonus = calculateConsistencyBonus(
        tutor.tutor_total_hours_period
      );
      
      console.log("🟣 Consistency Bonus:", consistencyBonus);

      console.log("📊 Calculating Group Bonus...");
      const groupBonus = calculateGroupBonus(
        Number(groupSessionData.counted_students) || 0
      );

      console.log("🟡 Group Bonus:", groupBonus);

      console.log("📊 Calculating Additional Students...");
      const additionalStudents = Number(groupSessionData.counted_students) || 0;

      console.log("🟠 Additional Students:", additionalStudents);
      console.log("🟢 Group Session Data:", groupSessionData);
      console.log("🔵 Group Sessions Array:", groupSessionData?.sessions);
      console.log("🔍 Total Hours Debug: ", tutor.tutor_total_hours_period);

      setRetentionReport({
        tutorName: tutor?.tutor_name || "Unknown Tutor",
        month: currentStartDate.format("MMMM YYYY"),
        leaderboard,
        lessons: {
          total: tutor.tutor_total_hours_period || 0,  // ✅ Make sure this value is not 0
          consistencyBonus,
          additionalStudents,
          groupBonus,
        },
         
      
        // ⬇️ pass the array directly
        groupSessionData: groupSessionData,

        reviews: filteredReviews.map((review) => ({
          reviewerName: review.client_name || "Anonymous",
          text: review.extra_attrs_value || "No review text available",
          rating: review.star_rating_value || 0,
        })),
        retentionRates: {
          monthly: tutor.period_retention_rate || 0,
          annual: tutor.all_time_retention_rate || 0,
          avgMonthlyAllTutors: retentionRates.avgMonthlyAllTutors || 0,
        },
        lost_clients_details: lostClients.map((client) => ({
          client_name: client.client_name || "N/A",
          last_lesson_date: client.last_lesson_date || "N/A",
          total_lesson_count: client.total_lesson_count || 0,
        })),
      });

      console.log("✅ Retention Report Successfully Set");
    } catch (error) {
      console.error(
        "❌ Error fetching retention report:",
        error.response || error.message
      );
      alert("Failed to fetch the retention report. Please try again.");
    } finally {
      setLoading(false);
      console.log("🔄 Loading finished.");
    }
  };

  const closeRetentionReportModal = () => {
    setIsReportModalOpen(false);
    setRetentionReport(null);
  };

  const toggleRowExpansion = (rowLabel) => {
    setExpandedRows((prevExpandedRows) => ({
      ...prevExpandedRows,
      [rowLabel]: !prevExpandedRows[rowLabel],
    }));
  };

  const [weeklyStats, setWeeklyStats] = useState({
    totalLessons: 0,
    totalOtherDivisionLessons: 0,
    periodBeforeLessons: 0,
    fourPeriodAvgLessons: 0,
    totalStudents: 0,
    totalHours: 0,
    avgStudentsPerLesson: 0.0,
    avgHoursPerLesson: 0.0,
    detailedLessons: [], // Initialize as an empty array
  });

  useEffect(() => {
    if (Array.isArray(weeklyStats.detailedLessons) || Array.isArray(lessonsData)) {
      const combinedLessons = [
        ...(Array.isArray(weeklyStats.detailedLessons) ? weeklyStats.detailedLessons : []),
        ...(Array.isArray(lessonsData) ? lessonsData : []),
      ];
  
      const updatedFilteredLessons = combinedLessons.filter((lesson) => {
        const lessonDate = dayjs(lesson.start || lesson.lessonStart).tz("America/New_York"); 
        return lessonDate.isBetween(startDate, endDate, null, "[]"); // Ensure it's within range
      });
  
      setFilteredLessons(updatedFilteredLessons); // Update filtered lessons
    }
  }, [weeklyStats.detailedLessons, lessonsData, startDate, endDate]); // Trigger filtering when any of these change
  

  const [clientCounts, setClientCounts] = useState({
    total: 0,
    active: 0,
    inactive: 0,
    ancient: 0,
    archived: 0,
    dead: 0,
    activeClients30Days: 0,
    activeClientsPreviousPeriod: 0, // Add this line
  });

  useEffect(() => {
    const updatedFilteredLessons = weeklyStats.detailedLessons.filter(
      (lesson) => {
        const lessonStart = dayjs(lesson.lessonStart).tz("America/New_York");
        return lessonStart.isBetween(startDate, endDate, null, "[]");
      }
    );
    setFilteredLessons(updatedFilteredLessons);
  }, [weeklyStats.detailedLessons, startDate, endDate]);

  const ReviewModal = ({ open, onClose, reviews, tutorName }) => (
    <Modal open={open} onClose={onClose} aria-labelledby="reviews-modal">
      <Paper
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxHeight: "80vh",
          overflowY: "auto",
          padding: 4,
          boxShadow: 24,
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6">Reviews for {tutorName}</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {Array.isArray(reviews) && reviews.length > 0 ? (
          reviews.map((review) => {
            const dateCreated = review.date_created
              ? dayjs(review.date_created)
                  .tz("America/New_York")
                  .format("MMMM D, YYYY h:mm A")
              : "Unknown Date";

            return (
              <Box key={review.review_id} sx={{ mb: 2 }}>
                <Typography variant="body1">
                  <strong>Client:</strong> {review.client_name || "Unknown"}
                </Typography>
                <Typography variant="body1">
                  <strong>Date:</strong> {dateCreated}
                </Typography>
                <Typography variant="body1">
                  <strong>Rating:</strong> {review.star_rating_value || "N/A"}{" "}
                  ⭐
                </Typography>
                <Typography variant="body2">
                  {review.extra_attrs_value || "No review text available."}
                </Typography>
              </Box>
            );
          })
        ) : (
          <Typography>No reviews available for the selected period.</Typography>
        )}
      </Paper>
    </Modal>
  );

  const fetchLabelsForAllTutors = async () => {
    try {
      const token = localStorage.getItem("token");
      const contractorIds = tutorData.map((tutor) => tutor.tutor_id);
      const response = await axios.post(
        "/api/tutor-labels/bulk",
        { contractorIds },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const { results } = response.data;

      // Debugging
      console.log("Fetched Labels:", results);

      // Map fetched labels to tutorData
      const updatedTutorData = tutorData.map((tutor) => {
        const fetchedTutor = results.find(
          (result) => result.contractorId === tutor.tutor_id
        );
        return fetchedTutor
          ? {
              ...tutor,
              labels: fetchedTutor.labels
                ? fetchedTutor.labels.split(",").map((label) => label.trim())
                : [],
            }
          : tutor;
      });

      // Debugging
      console.log("Updated Tutor Data with Labels:", updatedTutorData);

      setTutorData(updatedTutorData);
    } catch (error) {
      console.error("Error fetching labels:", error.message);
    }
  };

  const fetchClientReviews = async (tutorId, tutorName, startDate, endDate) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const response = await axios.get(`/api/reviews`, {
        params: {
          tutor_id: tutorId,
          start_date: startDate.format("YYYY-MM-DD"),
          end_date: endDate.format("YYYY-MM-DD"),
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      const { reviews, count } = response.data;

      // Update states for reviews and tutor name
      setReviews(reviews || []);
      setReviewCount(count || 0);
      setSelectedTutorName(tutorName); // Set tutor's name here
      setIsModalOpen(true); // Open the modal
    } catch (error) {
      console.error("Error fetching reviews:", error);
      setReviews([]); // Clear reviews on error
    } finally {
      setLoading(false);
    }
  };

  const fetchAllReviews = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/fetch-reviews");
      console.log("Fetch reviews response:", response.data);

      alert("All reviews fetched and stored successfully!");
    } catch (error) {
      console.error(
        "Error fetching all reviews:",
        error.response || error.message
      );
      alert("Failed to fetch reviews. Please check the logs for details.");
    } finally {
      setLoading(false);
    }
  };

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  // This state holds the detailed churn client data from the backend
  const [detailedClientData, setDetailedClientData] = useState({
    newClientsList: [],
    clientsLostList: [],
  });

  // Shortcuts for date ranges
  const setThisWeek = () => {
    setStartDate(dayjs().tz("America/New_York").startOf("week"));
    setEndDate(dayjs().tz("America/New_York").endOf("week"));
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  const setLastWeek = () => {
    setStartDate(
      dayjs().subtract(1, "week").tz("America/New_York").startOf("week")
    );
    setEndDate(
      dayjs().subtract(1, "week").tz("America/New_York").endOf("week")
    );
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  const setThisMonth = () => {
    setStartDate(dayjs().tz("America/New_York").startOf("month"));
    setEndDate(dayjs().tz("America/New_York").endOf("month"));
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  const setLastMonth = () => {
    setStartDate(
      dayjs().subtract(1, "month").tz("America/New_York").startOf("month")
    );
    setEndDate(
      dayjs().subtract(1, "month").tz("America/New_York").endOf("month")
    );
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  const setThisYear = () => {
    setStartDate(dayjs().startOf("year"));
    setEndDate(dayjs());
  };

  const setLastYear = () => {
    setStartDate(dayjs().subtract(1, "year").startOf("year"));
    setEndDate(dayjs().subtract(1, "year").endOf("year"));
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  // Quarter-based shortcuts
  const setQ1 = () => {
    setStartDate(dayjs().startOf("year")); // January 1st
    setEndDate(dayjs().startOf("year").add(2, "month").endOf("month")); // March 31st
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  const setQ2 = () => {
    setStartDate(dayjs().startOf("year").add(3, "month")); // April 1st
    setEndDate(dayjs().startOf("year").add(5, "month").endOf("month")); // June 30th
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  const setQ3 = () => {
    setStartDate(dayjs().startOf("year").add(6, "month")); // July 1st
    setEndDate(dayjs().startOf("year").add(8, "month").endOf("month")); // September 30th
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  const setQ4 = () => {
    setStartDate(dayjs().startOf("year").add(9, "month")); // October 1st
    setEndDate(dayjs().startOf("year").add(11, "month").endOf("month")); // December 31st
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
  };

  // Set start and end of the week
  const handleWeekSelection = (date) => {
    const startOfWeek = dayjs(date).startOf("week"); // Sunday
    const endOfWeek = dayjs(date).endOf("week"); // Saturday
    // Reset
    setFetchTriggered(false);
    setChurnDataFetched(false);
    setTutorDataFetched(false);
    setWeeklyStatsFetched(false);
    setClientOverviewFetched(false);
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarExport csvOptions={{ fileName: "LessonDetails" }} />
      </GridToolbarContainer>
    );
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchTutorLessonsForTutor = async (tutorId, tutorName) => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");
  
      // Convert the dates to ISO strings with New York boundaries
      const startDateFormatted = dayjs(startDate)
        .tz("America/New_York")
        .startOf("day")
        .toISOString();
      const endDateFormatted = dayjs(endDate)
        .tz("America/New_York")
        .endOf("day")
        .toISOString();
  
      const response = await axios.post(
        "/tutor-lessons",
        {
          startDate: startDateFormatted,
          endDate: endDateFormatted,
          tutorId, // Send selected tutor ID
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      console.log(`✅ Unique Lessons for ${tutorName}:`, response.data);
      setLessonsData(response.data.lessons || []);
      setSelectedTutorName(tutorName);
      setIsLessonsModalOpen(true); // Open the modal
    } catch (error) {
      console.error("❌ Error fetching unique lesson data:", error);
      alert("Failed to fetch unique lesson data.");
    } finally {
      setLoading(false);
    }
  };
  
  
  

  const fetchTutorLabels = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/tutor-labels");
      const { labels } = response.data;

      // Map labels to tutorData
      const updatedTutorData = tutorData.map((tutor) => {
        const labelEntry = labels.find(
          (entry) => entry.contractor_id === tutor.tutor_id
        );
        return {
          ...tutor,
          labels: labelEntry
            ? labelEntry.labels.split(",").map((label) => ({
                label_name: label.trim(),
              }))
            : [],
        };
      });

      setTutorData(updatedTutorData);
    } catch (error) {
      console.error("Error fetching tutor labels:", error.message);
      alert("Failed to fetch tutor labels.");
    } finally {
      setLoading(false);
    }
  };

  const fetchTutorData = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");
  
      // Fetch the tutor overview data
      const tutorOverviewResponse = await axios.post(
        "/tutor-overview",
        {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
          previousStartDate: startDate.subtract(1, "month").format("YYYY-MM-DD"),
          previousEndDate: endDate.subtract(1, "month").format("YYYY-MM-DD"),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      // 📡 Log API Response
      console.log("📡 Received Tutor Overview Response:", tutorOverviewResponse.data);
  
      const { tutorOverview, allTutorsAvgMonthlyRetention } = tutorOverviewResponse.data;
  
      if (tutorOverview) {
        console.log("📊 Tutor Overview - Checking Lessons Count Per Tutor:");
        tutorOverview.forEach((tutor) => {
          console.log(`🔹 Tutor: ${tutor.tutor_name} (ID: ${tutor.tutor_id}) - Lessons: ${tutor.total_complete_appointments_period}`);
        });
  
        // Fetch review counts for each tutor in parallel
        const tutorDataWithReviews = await Promise.all(
          tutorOverview.map(async (tutor) => {
            try {
              const reviewsResponse = await axios.get(`/api/reviews`, {
                params: {
                  tutor_id: tutor.tutor_id,
                  start_date: startDate.format("YYYY-MM-DD"),
                  end_date: endDate.format("YYYY-MM-DD"),
                },
                headers: { Authorization: `Bearer ${token}` },
              });
  
              return {
                ...tutor,
                review_count: reviewsResponse.data.count || 0,
              };
            } catch (error) {
              console.error(`❌ Error fetching reviews for Tutor ${tutor.tutor_id}:`, error);
              return { ...tutor, review_count: 0 };
            }
          })
        );
  
        // ✅ Log Processed Tutor Data Before Setting State
        console.log("✅ Processed Tutor Data (Before Setting State):", tutorDataWithReviews);
  
        setTutorData(tutorDataWithReviews);
  
        // Store the aggregated average in local state
        setRetentionRates((prev) => ({
          ...prev,
          avgMonthlyAllTutors: parseFloat(allTutorsAvgMonthlyRetention) || 0,
        }));
      } else {
        console.error("❌ No tutorOverview returned from API.");
      }
    } catch (error) {
      console.error("❌ Error fetching tutor overview data:", error);
    } finally {
      setLoading(false);
    }
  }, [startDate, endDate]);
  

  const fetchTutorStatus = async (contractorId, rowIndex) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const response = await axios.get(
        `https://secure.tutorcruncher.com/api/contractors/${contractorId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const status = response.data.status;

      // Update the tutorData state with the new status
      setTutorData((prevData) =>
        prevData.map((row, index) =>
          index === rowIndex ? { ...row, tutor_status: status } : row
        )
      );

      return status;
    } catch (error) {
      console.error(
        `Error fetching tutor status for ID ${contractorId}:`,
        error
      );
      return "Error";
    }
  };

  const tutorColumns = [
    {
      field: "tutor_name",
      headerName: "Tutor",
      width: 200,
      pinned: "left", // This makes the column sticky on the left
    },
    {
      field: "tutor_status",
      headerName: "Status",
      width: 200,
    },

    {
      field: "labels",
      headerName: "Labels",
      width: 300,
    },

    {
      field: "tutor_id",
      headerName: "Tutor ID",
      width: 200,
      renderCell: (params) => (
        <a
          href={`https://secure.tutorcruncher.com/contractors/${params.value}/`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#1976d2", textDecoration: "none" }}
        >
          {params.value}
        </a>
      ),
    },

    {
      field: "monthlyRetentionReport",
      headerName: "Monthly Report",
      width: 200,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => openRetentionReportModal(params.row)}
        >
          View Report
        </Button>
      ),
    },

    {
      field: "review_count",
      headerName: "Review Count",
      width: 150,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            {params.row.review_count || 0}
          </Box>
        );
      },
    },

    {
      field: "reviews",
      headerName: "Reviews",
      width: 150,
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() =>
              fetchClientReviews(
                params.row.tutor_id,
                params.row.tutor_name,
                startDate,
                endDate
              )
            }
          >
            View Reviews
          </Button>
        </Box>
      ),
    },

    // Current Period Metrics
    {
      field: "total_complete_appointments_period",
      headerName: "Total Lessons (Unique / Period)",
      width: 250,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "tutor_total_hours_period",
      headerName: "Total Hours (Unique / Period)",
      width: 250,
      type: "number",
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",  // Centering horizontally
            alignItems: "center",  // Centering vertically
            width: "100%",  // Ensuring full width
            height: "100%",  // Ensuring full height
            gap: 1,  // Spacing between elements
          }}
        >
         {params.value}
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ minWidth: "120px" }}  // Ensuring button size consistency
            onClick={() => fetchTutorLessonsForTutor(params.row.tutor_id, params.row.tutor_name)}
          >
            View Lessons
          </Button>
        </Box>
      ),
    },
    
    {
      field: "total_cancelled_appointments_period",
      headerName: "Cancelled (Period)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "total_chargeable_cancelled_appointments_period",
      headerName: "CBC (Period)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },

    // Clients worked with and Retention Rates
    {
      field: "clients_worked_with",
      headerName: "Clients Worked With (All Time)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "clients_active_30_days",
      headerName: "Clients Active (Last 30 Days)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "all_time_retention_rate",
      headerName: "All-Time Retention Rate (%)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "period_retention_rate",
      headerName: "Period Retention Rate (%)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },

    // New, Retained, and Lost Clients during the current period
    {
      field: "new_clients_period",
      headerName: "New Clients (Period)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "retained_clients_period",
      headerName: "Retained Clients (Period)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "lost_clients_period",
      headerName: "Lost Clients (Period)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    // All-Time Metrics
    {
      field: "total_complete_appointments_all_time",
      headerName: "Total Lessons (All Time)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "tutor_total_hours_all_time",
      headerName: "Total Hours (All Time)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "total_cancelled_appointments_all_time",
      headerName: "Cancelled (All Time)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
    {
      field: "total_chargeable_cancelled_appointments_all_time",
      headerName: "CBC (All Time)",
      width: 200,
      type: "number", // Numerical column
      filterOperators: DataGridPro.numericOperators, // Use default numeric filter operators
    },
  ];

  const updateAllTutorStatuses = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "/update-all-tutors-status",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.updates) {
        const updatedTutorData = tutorData.map((tutor) => {
          const update = response.data.updates.find(
            (u) => u.contractorId === tutor.tutor_id
          );
          if (update) {
            return { ...tutor, tutor_status: update.status };
          }
          return tutor;
        });

        setTutorData(updatedTutorData);
      }
      console.log("All tutor statuses updated successfully.");
    } catch (error) {
      console.error("Error updating all tutor statuses:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (fetchTriggered) {
      console.log("Fetching data for updated date range.");
      // fetchChurnData();
      fetchTutorData();
      // fetchWeeklyStats();
      // fetchClientOverviewData();
    }
  }, [fetchTriggered]);

  useEffect(() => {
    console.log("Updated weeklyStats state:", weeklyStats);
  }, [weeklyStats]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: "100%" }}>
        {/* Date Range Pickers */}
        <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={handleStartDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={handleEndDateChange}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        <Box sx={{ mb: 2 }}>
          {dataFetched ? (
            <Typography variant="subtitle2" color="textSecondary">
              Data has been fetched for the selected range.
            </Typography>
          ) : (
            <Typography variant="subtitle2" color="error">
              Data is not fetched yet. Click 'Fetch Data' to load *once* and
              then wait for this red text to dissapear.
            </Typography>
          )}
        </Box>

        <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFetchData}
            disabled={loading} // <-- disable when loading === true
          >
            {loading ? <CircularProgress size={24} /> : "Fetch Data"}
          </Button>
        </Box>

        {/* Date Range Shortcuts */}
        <Box sx={{ mb: 2 }}>
          <Button variant="outlined" onClick={setThisWeek} sx={{ mr: 1 }}>
            This Week
          </Button>
          <Button variant="outlined" onClick={setLastWeek} sx={{ mr: 1 }}>
            Last Week
          </Button>
          <Button variant="outlined" onClick={setThisMonth} sx={{ mr: 1 }}>
            This Month
          </Button>
          <Button variant="outlined" onClick={setLastMonth} sx={{ mr: 1 }}>
            Last Month
          </Button>
          <Button variant="outlined" onClick={setThisYear} sx={{ mr: 1 }}>
            This Year
          </Button>
          <Button variant="outlined" onClick={setLastYear} sx={{ mr: 1 }}>
            Last Year
          </Button>

          {/* Quarter Shortcuts */}
          <Button variant="outlined" onClick={setQ1} sx={{ mr: 1 }}>
            Q1
          </Button>
          <Button variant="outlined" onClick={setQ2} sx={{ mr: 1 }}>
            Q2
          </Button>
          <Button variant="outlined" onClick={setQ3} sx={{ mr: 1 }}>
            Q3
          </Button>
          <Button variant="outlined" onClick={setQ4}>
            Q4
          </Button>
        </Box>

        <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={updateAllTutorStatuses}
            disabled={loading}
          >
            {loading ? "Updating..." : "Update All Tutors Statuses"}
          </Button>

          <Button
            variant="outlined"
            size="medium" // Adjust size for consistency
            onClick={fetchAllReviews} // Function to trigger the fetch
          >
            Fetch Reviews
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={fetchLabelsForAllTutors}
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : "Fetch Labels"}
          </Button>

          <Button variant="outlined" onClick={() => setOpenExclusionModal(true)}>
  Exclude Tutors from Group Bonus
</Button>
{renderExclusionModal()}  {/* This will render the modal */}


        </Box>

        <Box>
          {/* Tutor Overview Data Grid */}
          <StyledDataGrid
            pagination
            rows={tutorData.filter(
              (row) => row.tutor_id !== null && row.tutor_id !== undefined
            )} // Exclude rows with null or undefined tutor_id
            columns={tutorColumns} // Use updated tutorColumns here
            getRowId={(row) => row.tutor_id} // Ensure tutor_id is used as a unique identifier
            pageSize={paginationModel.pageSize}
            loading={loading}
            rowsPerPageOptions={[5, 10, 25]}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => setPaginationModel(newModel)}
            autoHeight={false}
            disableRowSelectionOnClick
            {...tutorColumns}
            slots={{
              toolbar: GridToolbar,
            }}
            sx={{
              width: "100%",
              maxWidth: "85vw", // Restrict to the full viewport width
            }}
            initialState={{ pinnedColumns: { left: ["tutor_name"] } }}
            getRowClassName={getRowClassName} // 🔥 Apply new coloring logic
          />
          <ReviewModal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            reviews={reviews}
            tutorName={selectedTutorName} // Pass the tutor's name here
          />

<LessonDetailsModal
  open={isLessonsModalOpen}
  onClose={() => setIsLessonsModalOpen(false)}
  lessons={lessonsData}
  tutorName={selectedTutorName}
/>



          {/* Retention Report Modal */}
          {isReportModalOpen && (
            <RetentionReportModal
              open={isReportModalOpen}
              onClose={closeRetentionReportModal}
              reportData={retentionReport}
              tutorId={selectedTutor?.tutor_id} // Pass the tutor ID to the modal
              tutorData={tutorData}
              loading={loading} // Pass the `loading` state
              excludedTutors={excludedTutors}  // Pass Excluded Tutors List

            />
          )}
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default ClientRetention;
