import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { LicenseInfo } from '@mui/x-license';
import { Bars3Icon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChartPieIcon, UsersIcon, CogIcon, CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { ChartBarIcon, ChartBarSquareIcon, PaperClipIcon } from '@heroicons/react/20/solid';
import { CalendarIcon } from '@mui/x-date-pickers-pro';

import MainDashboard from './components/MainDashboard';
import CurrentWeek from './components/CurrentWeek';
import MonthToDate from './components/MonthToDate';
import YearToDate from './components/YearToDate';
import LastYearStats from './components/LastYearStats';
import ClientBehavior from './components/ClientBehavior';
import TutorBehavior from './components/TutorBehavior';
import LifetimeStats from './components/LifetimeStats';
import BigStats from './components/BigStats';
import AdsEmailAnalytics from './components/AdsEmailAnalytics';
import TutorCruncherData from './components/TutorCruncherData';
import GravityData from './components/GravityData';
import RevenueByDivision from './components/RevenueByDivision';
import Login from './components/Login';
import Users from './components/UsersPage';
import GravityBookingsStats from './components/GravityBookingsStats';
import TutorRetention from './components/TutorRetention';
import ClientsOverview from './components/ClientsOverview';
import MasterReport from './components/MasterReport';
import Settings from './components/Settings';
import RetentionMetrics from './components/RetentionMetrics';

LicenseInfo.setLicenseKey('397110379bbdb9d8dcd4b7e2a9e5533dTz05OTQ3NSxFPTE3NTk5MDg0MDQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg==');

const navigation = [
  { name: 'Master Report', component: MasterReport, path: '/master-report', title: 'Master Report', icon: ChartBarIcon },
  { name: 'Revenue By Division', component: RevenueByDivision, path: '/revenue-by-division', title: 'Revenue By Division', icon: CurrencyDollarIcon },
  { name: 'Client Retention', component: RetentionMetrics, path: '/retention-metrics', title: 'Client Retention', icon: ChartBarSquareIcon },
  { name: 'Tutor Retention', component: TutorRetention, path: '/tutor-retention', title: 'Tutor Retention', icon: ChartPieIcon },
  { name: 'Clients Overview', component: ClientsOverview, path: '/clients-overview', title: 'Clients Overview', icon: UsersIcon },
  { name: 'Marketing Data', component: GravityBookingsStats, path: '/gravity-booking-stats', title: 'Marketing Data', icon: CalendarIcon },
  { name: 'TutorCruncher Data', component: TutorCruncherData, path: '/tutorcruncher-data', title: 'TutorCruncher Data', icon: ChartBarIcon },
  { name: 'Gravity Data', component: GravityData, path: '/gravity-data', title: 'Gravity Data', icon: PaperClipIcon },
  { name: 'Users', component: Users, path: '/users-page', title: 'Users', icon: UsersIcon },
  { name: 'Settings', component: Settings, path: '/settings', title: 'Settings', icon: CogIcon },
];

const userNavigation = [
  { name: 'Sign out', href: '#' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Header({ title }) {
  return (
    <div className="bg-white mb-4">
      <div className="max-w-12xl mx-auto">
        <h1 className="text-3xl font-bold leading-tight text-gray-900">{title}</h1>
      </div>
    </div>
  );
}

/*
  AuthenticatedLayout now receives user and handleSignOut as props.
  It displays the user menu and a "Return to Main" button in the top navbar.
*/
function AuthenticatedLayout({ children, sidebarExpanded, setSidebarExpanded, isAuthenticated, user, handleSignOut }) {
  const location = useLocation();
  const isMainDashboard = location.pathname === '/main';

  return (
    <div className="min-h-full flex">
      {isAuthenticated && !isMainDashboard && (
        <div
          className={`${sidebarExpanded ? 'w-64' : 'w-20'} transition-width duration-300 ease-in-out lg:flex lg:flex-col lg:fixed lg:inset-y-0`}
          style={{ zIndex: 999 }}
        >
          <div className="flex flex-col flex-grow border-r border-gray-200 bg-white overflow-y-auto">
            <div className="flex items-center h-16 px-4 bg-white shadow">
              <img className="h-8 w-auto" src="/logo512.png" alt="App Logo" />
              <button
                onClick={() => setSidebarExpanded(!sidebarExpanded)}
                className="ml-auto focus:outline-none"
              >
                {sidebarExpanded ? (
                  <ChevronDoubleLeftIcon className="h-6 w-6 text-gray-500" />
                ) : (
                  <ChevronDoubleRightIcon className="h-6 w-6 text-gray-500" />
                )}
              </button>
            </div>
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 space-y-1 bg-white">
                {navigation.map((tab) => (
                  <Link
                    key={tab.name}
                    to={tab.path}
                    className={classNames(
                      window.location.pathname === tab.path
                        ? 'bg-gray-100 text-gray-900'
                        : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md w-full text-left'
                    )}
                  >
                    <tab.icon className="h-6 w-6 text-gray-500" />
                    {sidebarExpanded && <span className="ml-3">{tab.name}</span>}
                  </Link>
                ))}
              </nav>
            </div>
          </div>
        </div>
      )}

      <div className={`flex-1 ${isAuthenticated && !isMainDashboard ? (sidebarExpanded ? 'lg:pl-64' : 'lg:pl-20') : ''}`}>
        <div className="sticky top-0 z-10 flex h-16 bg-white shadow">
          <button
            type="button"
            onClick={() => setSidebarExpanded(!sidebarExpanded)}
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none lg:hidden"
          >
            <Bars3Icon className="h-6 w-6" />
          </button>
          <div className="flex-1 px-4 flex justify-end items-center">
            {isAuthenticated && (
              <>
                {/* Return to Main button */}
                <Link
                  to="/main"
                  className="mr-4 px-3 py-1 border border-gray-300 rounded text-gray-700 hover:bg-gray-50"
                >
                  Return to Main
                </Link>
                <div className="ml-4 flex items-center">
                  {/* User Menu */}
                  <Menu as="div" className="ml-3 relative">
                    <MenuButton className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={user?.imageUrl || '/logo512.png'}
                        alt="User profile"
                      />
                    </MenuButton>
                    <MenuItems className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                      {userNavigation.map((item) => (
                        <MenuItem key={item.name}>
                          {item.name === 'Sign out' ? (
                            <button
                              onClick={handleSignOut}
                              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              {item.name}
                            </button>
                          ) : (
                            <a
                              href={item.href}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            >
                              {item.name}
                            </a>
                          )}
                        </MenuItem>
                      ))}
                    </MenuItems>
                  </Menu>
                </div>
              </>
            )}
          </div>
        </div>
        <main className="flex-1 p-6">{children}</main>
      </div>
    </div>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthChecked, setIsAuthChecked] = useState(false); // Loading state for auth check
  const [user, setUser] = useState(null);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userData = localStorage.getItem('user');
    if (token && userData && userData !== "undefined") {
      try {
        const parsedUserData = JSON.parse(userData);
        if (parsedUserData) {
          setUser(parsedUserData);
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error("Error parsing user data:", error);
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
      setUser(null);
    }
    setIsAuthChecked(true);
  }, []);

  // Define the authentication functions
  const handleLogin = (token, userData) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(userData));
    setIsAuthenticated(true);
    setUser(userData);
  };

  const handleSignOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUser(null);
  };

  // Show a loading indicator until auth check is complete
  if (!isAuthChecked) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        {!isAuthenticated ? (
          <>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        ) : (
          <Route
            path="*"
            element={
              <AuthenticatedLayout 
                isAuthenticated={isAuthenticated}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
                user={user}
                handleSignOut={handleSignOut}
              >
                <Routes>
                  {/* Main Dashboard */}
                   {/* Default: root path redirects to /main */}
              <Route path="/" element={<Navigate to="/main" replace />} />
              {/* Main Dashboard */}
              <Route path="/main" element={<MainDashboard />} />

                  {/* Redirect /analytics to /master-report */}
                  <Route path="/analytics" element={<Navigate to="/master-report" />} />

                  <Route
                    path="/calendar-frontend"
                    element={
                      <>
                        <Header title="Calendar Frontend" />
                        <div>Calendar Frontend Content Here</div>
                      </>
                    }
                  />
                  <Route
                    path="/calendar-backend"
                    element={
                      <>
                        <Header title="Calendar Backend" />
                        <div>Calendar Backend Content Here</div>
                      </>
                    }
                  />
                  <Route
                    path="/booking-forms"
                    element={
                      <>
                        <Header title="Booking Forms" />
                        <div>Booking Forms Content Here</div>
                      </>
                    }
                  />

                  {/* Existing analytics routes */}
                  {navigation.map((tab) => (
                    <Route
                      key={tab.path}
                      path={tab.path}
                      element={
                        <>
                          <Header title={tab.title} />
                          <tab.component />
                        </>
                      }
                    />
                  ))}
                  <Route path="*" element={<div>Page not found</div>} />
                </Routes>
              </AuthenticatedLayout>
            }
          />
        )}
      </Routes>
    </Router>
  );
}

export default App;
