import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Tabs,
  Tab,
  Button
} from "@mui/material";
import { DataGridPro, GridToolbar } from "@mui/x-data-grid-pro";
import axios from "axios";
import * as XLSX from "xlsx";
import { saveAs } from 'file-saver';
// Helper function to strip suffix for cleaner labels
const stripSuffix = (text) =>
  typeof text === "string" ? text.replace(/_\d+$/, "") : text;

// Helper function to round values to 2 decimal places
const roundValue = (value) =>
  value ? parseFloat(value).toFixed(2) : "0.00";

export default function MasterReport() {
  const [selectedYear, setSelectedYear] = useState(2025);
  const [selectedTab, setSelectedTab] = useState("ALL");
  const [reportData, setReportData] = useState(null);
  const [reportDataAll, setReportDataAll] = useState(null);
  const [reportDataHome, setReportDataHome] = useState(null);
  const [reportDataOnline, setreportDataOnline] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch data when the year changes
  // useEffect(() => {
  //   const fetchReportData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await axios.get(
  //         `/api/master-report?year=${selectedYear}`
  //       );
  //       console.log("📊 API Response:", response.data);
  //       setReportData(response.data);
  //     } catch (error) {
  //       console.error("Error fetching master report:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchReportData();
  // }, [selectedYear]);

  
  // Define the order of months based on API keys
const monthKeys = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

// Map API keys to full month names
const monthMapping = {
  jan: "January",
  feb: "February",
  mar: "March",
  apr: "April",
  may: "May",
  jun: "June",
  jul: "July",
  aug: "August",
  sep: "September",
  oct: "October",
  nov: "November",
  dec: "December"
};

// Map API metric keys to friendly names
const metricMapping = {
  lessons: "Total Lessons",
  hours: "Total Lesson Hours",
  revenue: "Revenue",
  tutorPay: "Total Tutor Pay",
  grossProfitMargin: "Gross Profit",
  home: "Total Home Lesson Hours",
  homeRevenue: "Home Revenue",
  online: "Total Online Lesson Hours",
  onlineRevenue: "Online Revenue",
  school: "Total School Lesson Hours",
  schoolRevenue: "School Revenue",
  club: "Total Club Lesson Hours",
  clubRevenue: "Club Revenue",
  totalLeads: "Total Leads",
  convertedLeads: "Total Converted Leads",
  unconvertedLeads: "Total Unconverted Leads",
  remainedNewLead: "Remained a New Lead",
  trialFirstLessons: "Trial/First Lessons",
  secondLessons: "Second Lessons",
  threeOrMoreLessons: "3 or More Lessons",
  lessonsPlaced: "Lessons Placed",
  trialsNotConverted: "Trials Not Converted",
  seventhFullPdLesson: "Seventh Full Paid Lesson",
  activeTutorCount: "Total Active Tutors",
  tutorsTaught0_19: "Tutors taught 0-19H",
  tutorsTaught20_39: "Tutors taught 20-39H",
  tutorsTaught40_59: "Tutors taught 40-59H",
  tutorsTaught60_79: "Tutors taught 60-79H",
  tutorTaught80_84: "Tutor taught 80-84H",
  tutorTaught85Plus: "Tutor taught 85+",
  consistencyBonusPayout: "Consistency Bonus Payout",
  groupLessonCount: "Total Group Lesson Students",
  groupLessonBonusPayout: "Total Group Lesson Bonus Payout",
};

// Helper function to format a value with a $ or % symbol when needed.
function exportFormat(metric, value) {
  const currencyMetrics = ["revenue", "tutorPay", "homeRevenue", "onlineRevenue", "schoolRevenue", "clubRevenue"];
  const percentMetrics = ["grossProfitMargin"];
  if (currencyMetrics.includes(metric)) {
    return "$" + value;
  } else if (percentMetrics.includes(metric)) {
    return value + "%";
  } else {
    return value;
  }
}

const exportMasterReport = async () => {
  // Define the years for which you want to export data.
  const years = [2024, 2025];
  const workbook = XLSX.utils.book_new();

  // Loop over each year and fetch ALL, HOME, and ONLINE data.
  for (const year of years) {
    const startDate = `${year}-01-01`;
    const endDate = `${year}-12-31`;

    // Fetch data concurrently.
    const [allRes, homeRes, onlineRes] = await Promise.all([
      axios.get(`/api/master-report?year=${year}&startDate=${startDate}&endDate=${endDate}`),
      axios.get(`/api/master-report-home?year=${year}&startDate=${startDate}&endDate=${endDate}`),
      axios.get(`/api/master-report-online?year=${year}&startDate=${startDate}&endDate=${endDate}`)
    ]);

    // Create header row with full month names.
    const header = ["Metric", "YTD", ...monthKeys.map(key => monthMapping[key])];

    // Create an array of arrays (AOA) that will represent the worksheet.
    const sheetData = [];
    sheetData.push([`Master Report for ${year}`]);
    sheetData.push([]);
    sheetData.push(header);
    
    // Helper to add section data.
    const addSection = (sectionTitle, dataObj) => {
      sheetData.push([sectionTitle]); // Section title
      for (const metric in dataObj) {
        const metricData = dataObj[metric];
        const friendlyMetric = metricMapping[metric] || metric;
        // Format YTD with symbols if applicable.
        const formattedYTD = exportFormat(metric, metricData.ytd);
        const row = [friendlyMetric, formattedYTD];
        monthKeys.forEach(key => {
          const monthVal = metricData.months ? metricData.months[key] || 0 : 0;
          row.push(exportFormat(metric, monthVal));
        });
        sheetData.push(row);
      }
      sheetData.push([]);
    };

    // ALL data section.
    addSection("ALL Data", allRes.data);
    // HOME data section.
    addSection("HOME Data", homeRes.data);
    // ONLINE data section.
    addSection("ONLINE Data", onlineRes.data);

    // Convert the AOA to a worksheet.
    const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
    // Append the worksheet to the workbook using the year as the sheet name.
    XLSX.utils.book_append_sheet(workbook, worksheet, String(year));
  }

  const today = new Date();
const month = (today.getMonth() + 1).toString().padStart(2, '0');
const day = today.getDate().toString().padStart(2, '0');
const year = today.getFullYear();
const fileName = `ChessAt3_Master_Report_${month}-${day}-${year}.xlsx`;


  // Write the workbook to a binary array and trigger a download.
 // Write the workbook to a binary array and trigger a download.
const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
saveAs(new Blob([wbout]), fileName);

};

  useEffect(() => {
    const fetchReportData = async () => {
      setLoading(true);
      try {
        const startDate = `${selectedYear}-01-01`;
        const endDate = `${selectedYear}-12-31`;
        const [allResponse, homeResponse, onlineResponse] = await Promise.all([
          axios.get(
            `/api/master-report?year=${selectedYear}&startDate=${startDate}&endDate=${endDate}`
          ),
          axios.get(
            `/api/master-report-home?year=${selectedYear}&startDate=${startDate}&endDate=${endDate}`
          ),
          axios.get(
            `/api/master-report-online?year=${selectedYear}&startDate=${startDate}&endDate=${endDate}`
          ),
        ]);
        setReportDataAll(allResponse.data);
        setReportDataHome(homeResponse.data);
        setreportDataOnline(onlineResponse.data);
      } catch (error) {
        console.error("Error fetching report data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchReportData();
  }, [selectedYear]);

  // Handle tab changes between ALL and HOME.
  const handleTabChange = (event, newValue) => {
    if (newValue !== null) {
      setSelectedTab(newValue);
    }
  };

  // Format numbers with commas (e.g., 151090 -> 151,090) and currency where needed
  const formatNumber = (value, isCurrency = false) => {
    if (!value || isNaN(value)) return isCurrency ? "$0" : "0";
    const formatted = new Intl.NumberFormat("en-US").format(Math.round(value));
    return isCurrency ? `$${formatted}` : formatted;
  };

  const existingRows = [
    // { id: "0", metric: "ALL TUTORING" },
    // Total Lessons
    {
      id: "1",
      metric: "Total Lessons",
      ytd: formatNumber(reportDataAll?.lessons?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.lessons?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "2",
      metric: "Total Lesson Hours",
      ytd: formatNumber(reportDataAll?.hours?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.hours?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "3",
      metric: "Revenue",
      ytd: formatNumber(reportDataAll?.revenue?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.revenue?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    {
      id: "4",
      metric: "Total Tutor Pay",
      ytd: formatNumber(reportDataAll?.tutorPay?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.tutorPay?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    // Gross Profit Margin
    {
      id: "5",
      metric: "Gross Profit Margin",
      ytd: `${formatNumber(reportDataAll?.grossProfitMargin?.ytd)}%`,
      ...Object.fromEntries(
        Object.entries(reportDataAll?.grossProfitMargin?.months || {}).map(
          ([m, v]) => [m, `${formatNumber(v)}%`]
        )
      ),
    },
    // Home
    {
      id: "7",
      metric: "Total Home Lesson Hours",
      ytd: formatNumber(reportDataAll?.home?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.home?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "8",
      metric: "Home Revenue",
      ytd: formatNumber(reportDataAll?.homeRevenue?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.homeRevenue?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    // Online
    {
      id: "10",
      metric: "Total Online Lesson Hours",
      ytd: formatNumber(reportDataAll?.online?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.online?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "11",
      metric: "Online Revenue",
      ytd: formatNumber(reportDataAll?.onlineRevenue?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.onlineRevenue?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    // School
    {
      id: "13",
      metric: "Total School Lesson Hours",
      ytd: formatNumber(reportDataAll?.school?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.school?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "14",
      metric: "School Revenue",
      ytd: formatNumber(reportDataAll?.schoolRevenue?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.schoolRevenue?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    // Club
    {
      id: "16",
      metric: "Total Club Lesson Hours",
      ytd: formatNumber(reportDataAll?.club?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.club?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "17",
      metric: "Club Revenue",
      ytd: formatNumber(reportDataAll?.clubRevenue?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.clubRevenue?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
  ];

  const additionalRows = [
    // {
    //   id: "18",
    //   metric: "T- Total Division Expenses",
    //   ytd: formatNumber(reportDataAll?.divisionExpenses?.ytd, true),
    //   ...Object.fromEntries(
    //     Object.entries(reportDataAll?.divisionExpenses?.months || {}).map(([m, v]) => [
    //       m,
    //       formatNumber(v, true),
    //     ])
    //   ),
    // },
    {
      id: "19",
      metric: "Total Leads",
      ytd: formatNumber(reportDataAll?.totalLeads?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.totalLeads?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "20",
      metric: "Total Converted Leads",
      ytd: formatNumber(reportDataAll?.convertedLeads?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.convertedLeads?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "21",
      metric: "Total Unconverted Leads",
      ytd: formatNumber(reportDataAll?.unconvertedLeads?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.unconvertedLeads?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "22",
      metric: "Remained a New Lead (Only)",
      ytd: formatNumber(reportDataAll?.remainedNewLead?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.remainedNewLead?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "23",
      metric: "Trial/First Lessons (Only)",
      ytd: formatNumber(reportDataAll?.trialFirstLessons?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.trialFirstLessons?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "24",
      metric: "Second Lessons (Only)",
      ytd: formatNumber(reportDataAll?.secondLessons?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.secondLessons?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "25",
      metric: "3 or More Lessons",
      ytd: formatNumber(reportDataAll?.threeOrMoreLessons?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.threeOrMoreLessons?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "26",
      metric: "Lessons Placed",
      ytd: formatNumber(reportDataAll?.lessonsPlaced?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.lessonsPlaced?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "27",
      metric: "Trials - Not Converted",
      ytd: formatNumber(reportDataAll?.trialsNotConverted?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.trialsNotConverted?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "28",
      metric: "Seventh Full Paid Lesson",
      ytd: formatNumber(reportDataAll?.seventhFullPdLesson?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.seventhFullPdLesson?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    // {
    //   id: "29",
    //   metric: "Saves/Comebacks/Takeovers",
    //   ytd: formatNumber(reportDataAll?.savesComebacksTakeovers?.ytd),
    //   ...Object.fromEntries(
    //     Object.entries(reportDataAll?.savesComebacksTakeovers?.months || {}).map(([m, v]) => [
    //       m,
    //       formatNumber(v),
    //     ])
    //   ),
    // },
    // {
    //   id: "30",
    //   metric: "T - Bundles Sold",
    //   ytd: formatNumber(reportDataAll?.bundlesSold?.ytd),
    //   ...Object.fromEntries(
    //     Object.entries(reportDataAll?.bundlesSold?.months || {}).map(([m, v]) => [
    //       m,
    //       formatNumber(v),
    //     ])
    //   ),
    // },
    // {
    //   id: "31",
    //   metric: "Unique Active Clients",
    //   ytd: formatNumber(reportDataAll?.uniqueActiveClients?.ytd),
    //   ...Object.fromEntries(
    //     Object.entries(reportDataAll?.uniqueActiveClients?.months || {}).map(([m, v]) => [
    //       m,
    //       formatNumber(v),
    //     ])
    //   ),
    // },
    // {
    //   id: "32",
    //   metric: "Clients took last month",
    //   ytd: formatNumber(reportDataAll?.clientsLastMonth?.ytd),
    //   ...Object.fromEntries(
    //     Object.entries(reportDataAll?.clientsLastMonth?.months || {}).map(([m, v]) => [
    //       m,
    //       formatNumber(v),
    //     ])
    //   ),
    // },
    // {
    //   id: "33",
    //   metric: "Clients INACTIVE last 21 days",
    //   ytd: formatNumber(reportDataAll?.inactiveLast21?.ytd),
    //   ...Object.fromEntries(
    //     Object.entries(reportDataAll?.inactiveLast21?.months || {}).map(([m, v]) => [
    //       m,
    //       formatNumber(v),
    //     ])
    //   ),
    // },
    // {
    //   id: "34",
    //   metric: "INACTIVE clients became ACTIVE in last 21 days",
    //   ytd: formatNumber(reportDataAll?.inactiveBecameActiveLast21?.ytd),
    //   ...Object.fromEntries(
    //     Object.entries(reportDataAll?.inactiveBecameActiveLast21?.months || {}).map(([m, v]) => [
    //       m,
    //       formatNumber(v),
    //     ])
    //   ),
    // },
    {
      id: "35",
      metric: "Total Active Tutors",
      ytd: formatNumber(reportDataAll?.activeTutorCount?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.activeTutorCount?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "36",
      metric: "Tutors taught 0-19H",
      ytd: formatNumber(reportDataAll?.tutorsTaught0_19?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.tutorsTaught0_19?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "37",
      metric: "Tutors taught 20-39H",
      ytd: formatNumber(reportDataAll?.tutorsTaught20_39?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.tutorsTaught20_39?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "38",
      metric: "Tutors taught 40-59H",
      ytd: formatNumber(reportDataAll?.tutorsTaught40_59?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.tutorsTaught40_59?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "39",
      metric: "Tutors taught 60-79H",
      ytd: formatNumber(reportDataAll?.tutorsTaught60_79?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.tutorsTaught60_79?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "40",
      metric: "Tutor taught 80 - 84H",
      ytd: formatNumber(reportDataAll?.tutorTaught80_84?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.tutorTaught80_84?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "41",
      metric: "Tutor taught 85+",
      ytd: formatNumber(reportDataAll?.tutorTaught85Plus?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.tutorTaught85Plus?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "42",
      metric: "Consistency Bonus Payout",
      ytd: formatNumber(reportDataAll?.consistencyBonusPayout?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.consistencyBonusPayout?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    {
      id: "43",
      metric: "Total Group Lesson Students",
      ytd: formatNumber(reportDataAll?.groupLessonCount?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.groupLessonCount?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "44",
      metric: "Total Group Lesson Bonus Payout",
      ytd: formatNumber(reportDataAll?.groupLessonBonusPayout?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataAll?.groupLessonBonusPayout?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
  ];
  
  const allRows = [...existingRows, ...additionalRows];

  // --------------------------------------------------------------------------
  // Build rows for the HOME tab (using reportDataHome)
  // --------------------------------------------------------------------------
  const homeRows = [
    // { id: "home-0", metric: "HOME REPORT" },
    {
      id: "home-1",
      metric: "Total Lessons",
      ytd: formatNumber(reportDataHome?.lessons?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.lessons?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-2",
      metric: "Revenue",
      ytd: formatNumber(reportDataHome?.revenue?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.revenue?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    {
      id: "home-3",
      metric: "Tutor Pay",
      ytd: formatNumber(reportDataHome?.tutorPay?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.tutorPay?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    {
      id: "home-4",
      metric: "Gross Profit Margin",
      ytd: `${formatNumber(reportDataHome?.grossProfitMargin?.ytd)}%`,
      ...Object.fromEntries(
        Object.entries(reportDataHome?.grossProfitMargin?.months || {}).map(
          ([m, v]) => [m, `${formatNumber(v)}%`]
        )
      ),
    },
    {
      id: "home-5",
      metric: "Leads",
      ytd: formatNumber(reportDataHome?.leads?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.leads?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-6",
      metric: "Lessons Placed",
      ytd: formatNumber(reportDataHome?.lessonsPlaced?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.lessonsPlaced?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-7",
      metric: "Trial #",
      ytd: formatNumber(reportDataHome?.trialNumber?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.trialNumber?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-8",
      metric: "Trials - Not Converted",
      ytd: formatNumber(reportDataHome?.trialsNotConverted?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.trialsNotConverted?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-9",
      metric: "First Full Pd Lesson",
      ytd: formatNumber(reportDataHome?.trialNumber?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.trialNumber?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-10",
      metric: "Seventh Full Pd Lesson",
      ytd: formatNumber(reportDataHome?.seventhFullPdLesson?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.seventhFullPdLesson?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    // {
    //   id: "home-11",
    //   metric: "Saves/Win-backs/Takeovers",
    //   ytd: formatNumber(reportDataHome?.savesWinBacksTakeovers?.ytd),
    //   ...Object.fromEntries(
    //     Object.entries(reportDataHome?.savesWinBacksTakeovers?.months || {}).map(([m, v]) => [
    //       m,
    //       formatNumber(v),
    //     ])
    //   ),
    // },
    {
      id: "home-12",
      metric: "Active Tutor #",
      ytd: formatNumber(reportDataHome?.activeTutorCount?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.activeTutorCount?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-13",
      metric: "Tutors taught 20",
      ytd: formatNumber(reportDataHome?.tutorsTaught20?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.tutorsTaught20?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-14",
      metric: "Tutors taught 40",
      ytd: formatNumber(reportDataHome?.tutorsTaught40?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.tutorsTaught40?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-15",
      metric: "Tutors taught 60",
      ytd: formatNumber(reportDataHome?.tutorsTaught60?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.tutorsTaught60?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-16",
      metric: "Tutor taught 80",
      ytd: formatNumber(reportDataHome?.tutorTaught80?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.tutorTaught80?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "home-17",
      metric: "Tutor taught 85+",
      ytd: formatNumber(reportDataHome?.tutorTaught85Plus?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataHome?.tutorTaught85Plus?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
  ];

  // --------------------------------------------------------------------------
  // Build rows for the online tab (using reportDataOnline)
  // --------------------------------------------------------------------------
  const onlineRows = [
    // { id: "online-0", metric: "ONLINE REPORT" },
    {
      id: "online-1",
      metric: "Total Lessons",
      ytd: formatNumber(reportDataOnline?.lessons?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.lessons?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-2",
      metric: "Revenue",
      ytd: formatNumber(reportDataOnline?.revenue?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.revenue?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    {
      id: "online-3",
      metric: "Tutor Pay",
      ytd: formatNumber(reportDataOnline?.tutorPay?.ytd, true),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.tutorPay?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v, true),
        ])
      ),
    },
    {
      id: "online-4",
      metric: "Gross Profit Margin",
      ytd: `${formatNumber(reportDataOnline?.grossProfitMargin?.ytd)}%`,
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.grossProfitMargin?.months || {}).map(
          ([m, v]) => [m, `${formatNumber(v)}%`]
        )
      ),
    },
    {
      id: "online-5",
      metric: "Leads",
      ytd: formatNumber(reportDataOnline?.leads?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.leads?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-6",
      metric: "Lessons Placed",
      ytd: formatNumber(reportDataOnline?.lessonsPlaced?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.lessonsPlaced?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-7",
      metric: "Trial #",
      ytd: formatNumber(reportDataOnline?.trialNumber?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.trialNumber?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-8",
      metric: "Trials - Not Converted",
      ytd: formatNumber(reportDataOnline?.trialsNotConverted?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.trialsNotConverted?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-9",
      metric: "First Full Pd Lesson",
      ytd: formatNumber(reportDataOnline?.trialNumber?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.trialNumber?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-10",
      metric: "Seventh Full Pd Lesson",
      ytd: formatNumber(reportDataOnline?.seventhFullPdLesson?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.seventhFullPdLesson?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    // {
    //   id: "online-11",
    //   metric: "Saves/Win-backs/Takeovers",
    //   ytd: formatNumber(reportDataOnline?.savesWinBacksTakeovers?.ytd),
    //   ...Object.fromEntries(
    //     Object.entries(reportDataOnline?.savesWinBacksTakeovers?.months || {}).map(([m, v]) => [
    //       m,
    //       formatNumber(v),
    //     ])
    //   ),
    // },
    {
      id: "online-12",
      metric: "Active Tutor #",
      ytd: formatNumber(reportDataOnline?.activeTutorCount?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.activeTutorCount?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-13",
      metric: "Tutors taught 20",
      ytd: formatNumber(reportDataOnline?.tutorsTaught20?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.tutorsTaught20?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-14",
      metric: "Tutors taught 40",
      ytd: formatNumber(reportDataOnline?.tutorsTaught40?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.tutorsTaught40?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-15",
      metric: "Tutors taught 60",
      ytd: formatNumber(reportDataOnline?.tutorsTaught60?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.tutorsTaught60?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-16",
      metric: "Tutor taught 80",
      ytd: formatNumber(reportDataOnline?.tutorTaught80?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.tutorTaught80?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
    {
      id: "online-17",
      metric: "Tutor taught 85+",
      ytd: formatNumber(reportDataOnline?.tutorTaught85Plus?.ytd),
      ...Object.fromEntries(
        Object.entries(reportDataOnline?.tutorTaught85Plus?.months || {}).map(([m, v]) => [
          m,
          formatNumber(v),
        ])
      ),
    },
  ];

  // Use flex property to allow columns to expand/shrink as needed
  const columns = [
    { field: "metric", headerName: "Metric", minWidth: 250, flex: 1,
      renderCell: (params) => (
        <div style={{ backgroundColor: "#6a469d", width: "100%", height: "100%", fontWeight: "bold", color: '#FFFFFF', display: "flex", padding:
          10 }}>
          {params.value}
        </div>
      )
     },
    {
      field: "ytd",
      headerName: "YTD",
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <div style={{ backgroundColor: "#ede3fa", width: "100%", height: "100%", fontWeight: "bold", display: "flex", padding:
          10
         }}>
          {params.value}
        </div>
      )
    },
    { field: "jan", headerName: "January", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "feb", headerName: "February", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "mar", headerName: "March", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "apr", headerName: "April", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "may", headerName: "May", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "jun", headerName: "June", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "jul", headerName: "July", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "aug", headerName: "August", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "sep", headerName: "September", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "oct", headerName: "October", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
    { field: "nov", headerName: "November", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },,
    { field: "dec", headerName: "December", minWidth: 120, flex: 1,
      renderCell: (params) => (
      <div style={{ padding:
        10
       }}>
        {params.value}
      </div>
    )
  },
  ];

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Currently viewing report for {selectedYear}
      </Typography>

     

      {/* Year toggle */}
      <ToggleButtonGroup
        value={selectedYear}
        exclusive
        onChange={(event, newYear) => newYear && setSelectedYear(newYear)}
        sx={{ mb: 2 }}
      >
        <ToggleButton value={2024}>2024</ToggleButton>
        <ToggleButton value={2025}>2025</ToggleButton>
      </ToggleButtonGroup>

      
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
  <Button variant="contained" color="primary" onClick={exportMasterReport}>
    Export Master Report
  </Button>
</Box>


      {/* Tab navigation */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        sx={{ mb: 2 }}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab label="ALL" value="ALL" />
        <Tab label="HOME" value="HOME" />
        <Tab label="ONLINE" value="ONLINE" />
        <Tab label="DIVISION EXPENSES" value="DIVISION EXPENSES" />
        <Tab label="CLIENTS" value="CLIENTS" />
      </Tabs>

      {/* DataGrid container */}
      <Box sx={{ width: "100%", overflowX: "auto" }}>
      <div style={{ minWidth: 1500 }}>
  {selectedTab === "ALL" ? (
    <DataGridPro
    sx={{
      "& .MuiDataGrid-cell": {
        padding: 0,
      },
    }}
      rows={allRows}
      columns={columns}
      disableSelectionOnClick
      components={{ Toolbar: GridToolbar }}
      loading={loading}
      initialState={{ pinnedColumns: { left: ["metric"] } }}
    />
  ) : selectedTab === "HOME" ? (
    <DataGridPro
    sx={{
      "& .MuiDataGrid-cell": {
        padding: 0,
      },
    }}
      rows={homeRows}
      columns={columns}
      disableSelectionOnClick
      components={{ Toolbar: GridToolbar }}
      loading={loading}
      initialState={{ pinnedColumns: { left: ["metric"] } }}
    />
  ) : selectedTab === "ONLINE" ? (
    <DataGridPro
    sx={{
      "& .MuiDataGrid-cell": {
        padding: 0,
      },
    }}
      rows={onlineRows}
      columns={columns}
      disableSelectionOnClick
      components={{ Toolbar: GridToolbar }}
      loading={loading}
      initialState={{ pinnedColumns: { left: ["metric"] } }}
    />
  ) : null}
</div>

      </Box>
    </Box>
  );
}