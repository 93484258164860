import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,

  TextField,
  Card,
  CardContent,
} from "@mui/material";
import Typography from '@mui/joy/Typography';
import { GridToolbar } from '@mui/x-data-grid-pro';

import { DataGridPro } from "@mui/x-data-grid-pro";
import axios from "axios";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from "@mui/x-date-pickers-pro/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Line } from "react-chartjs-2";
import { BarChart } from "@mui/x-charts/BarChart";
import StatisticCard from "./StatisticCard"; // Assuming the card component is in the same folder
import EnhancedBarChart from "./EnhancedBarChart"; // Assuming the card component is in the same folder
//import { fetchRevenueData } from "../utils/apiUtils";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Extend dayjs with the necessary plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Set the default timezone to New York (NYC)
dayjs.tz.setDefault("America/New_York");

// Register the necessary Chart.js components
ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const numberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2, // Ensures 2 decimal places
});

const RevenueByDivision = () => {
  const [previousPeriodData, setPreviousPeriodData] = useState([]); // Define the previousPeriodData state
  const [divisions, setDivisions] = useState([]);
  const [totalSchoolRevenue, setTotalSchoolRevenue] = useState(0);
  const [lessonDetails, setLessonDetails] = useState({}); // State to store lesson details for expanded labels
  const [paginationModels, setPaginationModels] = useState({});

  // New state variable for divisions including schools
  const [divisionsIncludingSchoolsTotals, setDivisionsIncludingSchoolsTotals] =
    useState({
      totalRevenue: 0,
      totalContractorCost: 0,
      totalProfit: 0,
    });
  const [expandedRows, setExpandedRows] = useState({}); // State to track expanded rows

  const [loading, setLoading] = useState(false);
  const [divisionsTotals, setDivisionsTotals] = useState({
    totalRevenue: 0,
    totalContractorCost: 0,
    totalProfit: 0,
  });
  const [allLabelsTotals, setAllLabelsTotals] = useState({
    totalRevenue: 0,
    totalContractorCost: 0,
    totalProfit: 0,
  });

  // New state variable for including schools
  const [allLabelsIncludingSchoolsTotals, setAllLabelsIncludingSchoolsTotals] =
    useState({
      totalRevenue: 0,
      totalContractorCost: 0,
      totalProfit: 0,
    });

  const [allLabelsDifferences, setAllLabelsDifferences] = useState({
    revenueDifference: 0,
    contractorCostDifference: 0,
    profitDifference: 0,
  });

  const toggleRowExpansion = (label) => {
    setExpandedRows((prev) => ({
      ...prev,
      [label]: !prev[label],
    }));
  };

  const [differences, setDifferences] = useState({
    revenueDifference: 0,
    contractorCostDifference: 0,
    profitDifference: 0,
  });
  const [revenueData, setRevenueData] = useState([]); // Defined revenueData
  
  const [startDate, setStartDate] = useState(dayjs().startOf("year").tz());
  const [endDate, setEndDate] = useState(dayjs().tz());
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const handlePaginationModelChange = (model) => {
    setPaginationModel(model);
  };

  const handleAccordionPaginationChange = (label, model) => {
    setPaginationModels((prev) => ({
      ...prev,
      [label]: model,
    }));
  };

  const fetchOverallTotals = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const response = await axios.post(
        "/overall-revenue",
        {
          startDate: startDate.format("YYYY-MM-DD"),
          endDate: endDate.format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Enclose with backticks for correct string interpolation
          },
        }
      );

      console.log("Overall Totals Response:", response.data);

      const {
        overallInDivisions = {},
        overallInDivisionsIncludingSchools = {},
        overallInAllLabels = {},
        overallInAllLabelsIncludingSchools = {},
        previousPeriodData = {},
      } = response.data;

      // Update state for Divisions without Schools
      setDivisionsTotals({
        totalRevenue: overallInDivisions.totalRevenue || 0,
        totalContractorCost: overallInDivisions.totalContractorCost || 0,
        totalProfit: overallInDivisions.totalProfit || 0,
      });

      // Update state for Divisions with Schools
      setDivisionsIncludingSchoolsTotals({
        totalRevenue: overallInDivisionsIncludingSchools.totalRevenue || 0,
        totalContractorCost:
          overallInDivisionsIncludingSchools.totalContractorCost || 0,
        totalProfit: overallInDivisionsIncludingSchools.totalProfit || 0,
      });

      // Update state for All Labels without Schools
      setAllLabelsTotals({
        totalRevenue: overallInAllLabels.totalRevenue || 0,
        totalContractorCost: overallInAllLabels.totalContractorCost || 0,
        totalProfit: overallInAllLabels.totalProfit || 0,
      });

      // Update state for All Labels with Schools
      setAllLabelsIncludingSchoolsTotals({
        totalRevenue: overallInAllLabelsIncludingSchools.totalRevenue || 0,
        totalContractorCost:
          overallInAllLabelsIncludingSchools.totalContractorCost || 0,
        totalProfit: overallInAllLabelsIncludingSchools.totalProfit || 0,
      });
    } catch (error) {
      console.error("Error fetching overall totals:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchRevenueData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      // Ensure that startDate and endDate are properly formatted
      const formattedStartDate = startDate.format("YYYY-MM-DD");
      const formattedEndDate = endDate.format("YYYY-MM-DD");

      const response = await axios.post(
        "/revenue-by-division",
        {
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("API Response Data:", response.data); // Log the entire response

      // Explicitly map and rename fields to match what DataGrid expects
      const parsedData = response.data.map((item) => {
        const totalRevenue = parseFloat(item.totalRevenue) || 0;
        const totalContractorCost = parseFloat(item.totalContractorCost) || 0;
        const totalProfit = totalRevenue - totalContractorCost;

        return {
          id: item.label, // Use `label` as the row id
          division: item.division,
          label: item.label,
          totalAppointments: Number(item.totalAppointments) || 0,
          totalServices: Number(item.totalServices) || 0,
          totalRevenue,
          totalContractorCost,
          totalProfit,
          lessonDetails: item.lessonDetails || [], // Properly map lesson details here
        };
      });

      // Log only "Home - SF" parsed data for investigation
      const homeSFData = parsedData.find((item) => item.label === "Home - SF");
      console.log("Parsed Data for Home - SF:", homeSFData);

      setRevenueData(parsedData);
      return parsedData;
    } catch (error) {
      console.error("Error fetching revenue data:", error);
      return [];
    }
  };

  useEffect(() => {
    if (revenueData.length > 0) {
      console.log("Revenue Data with Lesson Details:", revenueData);
    }
  }, [revenueData]);

  // Fetch divisions and update state
  const fetchDivisions = async () => {
    try {
      const response = await axios.get("/api/divisions");
      setDivisions(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching divisions:", error);
      setDivisions([]);
    }
  };

  useEffect(() => {
    fetchDivisions();
  }, []);

  // Format the revenue data before setting it for the DataGrid
  const formatRevenueData = (data) =>
    Array.isArray(data)
      ? data.map((service) => ({
          ...service,
          totalAppointments: service.totalAppointments, // Use the parsed value directly
          totalServices: service.totalServices, // Use the parsed value directly
          totalRevenue: service.totalRevenue, // Use the parsed value directly
          totalContractorCost: service.totalContractorCost, // Use the parsed value directly
          totalProfit: service.totalProfit, // Use the parsed value directly
        }))
      : [];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch the current period data first
        await fetchOverallTotals();
        const revenueData = await fetchRevenueData();
        setRevenueData(revenueData);

        // After fetching current period data, fetch the previous period data
        await fetchPreviousPeriodData();

        // Calculate percentage differences after setting previous period data
        calculatePercentageDifferences();
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [startDate, endDate]);

  // Function to calculate percentage differences based on state values
  const calculatePercentageDifferences = () => {
    // Calculate total revenue, contractor cost, and profit for the current period
    const currentTotalRevenue = revenueData.reduce(
      (sum, row) => sum + (row.totalRevenue || 0),
      0
    );
    const currentTotalContractorCost = revenueData.reduce(
      (sum, row) => sum + (row.totalContractorCost || 0),
      0
    );
    const currentTotalProfit = revenueData.reduce(
      (sum, row) => sum + (row.totalProfit || 0),
      0
    );

    // Calculate total revenue, contractor cost, and profit for the previous period
    const previousTotalRevenue = previousPeriodData.reduce
      ? previousPeriodData.reduce(
          (sum, row) => sum + (row.totalRevenue || 0),
          0
        )
      : 0;
    const previousTotalContractorCost = previousPeriodData.reduce
      ? previousPeriodData.reduce(
          (sum, row) => sum + (row.totalContractorCost || 0),
          0
        )
      : 0;
    const previousTotalProfit = previousPeriodData.reduce
      ? previousPeriodData.reduce((sum, row) => sum + (row.totalProfit || 0), 0)
      : 0;

    // Update percentage differences using calculated values
    setDifferences({
      revenueDifference: calculatePercentageChange(
        currentTotalRevenue,
        previousTotalRevenue
      ),
      contractorCostDifference: calculatePercentageChange(
        currentTotalContractorCost,
        previousTotalContractorCost
      ),
      profitDifference: calculatePercentageChange(
        currentTotalProfit,
        previousTotalProfit
      ),
    });
  };

  useEffect(() => {
    // Ensures that totals are fetched before revenue data
    const fetchData = async () => {
      await fetchOverallTotals();
      fetchRevenueData();
    };
    fetchData();
  }, [startDate, endDate]);

  const fetchPreviousPeriodData = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) throw new Error("No token found");

      const duration = endDate.diff(startDate, "day");
      const previousStartDate = startDate.subtract(duration + 1, "day");
      const previousEndDate = endDate.subtract(duration + 1, "day");

      const response = await axios.post(
        "/revenue-by-division",
        {
          startDate: previousStartDate.format("YYYY-MM-DD"),
          endDate: previousEndDate.format("YYYY-MM-DD"),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setPreviousPeriodData(response.data); // Store previous period data
    } catch (error) {
      console.error("Error fetching previous period data:", error);
    }
  };

  useEffect(() => {
    fetchOverallTotals();
    fetchRevenueData();
    fetchPreviousPeriodData();
  }, [startDate, endDate]);
  const columns = [
    { field: "division", headerName: "Division", width: 200 },
    { field: "label", headerName: "Label", width: 200 },
    {
      field: "totalServices",
      headerName: "Total Jobs In Label",
      width: 250,
      type: "number",
      renderCell: (params) => (
        <Box sx={{ fontSize: "inherit", color: "inherit" }}>{params.value}</Box>
      ),
    },
    {
      field: "totalAppointments",
      headerName: "Total Lessons",
      width: 180,
      type: "number",
      renderCell: (params) => (
        <Box sx={{ fontSize: "inherit", color: "inherit" }}>{params.value}</Box>
      ),
    },

    {
      field: "totalRevenue",
      headerName: "Total Revenue ($)",
      width: 180,
      type: "number",
      renderCell: (params) => (
        <Box sx={{ fontSize: "inherit", color: "inherit" }}>
          {params.value != null
            ? numberFormatter.format(params.value)
            : "$0.00"}
        </Box>
      ),
    },
    {
      field: "totalContractorCost",
      headerName: "Total Tutor Cost ($)",
      width: 200,
      type: "number",
      renderCell: (params) => (
        <Box sx={{ fontSize: "inherit", color: "inherit" }}>
          {params.value != null
            ? numberFormatter.format(params.value)
            : "$0.00"}
        </Box>
      ),
    },
    {
      field: "totalProfit",
      headerName: "Total Profit ($)",
      width: 180,
      type: "number",
      renderCell: (params) => {
        const profit = params?.value != null ? params.value : 0;
        return (
          <Box
            sx={{
              color: profit >= 0 ? "green" : "red",
              fontSize: "inherit",
            }}
          >
            {numberFormatter.format(profit)}
          </Box>
        );
      },
    },
  ];

  // Calculate percentage change safely, avoiding division by zero
  // Calculate percentage change safely, avoiding division by zero
  const calculatePercentageChange = (current, previous) => {
    // Ensure both values are numbers and not undefined
    const currentNum = parseFloat(current) || 0;
    const previousNum = parseFloat(previous) || 0;

    // If previousNum is zero and currentNum is not zero, return 100% change
    if (previousNum === 0) {
      return currentNum === 0 ? 0 : 100; // Return 100% if previous was 0 but current has value
    }

    // Calculate the percentage difference if both numbers are valid
    return ((currentNum - previousNum) / Math.abs(previousNum)) * 100;
  };

  // Calculate totals for footer
  const totalRevenue = revenueData.reduce(
    (sum, row) => sum + (row.totalRevenue || 0),
    0
  );
  const totalContractorCost = revenueData.reduce(
    (sum, row) => sum + (row.totalContractorCost || 0),
    0
  );
  const totalProfit = revenueData.reduce(
    (sum, row) => sum + (row.totalProfit || 0),
    0
  );

  const previousTotalRevenue = previousPeriodData.reduce
    ? previousPeriodData.reduce((sum, row) => sum + row.totalRevenue, 0)
    : 0;
  const revenueDifference = calculatePercentageChange(
    totalRevenue,
    previousTotalRevenue
  );

  const previousTotalContractorCost = previousPeriodData.reduce
    ? previousPeriodData.reduce((sum, row) => sum + row.totalContractorCost, 0)
    : 0;
  const contractorCostDifference = calculatePercentageChange(
    totalContractorCost,
    previousTotalContractorCost
  );

  const previousTotalProfit = previousPeriodData.reduce
    ? previousPeriodData.reduce((sum, row) => sum + row.totalProfit, 0)
    : 0;
  const profitDifference = calculatePercentageChange(
    totalProfit,
    previousTotalProfit
  );

  // Prepare data for BarChart
  const chartLabels = revenueData.map((row) => row.label);
  const chartSeries = [
    {
      data: revenueData.map((row) => row.totalRevenue),
      label: "Total Revenue (In Labels)",
    },
    {
      data: revenueData.map((row) => row.totalContractorCost),
      label: "Total Tutor Cost (In Labels)",
    },
    {
      data: revenueData.map((row) => row.totalProfit),
      label: "Total Profit (In Labels)",
    },
  ];

  // Define chart data using react-chartjs-2
  const chartData = {
    labels: revenueData.map((row) => row.label),
    datasets: [
      {
        label: "Total Revenue (In Labels)",
        data: revenueData.map((row) => row.totalRevenue),
        borderColor: "rgba(75,192,192,1)",
        fill: false,
      },
      {
        label: "Total Tutor Cost (In Labels)",
        data: revenueData.map((row) => row.totalContractorCost),
        borderColor: "rgba(255,99,132,1)",
        fill: false,
      },
      {
        label: "Total Profit (In Labels)",
        data: revenueData.map((row) => row.totalProfit),
        borderColor: "rgba(54,162,235,1)",
        fill: false,
      },
    ],
  };

  const setThisWeek = () => {
    setStartDate(dayjs().tz().startOf("week"));
    setEndDate(dayjs().tz().endOf("week"));
  };

  const setLastWeek = () => {
    setStartDate(dayjs().tz().subtract(1, "week").startOf("week"));
    setEndDate(dayjs().tz().subtract(1, "week").endOf("week"));
  };

  const setThisMonth = () => {
    setStartDate(dayjs().tz().startOf("month"));
    setEndDate(dayjs().tz().endOf("month"));
  };

  const setLastMonth = () => {
    setStartDate(dayjs().tz().subtract(1, "month").startOf("month"));
    setEndDate(dayjs().tz().subtract(1, "month").endOf("month"));
  };

  const setThisYear = () => {
    setStartDate(dayjs().tz().startOf("year"));
    setEndDate(dayjs().tz());
  };

  const setLastYear = () => {
    setStartDate(dayjs().tz().subtract(1, "year").startOf("year"));
    setEndDate(dayjs().tz().subtract(1, "year").endOf("year"));
  };

  // Quarter-based shortcuts
  // Quarter-based shortcuts
  const setQ1 = () => {
    const currentYear = dayjs().year();
    setStartDate(
      dayjs(`${currentYear}-01-01`).tz("America/New_York").startOf("day")
    );
    setEndDate(
      dayjs(`${currentYear}-03-31`).tz("America/New_York").endOf("day")
    );
  };

  const setQ2 = () => {
    const currentYear = dayjs().year();
    setStartDate(
      dayjs(`${currentYear}-04-01`).tz("America/New_York").startOf("day")
    );
    setEndDate(
      dayjs(`${currentYear}-06-30`).tz("America/New_York").endOf("day")
    );
  };

  const setQ3 = () => {
    const currentYear = dayjs().year();
    setStartDate(
      dayjs(`${currentYear}-07-01`).tz("America/New_York").startOf("day")
    );
    setEndDate(
      dayjs(`${currentYear}-09-30`).tz("America/New_York").endOf("day")
    );
  };

  const setQ4 = () => {
    const currentYear = dayjs().year();
    setStartDate(
      dayjs(`${currentYear}-10-01`).tz("America/New_York").startOf("day")
    );
    setEndDate(
      dayjs(`${currentYear}-12-31`).tz("America/New_York").endOf("day")
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: "100%" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={fetchRevenueData}
          disabled={loading}
          sx={{ mb: 2 }}
        >
          {loading ? <CircularProgress size={24} /> : "Refresh Data"}
        </Button>

        {/* Date Range Pickers */}
        <Box sx={{ mb: 2, display: "flex", gap: 2 }}>
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => {
              if (newValue && newValue.isValid()) {
                setStartDate(newValue.tz("America/New_York").startOf("day"));
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => {
              if (newValue && newValue.isValid()) {
                setEndDate(newValue.tz("America/New_York").endOf("day"));
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        {/* Date Range Shortcuts */}
        <Box sx={{ mb: 2 }}>
          <Button variant="outlined" onClick={setThisWeek} sx={{ mr: 1 }}>
            This Week
          </Button>
          <Button variant="outlined" onClick={setLastWeek} sx={{ mr: 1 }}>
            Last Week
          </Button>
          <Button variant="outlined" onClick={setThisMonth} sx={{ mr: 1 }}>
            This Month
          </Button>
          <Button variant="outlined" onClick={setLastMonth} sx={{ mr: 1 }}>
            Last Month
          </Button>
          <Button variant="outlined" onClick={setThisYear} sx={{ mr: 1 }}>
            This Year
          </Button>
          <Button variant="outlined" onClick={setLastYear} sx={{ mr: 1 }}>
            Last Year
          </Button>

          {/* Quarter Shortcuts */}
          <Button variant="outlined" onClick={setQ1} sx={{ mr: 1 }}>
            Q1
          </Button>
          <Button variant="outlined" onClick={setQ2} sx={{ mr: 1 }}>
            Q2
          </Button>
          <Button variant="outlined" onClick={setQ3} sx={{ mr: 1 }}>
            Q3
          </Button>
          <Button variant="outlined" onClick={setQ4}>
            Q4
          </Button>
        </Box>

        {/* Display total at the bottom */}
        <Box
          sx={{ display: "flex", gap: 3, justifyContent: "left", mt: 4, mb: 4 }}
        >
          {/* <Typography variant="h6">Overall in Divisions</Typography> */}

          <StatisticCard
            title="Total Revenue (Divisions)"
            value={numberFormatter.format(divisionsTotals.totalRevenue || 0)}
            percentageChange={`${(differences.revenueDifference || 0).toFixed(
              2
            )}`}
            isPositive={(differences.revenueDifference || 0) >= 0}
          />
          <StatisticCard
            title="Total Revenue (Divisions) including Schools"
            value={numberFormatter.format(
              divisionsIncludingSchoolsTotals.totalRevenue || 0
            )}
            percentageChange={`${(differences.revenueDifference || 0).toFixed(
              2
            )}`}
            isPositive={(differences.revenueDifference || 0) >= 0}
          />

          <StatisticCard
            title="Total Tutor Cost (Divisions)"
            value={numberFormatter.format(
              divisionsTotals.totalContractorCost || 0
            )}
            percentageChange={`${(
              differences.contractorCostDifference || 0
            ).toFixed(2)}`}
            isPositive={(differences.contractorCostDifference || 0) >= 0}
          />
          <StatisticCard
            title="Total Profit (Divisions)"
            value={numberFormatter.format(divisionsTotals.totalProfit || 0)}
            percentageChange={`${(differences.profitDifference || 0).toFixed(
              2
            )}`}
            isPositive={(differences.profitDifference || 0) >= 0}
          />
        </Box>

        <Box sx={{ height: 400, mb: 4 }}>
          <EnhancedBarChart revenueData={revenueData} />
        </Box>

        <DataGridPro
          rows={revenueData}
          columns={columns}
          getRowId={(row) => row.label} // Use `id` for unique row identification
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          pageSizeOptions={[5, 10, 25, 50]}
          pagination
          autoHeight
          slots={{
            toolbar: GridToolbar,
          }}
        />
        {/* Add Accordions for Lesson IDs below the DataGrid */}

        <Box sx={{ marginTop: 4 }}>
          <Typography variant="h6">
            View Lessons Counting Towards Totals
          </Typography>
          <Typography variant="subtitle2">
            Filtering by broad date ranges may result in slow loading.
          </Typography>
        </Box>

        {/* <Box sx={{ marginTop: 4 }}>
          {revenueData.map((row) => (
            <Accordion
              key={row.label}
              expanded={expandedRows[row.label] || false}
              onChange={() => toggleRowExpansion(row.label)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${row.label}-content`}
                id={`${row.label}-header`}
              >
                <Typography variant="subtitle2">
                  Lesson Details for {row.label}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Array.isArray(row.lessonDetails) &&
                row.lessonDetails.length > 0 ? (
                  <>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      sx={{ fontSize: "0.875rem" }}
                    >
                      Total Lessons: {row.lessonDetails.length}
                    </Typography>
                    {row.lessonDetails.map((lesson, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: 2,
                          alignItems: "center",
                          marginBottom: 1,
                        }}
                      >
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          <strong>Job ID:</strong>{" "}
                          {lesson.serviceId ? (
                            <a
                              href={`https://secure.tutorcruncher.com/cal/service/${lesson.serviceId}/`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#1976d2",
                                textDecoration: "none",
                              }}
                            >
                              {lesson.serviceId}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </Typography>
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          <strong>Lesson ID:</strong>{" "}
                          {lesson.lessonId ? (
                            <a
                              href={`https://secure.tutorcruncher.com/cal/appointments/${lesson.lessonId}/`}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "#1976d2",
                                textDecoration: "none",
                              }}
                            >
                              {lesson.lessonId}
                            </a>
                          ) : (
                            "N/A"
                          )}
                        </Typography>
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          <strong>Start Date:</strong>{" "}
                          {lesson.startDate
                            ? dayjs(lesson.startDate)
                                .tz("America/New_York")
                                .format("MM/DD/YYYY, h:mm:ss A")
                            : "N/A"}
                        </Typography>
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          <strong>Charge Rate:</strong> $
                          {lesson.chargeRate.toFixed(2)}
                        </Typography>
                        <Typography sx={{ fontSize: "0.875rem" }}>
                          <strong>Contractor Rate:</strong> $
                          {lesson.contractorRate.toFixed(2)}
                        </Typography>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Typography sx={{ fontSize: "0.875rem" }}>
                    No lesson details available
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box> */}


<Box sx={{ marginTop: 4 }}>
  {revenueData.map((row) => (
    <Accordion
      key={row.label}
      expanded={expandedRows[row.label] || false}
      onChange={() => toggleRowExpansion(row.label)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${row.label}-content`}
        id={`${row.label}-header`}
      >
        <Typography variant="subtitle2">
          Lesson Details for {row.label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {Array.isArray(row.lessonDetails) && row.lessonDetails.length > 0 ? (
          <>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{ fontSize: "0.875rem" }}
            >
              Total Lessons: {row.lessonDetails.length}
            </Typography>

            {/* DataGridPro for lesson details */}
            <Box sx={{ width: "100%" }}>
              <DataGridPro
                rows={row.lessonDetails.map((lesson, index) => ({
                  id: index, // Unique ID for each row
                  serviceId: lesson.serviceId || "N/A",
                  jobName: lesson.jobName || "N/A",
                  studentName: lesson.studentName
                    ? Array.isArray(lesson.studentName)
                      ? lesson.studentName.join(", ")
                      : lesson.studentName
                    : "N/A",
                  tutorName: lesson.tutorName || "N/A",
                  units: lesson.units != null ? lesson.units : "N/A",
                  lessonId: lesson.lessonId || "N/A",
                  startDate: lesson.startDate
                    ? dayjs(lesson.startDate)
                        .tz("America/New_York")
                        .format("MM/DD/YYYY, h:mm:ss A")
                    : "N/A",
                  chargeRate:
                    lesson.chargeRate != null
                      ? lesson.chargeRate.toFixed(2)
                      : "N/A",
                  contractorRate:
                    lesson.contractorRate != null
                      ? lesson.contractorRate.toFixed(2)
                      : "N/A",
                }))}
                columns={[
                  {
                    field: "serviceId",
                    headerName: "Job ID",
                    width: 150,
                    renderCell: (params) => (
                      <a
                        href={`https://secure.tutorcruncher.com/cal/service/${params.value}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#1976d2",
                          textDecoration: "none",
                        }}
                      >
                        {params.value}
                      </a>
                    ),
                  },
                  {
                    field: "jobName",
                    headerName: "Job Name",
                    width: 200,
                  },
                  {
                    field: "studentName",
                    headerName: "Students",
                    width: 200,
                    renderCell: (params) =>
                      params.value || "N/A",
                  },
                  {
                    field: "tutorName",
                    headerName: "Tutor Name",
                    width: 200,
                  },
                  {
                    field: "units",
                    headerName: "Duration (Hours)",
                    width: 100,
                  },
                  {
                    field: "lessonId",
                    headerName: "Lesson ID",
                    width: 150,
                    renderCell: (params) => (
                      <a
                        href={`https://secure.tutorcruncher.com/cal/appointments/${params.value}/`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          color: "#1976d2",
                          textDecoration: "none",
                        }}
                      >
                        {params.value}
                      </a>
                    ),
                  },
                  { field: "startDate", headerName: "Start Date", width: 200 },
                  {
                    field: "chargeRate",
                    headerName: "Charge Rate ($)",
                    width: 150,
                    type: "number",
                  },
                  {
                    field: "contractorRate",
                    headerName: "Contractor Rate ($)",
                    width: 180,
                    type: "number",
                  },
                ]}
                autoHeight
                pageSize={5}
                rowsPerPageOptions={[5, 10, 25]}
                disableSelectionOnClick
                paginationModel={
                  paginationModels[row.label] || { page: 0, pageSize: 5 }
                }
                onPaginationModelChange={(model) =>
                  handleAccordionPaginationChange(row.label, model)
                }
                pagination
                slots={{
                  toolbar: GridToolbar,
                }}
              />
            </Box>
          </>
        ) : (
          <Typography sx={{ fontSize: "0.875rem" }}>
            No lesson details available
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  ))}
</Box>


      </Box>
    </LocalizationProvider>
  );
};

export default RevenueByDivision;
